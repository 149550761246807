import styled from "styled-components";

export const PartnersWrap = styled.div`
  padding: 19.5rem 0;
  overflow: hidden;
  .partners-list {
    display: flex;
    align-items: center;
    white-space: nowrap;
    ul {
      display: flex;
      align-items: center;
      white-space: nowrap;
      //gap: 1rem;
      &.original {
        animation: 300s linear infinite normal none running infiniteAnimation1;
      }
      &.clone {
        animation: 300s linear infinite infiniteAnimation2;
      }
      li {
        width: 12rem;
        margin-right: 8rem;
        text-align: center;
        img {
          filter: grayscale(1);
        }
      }
    }
    &.reverse {
      flex-direction: row-reverse;
      margin-top: 6rem;
      ul {
        flex-direction: row-reverse;
        &.original {
          animation: 300s linear infinite normal none running infiniteAnimation3;
        }
        &.clone {
          animation: 300s linear infinite infiniteAnimation4;
        } 
      }
    }
  }
  @keyframes infiniteAnimation1 {
    0% {
      transform: translateX(0%);
    }
    50% {
      transform: translateX(-100%);
    }
    50.1% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0%);
    }
  } 
  @keyframes infiniteAnimation2 {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-200%);
    }
  }
  @keyframes infiniteAnimation3 {
    0% {
      transform: translateX(0%);
    }
    50% {
      transform: translateX(100%);
    }
    50.1% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0%);
    }
  } 
  @keyframes infiniteAnimation4 {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(200%);
    }
  }

  @media screen and (max-width: 500px) {
    padding: 10rem 0 15rem;
    .partners-list {
      &.reverse {
        margin-top: 2.5rem;
      }
      ul {
        li {
          width: 7.3rem;
          margin-right: 2.5rem;
          img {
            max-height: 2rem;
          }
        }
      } 
    }
  }
`
