import styled from "styled-components";

export const HomeWrap = styled.div`
  > section {
    width: 100%;
    height: auto;
  }
  .visual {
    height: 90rem;
    background-color: #ed0000;
    position: relative;
    .container {
      display: flex;
      align-items: center;
      justify-content: center;
      .visual-img {
        position: relative;
        .mo {
          display: none;
        }
        .dots {
          display: block;
          width: 3rem;
          height: 3rem;
          border-radius: 50%;
          background-color: rgba(155, 237, 74, 1);
          filter: blur(10px);
          opacity: 0;
        }
        .dots01 {
          position: absolute;
          left: 9.6%;
          top: 39.7%;
          animation: 3s linear infinite normal opacity1;
        }      
        .dots02 {
          position: absolute;
          left: 9.6%;
          top: 43.8%;
          animation: 3s linear infinite normal opacity1;
          animation-delay: 1s;
        }  
        .dots03 {
          width: 4rem;
          height: 4rem;
          position: absolute;
          left: 12.8%;
          top: 51.2%;
          animation: 3s linear infinite normal opacity1;
          filter: blur(15px);
        }  
        .dots04 {
          width: 4rem;
          height: 4rem;
          position: absolute;
          left: 21%;
          bottom: 25.6%;
          animation: 3s linear infinite normal opacity1;
          animation-delay: 1s;
          filter: blur(15px);
        }  
        .dots05 {
          width: 4.5rem;
          height: 4.5rem;
          position: absolute;
          left: 52%;
          bottom: 18%;
          animation: 3s linear infinite normal opacity1;
          animation-delay: 1s;
          filter: blur(20px);
        }  
        .dots06 {
          width: 4.5rem;
          height: 4.5rem;
          position: absolute;
          left: 52%;
          bottom: 24.5%;
          animation: 3s linear infinite normal opacity1;
          animation-delay: 3s;
          filter: blur(20px);
        }  
        .dots07 {
          position: absolute;
          right: 19.4%;
          top: 45%;
          animation: 3s linear infinite normal opacity1;
          animation-delay: 4s;
          filter: blur(15px);
        }  
        .dots08 {
          position: absolute;
          right: 19.4%;
          top: 50%;
          animation: 3s linear infinite normal opacity1;
          animation-delay: 0s;
          filter: blur(15px);
        }  
        .dots09 {
          position: absolute;
          right: 19.4%;
          top: 55%;
          animation: 3s linear infinite normal opacity1;
          animation-delay: 2s;
          filter: blur(15px);
        }  
        @keyframes opacity1 {
          0% {
            opacity: 0;
          }
          50% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        } 
      }
      .arrow-down {
        display: none;
      }
      .title-wrap {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        h2 {
          display: inline-block;
          font-size: 7.4rem;
          line-height: 1.3;
          font-weight: 900;
          color: #fff;
          overflow: hidden;
          span {
            display: inline-block;
          }
        }
      }
    }
  }
  .sec01 {
    height: auto;
    padding: 14rem 0 19rem;
    background: url('/image/main-sec01-bg.svg') no-repeat center / cover;
    .container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    p {
      font-size: 1.8rem;
      line-height: 1.8;
      text-align: center;
      margin-top: 6rem;
    }
  }
  .sec02 {
    background-color: #333;
    padding: 17.5rem 0;
    .container {
      display: flex;
    }
    .about {
      width: 28.5rem;
      height: auto;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .mobile {
        display: none;
      }
      dl {
        dt {
          font-size: 3.3rem;
          line-height: 1.15;
          font-weight: 600;
          color: #fff;
        }
        dd {
          font-size: 1.8rem;
          font-weight: 400;
          color: #aaa;
          margin-top: 1.5rem;
        }
      }
    }
    .info {
      width: calc(100% - 28.5rem);
      height: auto;
      padding-left: 13.6rem;
      p {
        font-size: 1.8rem;
        line-height: 1.78;
        color: #fff;
        word-break: keep-all;
      }
      span {
        display: block;
        font-size: 1.8rem;
        color: #aaa;
        margin-top: 3rem;
        br {
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .sec02 {
      .container {
        display: block;
      }
      .about {
        width: 100%;
        justify-content: center;
        gap: 2rem;
      }
      .info {
        width: 100%;
        padding-left: 0;
        text-align: center;
        margin-top: 6rem;
      }
    }
  }

  @media screen and (max-width: 813px) {
    .sec01 {
      p {
        word-break: keep-all;
        br {
          display: none;
        }
        span {
          display: block;
          margin-top: 3rem;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .sec01 {
      padding: 9rem 0 11rem;
      background: url('/image/mobile/main-sec01-bg.png') no-repeat center / cover;
      .logo {
        img {
          width: 17.5rem;
        }
      }
      p {
        font-size: 1.6rem;
        line-height: 2.8rem;
        max-width: 57.8rem;
        margin: 6rem auto 0;
      }
    }
    .sec02 {
      padding: 9rem 0 11rem;
      .about {
        img {
          width: 9rem;
        }
        dl {
          dt {
            font-size: 2rem;
            line-height: 2.4rem;
          }
          dd {
            font-size: 1.8rem;
            line-height: 2.2rem;
            margin-top: 1.1rem;
          }
        }
      }
      .info {
        p {
          font-size: 1.6rem;
          line-height: 2.8rem;
          max-width: 58.2rem;
          margin: 0 auto;
        }
        span {
          font-size: 1.6rem;
          line-height: 2.8rem;
          margin-top: 2.4rem;
          br {
            display: block;
          }
        }
      }
    }
  }
  
  @media screen and (max-width: 616px) {
    .visual {
      .container {
        .title-wrap {
          h2 {
            font-size: 6rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: 505px) {
    .visual {
      height: 158.93vw;
      padding-top: 67vw;
      .container {
        display: block;
        .title-wrap {
          top: 39.7333vw;
          transform: translateX(-50%);
          h2 {
            font-size: 8.8vw;
          }
        }
        .visual-img {
          .pc {
            display: none;
          }
          .mo {
            display: block;
            width: 100%;
          }
          .dots {
            width: 3vw;
            height: 3vw;
            filter: blur(7px);
          }
          .dots01 {
            left: 6.4%;
            top: 29%;
          }
          .dots02 {
            left: 6.4%;
            top: 35.6%;
          }
          .dots03 {
            width: 5vw;
            height: 5vw;
            left: 10%;
            top: 47.6%;
          }
          .dots04 {
            width: 5vw;
            height: 5vw;
            left: 19.5%;
            bottom: 15.5%;
          }
          .dots05 {
            width: 5vw;
            height: 5vw;
            left: 54.8%;
            bottom: 13.6%;
          }
          .dots06 {
            width: 5vw;
            height: 5vw;
            left: 54.8%;
            bottom: 3.3%;
          }
          .dots07 {
            right: 14%;
            top: 38%;
          }
          .dots08 {
            right: 14%;
            top: 47%;
          }
          .dots09 {
            right: 14%;
            top: 54.5%;
          }
        }
        .arrow-down {
          display: block;
          position: absolute;
          left: 50%;
          bottom: 13.33vw;
          height: 6.4vw;
          transform: translateX(-50%);
        }
      }
    }

    .sec01 {
      padding: 9rem 0 11rem;
      background: url('/image/mobile/main-sec01-bg.svg') no-repeat center / cover;
      .logo {
        img {
          width: 17.5rem;
        }
      }
      p {
        font-size: 1.6rem;
      }
    }
    .sec02 {
      padding: 9rem 0 11rem;
      .about {
        align-items: center;
        img {
          width: 9rem;
          &.mobile {
            display: block;
          }
          &.pc {
            display: none;
          }
        }
        dl {
          dt {
            font-size: 2rem;
          }
          dd {
            font-size: 1.8rem;
            margin-top: 1.1rem;
          }
        }
      }
      .info {
        p {
          font-size: 1.6rem;
        }
        span {
          font-size: 1.6rem;
          line-height: 2.8rem;
          word-break: keep-all;
          margin-top: 2.4rem;
          br {
            display: block;
          }
        }
      }
    }
  }
`
