import styled from "styled-components";

export const Container = styled.div`
  @media screen and (min-width: 1201px) {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 120rem;
    padding: 10rem 0;
  }
  @media screen and (max-width: 1200px) {
    position: fixed;
    top: 0;
    padding: 13.8rem 3.8rem;
    width: 100%;
    height: 100%;
    background-color: rgba(51, 51, 51, 0.3);
    z-index: 9999;
  }
`

export const ModalWrap = styled.div`
  position: relative;
  width: calc(100vw - 7.6rem);
  max-width: 50rem;
  background-color: white;
  box-shadow: 0px 0px 16px 0px #00000025;
`

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  width: 100%;
  padding: 6.85rem 4.9rem;
  @media screen and (max-width: 600px) {
    padding: 5rem 2.4rem;
  }
`

export const ModalImage = styled.img<{
  hover: boolean
}>`
  width: 100%;
  ${props => props.hover && 'cursor: pointer;'}
`

export const Title = styled.p`
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  text-align: center;
  @media screen and (max-width: 1200px) {
    font-size: 18px;
    line-height: 24px;
  }
`
export const ModalContent = styled.div``
export const Content = styled.p`
  display: inline;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word; /* 수정된 부분 */
  > p {
    line-height: 24px;
  }
`
export const DetailButton = styled.button`
  background-color: #333333;
  color: white;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.71px;
  text-align: center;
  padding: 1.6rem 0;
  margin-top: 3rem;
`

export const ModalButtonWrap = styled.div`
  display: flex;
   
`

export const ModelButtonBox = styled.div`
  display: flex;
  gap: 1.1rem;
  flex: 1;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.71px;
  text-align: center;
  border-top: .1rem solid #DDDDDD;
  color: #333333;
  padding: 2.3rem 0;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:first-child {
    border-right: .1rem solid #DDDDDD;
  }
  span {
    text-align: left;
  }

  @media screen and (max-width: 500px) {
    font-size: 12px;
    gap: 0.7rem;
    padding: 1.55rem 0;
    span {
      font-size: 10px;
      line-height: 14px;
    }
  }
`
