import React, { useEffect } from 'react'
import Routers from './router'
import AOS from "aos"
import "aos/dist/aos.css"

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      offset: 400
    })
  },[])
  return <Routers />
}

export default App
