import { useRecoilValue } from 'recoil';
import {
  DisplayBack, DisplayBackWrap,
  InfoText,
  RedText, SearchIconWrap,
  SearchListItem,
  SearchListItemWrap,
  SearchListWrap,
  Section01Wrap,
  TitleWrap
} from './section01Styles';
import React, {useEffect, useState} from "react";
import { langAtom } from '../../../../store/recoil/lang';

const Section01 = () => {

  // 다국어
  const lang = useRecoilValue(langAtom)

  const listItem = [
    {
      title: lang ? '잡지' : 'Magazines',
      value: 180,
    },
    {
      title: lang ? '지면 신문' : 'Newspapers',
      value: 350,
    },
    {
      title: lang ? '카페,블로그,SNS' : 'Broadcasting & Telecommunications',
      value: 60,
    },
    {
      title: lang ? '인터넷신문' : 'Internet News',
      value: 2600,
    },
    {
      title: lang ? '방송&통신사' : 'Social Media',
      value: 60,
    },
  ]

  const [list, setList] = useState([])
  const [listIcon, setListIcon] = useState([
    '/image/product/searchIcon/img-1.png',
    '/image/product/searchIcon/img-2.png',
    '/image/product/searchIcon/img-3.png',
    '/image/product/searchIcon/img-4.png',
    '/image/product/searchIcon/img-5.png',
  ])

  const [fillList, setFillList] = useState(false)
  const [topValue, setTopValue] = useState(9)
  const [active, setActive] = useState({
    cnt: 0,
    titleTopValue: '0rem',
    iconTopValue: '0rem',
  })

  useEffect(() => {
    let temp:any = []

    Array.from({length: 5}).forEach((it, index) => {
      temp.push( index === 0 ? [...listItem] : temp[index - 1] )
      const firstItem = temp[index][0]
      const newList = temp[index].slice(1);
      temp[index] = [...newList, firstItem]
    })
    setList(temp)
  }, [])

  useEffect(() => {

    const interval = setInterval(() => {
      setActive(prevActive => {
        const cnt = prevActive.cnt + 1
        let titleTopValue, iconTopValue;
  
        if (window.innerWidth >= 914) {
          titleTopValue = `-${topValue * cnt}rem`;
          iconTopValue = `-${10 * cnt}rem`;
        } else {
          titleTopValue = `-9.8468vw`;
          iconTopValue = `-10.9409vw`;
        }
        
        return {
          cnt: cnt,
          titleTopValue: titleTopValue,
          iconTopValue: iconTopValue,
        };
      });
    }, 2550);

    return () => clearInterval(interval);
  }, [fillList]);

  useEffect(() => {
    if(list.length > 0) setFillList(true)
  }, [list])

  useEffect(() => {
    if(active.cnt > 0) {

      setTimeout(() => {
        setListIcon(prev => {
          const temp = [...prev];
          let swap: any = temp.shift();
          temp.push(swap);
          return [...temp];
        });
      }, 500)

      setTimeout(() => {
        setList(prev => {
          let temp: any = []

          prev.map((listItem: any) => {
            const item = [...listItem]
            let swap = item.shift()
            item.push(swap)
            temp.push(item )
          })

          return temp
        })
        setActive({
          cnt: 0,
          titleTopValue: `0rem`,
          iconTopValue: `0rem`,
        });
      }, 500)

    }
  }, [active])

  useEffect(() => {
    if(active.cnt === 0) {
      setListIcon(listIcon)
    }
  }, [active])

  const [windowWidth, setWindowWidth] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {

      setWindowWidth(Number(window.innerWidth) <= 500);
    };

    handleResize()

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return <Section01Wrap>
    <TitleWrap>
      <RedText>BECUAI PRODUCT</RedText>
      {
        lang ? (
          <InfoText>
            ‘인공지능’ 확장에 필요한 <br/>
            신뢰할 수 있는{windowWidth && <br/> } 실시간 지식 데이터와 <br/>
            다양한 AI SaaS 서비스를 <br/>
            완벽하게 제공합니다.
          </InfoText>
        ) : (
          <InfoText>
            BECUAI offers <br/>
            Reliable training data for <br/>
            smarter AI and a range of <br/>
            AIaaS Platforms
          </InfoText>
        )
      }
    </TitleWrap>
    <DisplayBackWrap>
      <DisplayBack>
        <img src="/image/product/section01-main.png" alt="bg" className='bg' />
        <SearchListWrap>
          <SearchIconWrap>
            <div style={{transition: active.cnt == 0 ? '' : '.5s', top: active.iconTopValue}}>
              {
                listIcon.map((icon, iconKey) => (
                  <img key={iconKey} src={icon} alt={icon} />
                ))
              }
            </div>
          </SearchIconWrap>
          {
            list.map((it: any, key) => (
              <SearchListItem key={key}>
                {
                  it.map((item: any, key2: number) => {
                    return <SearchListItemWrap key={key2}>
                      <div>
                        { key !== 1 && <p className={'number'}>{key + 1}</p> }
                        <p style={{top: active.titleTopValue, transition: active.cnt == 0 ? '' : '.5s'}}>{item.title}</p>
                      </div>
                      {key === 1 && <p className={'value'}>
                        <span style={{top: active.titleTopValue, transition: active.cnt == 0 ? '' : '.5s'}}>{Number(item.value).toLocaleString('ko-KR')}</span>
                        {lang ? '매체' : 'partners'}
                      </p>}
                    </SearchListItemWrap>
                  })
                }

              </SearchListItem>
            ))
          }
        </SearchListWrap>
      </DisplayBack>
    </DisplayBackWrap>
  </Section01Wrap>
}

export default Section01
