import styled from "styled-components";

export const BusinessSliderWrap = styled.section`
  width: 100%;
  height: auto;
  padding: 13rem 0 17.4rem;
  transition: all ease 0.3s;
  position: relative;
  .bg-box {
    .tablet-big {
      > div {
        display: none;
      }
    }
    .tablet-small {
      > div {
        display: none;
      }
    }
    .mobile {
      > div {
        display: none;
      }
    }
    .bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      &.on {
        opacity: 1;
      }
    }
  }
  .title-wrap {
    width: 100%;
    height: auto;
    margin-bottom: 9rem;
    position: relative;
    z-index: 9;
    h2 {
      font-size: 5rem;
      font-weight: 900;
      color: #fff;
      text-align: center;
    }
    span {
      display: block;
      font-size: 1.8rem;
      font-weight: 600;
      color: #fff;
      text-align: center;
      margin-top: 3rem;
      br {
        display: none;
      }
    }
  }
  .slider-wrap {
    position: relative;
    z-index: 9;
    .arrow-btn-wrap {
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 13.5rem;
        transform: rotate(90deg);
        width: 26.2rem;
        height: 6.8rem;
        background-color: #fff;
        opacity: 1;
        z-index: 9;
        transition: all ease 0.3s;
        img {
          width: 1.8rem;
          position: absolute;
          left: 4.8rem;
          top: 50%;
          transform: translateY(-50%) rotate(-90deg);
        }
        &.slider-prev {
          left: -9.8rem;
        }
        &.slider-next {
          right: -9.8rem;
        }
        &.swiper-button-disabled {
          opacity: 0;
        }
        span {
          display: block;
          font-size: 1.8rem;
          font-weight: 700;
          color: #333;
          transform: rotate(-180deg);
        }
      }
    }
    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10rem;
      width: 100%;
      height: auto;
      .side-box {
        padding-bottom: 12rem;
        .logo-box {
          text-align: center;
          &.mw256 {
            min-width: 26.4rem;
          }
          img {
            height: 3.2rem;
          }
          span {
            display: block;
            font-size: 1.4rem;
            font-weight: 600;
            color: #fff;
            margin-top: 1.5rem;
          }
        }
        ul {
          li {
            &:not(:last-child) {
              margin-bottom: 1rem;
            }
            span {
              display: inline-block;
              font-size: 1.8rem;
              font-weight: 400;
              color: #fff;
              padding-left: 1.5rem;
              position: relative;
              &::after {
                content: '';
                display: block;
                width: 0.4rem;
                height: 0.4rem;
                border-radius: 50%;
                background-color: #fff;
                position: absolute;
                left: 0;
                top: 0.7rem;
              }
            }
          }
        }
        &.right {
          ul {
            li {
              text-align: right;
            }
          }
        }
      }
      .center-box {
        width: 52.1rem;
        height: auto;
        &.w100 {
          width: 100%;
          .circle-wrap {
            width: 52.1rem;
            margin: 0 auto;
          }
        }
        .circle-wrap {
          width: 100%;
          height: 34rem;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          .swiper-wrapper {
            align-items: center;
          }
          .swiper-slide {
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              &.mo {
                display: none;
              }
            }
          }
          span {
            display: block;
            font-size: 2.2rem;
            font-weight: 700;
            color: #fff;
            position: absolute;
            left: 50%;
            bottom: 5rem;
            transform: translateX(-50%);
            white-space: nowrap;
          }
        }
        .mobile-box {
          display: none;
        }
        .info {
          margin-top: 5rem;
          .subject {
            font-size: 2.8rem;
            font-weight: 700;
            color: #fff;
            text-align: center;
            br {
              display: none;
            }
          }
          .text{
            min-height: 9.6rem;
            font-size: 1.8rem;
            line-height: 1.78;
            font-weight: 300;
            color: #fff;
            text-align: center;
            margin-top: 1.5rem;
            white-space: pre-line;
          }
        }
      }
    }
  }
  
  @media screen and (max-width: 1300px) {
    .slider-wrap {
      .item {
        gap: 5rem;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 12.4rem 0 14rem;
    min-height: 89.2rem;
    .title-wrap {
      margin-bottom: 7.8rem;
      h2 {
        font-size: 3rem;
      }
      span {
        font-size: 1.6rem;
        line-height: 2.8rem;
        margin-top: 2.4rem;
      }
    }
    .bg-box {
      .pc {
        > div {
          display: none;
        }
      }
      .tablet-small {
        > div {
          display: none;
        }
      }
      .tablet-big {
        > div {
          display: block;
        }
      }
    }
    .slider-wrap {
      .item {
        .side-box {
          &.pc {
            display: none;
          }
        }
        .center-box {
          width: 100%;
          .mobile-box {
            max-width: 48.2rem;
            min-height: 15.3rem;
            display: flex;
            justify-content: center;
            padding-top: 3rem;
            margin: 0 auto;
            &.space {
              justify-content: space-between;
            }
            .side-box {
              padding: 0;
              text-align: center;
              ul {
                li {
                  span {
                    font-size: 1.6rem;
                  }
                }
              }
            }
          }
          .circle-wrap {
            width: 24.8rem;
            height: 20.7rem;
            margin: 0 auto;
            .swiper-slide {
              img {
                width: 100%;
                &.mo {
                  display: block;
                }
                &.pc {
                  display: none;
                }
              }
            }
            span {
              font-size: 1.4rem;
              bottom: 4.4rem;
            }
          }
          &.w100 {
            .circle-wrap {
              width: 24.8rem;
            }
          }
          .info {
            margin-top: 0;
            .subject {
              font-size: 1.8rem;
              line-height: 3rem;
            }
            .text {
              font-size: 1.6rem;
              margin-top: 1.4rem;
              min-height: unset;
            }
          }
        }
      }
      .arrow-btn-wrap {
        button {
          width: 16.4rem;
          height: 4.2rem;
          transform: rotate(90deg);
          top: 8.4rem;
          &.slider-prev {
            left: -6.1rem;
          }
          &.slider-next {
            right: -6.1rem;
          }
          img {
            width: 1.2rem;
            left: 3.2rem;
          }
          span {
            font-size: 1.2rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: 500px) {
    padding: 12.4rem 0 0;
    min-height: 90.3rem;
    .title-wrap {
      padding: 0 1.6rem;
      margin-bottom: 7.8rem;
      span {
        line-height: 2.8rem;
        word-break: keep-all;
        br {
          display: block;
        }
      }
    }
    .bg-box {
      .tablet-big {
        > div {
          display: none;
        }
      }
      .mobile {
        > div {
          display: block;
        }
      }
    }
    .slider-wrap {
      .item {
        .center-box {
          .mobile-box {
            display: none;
            min-height: unset;
            &.item02 {
              padding-top: 7.2rem;
              padding-bottom: 6.8rem;
            }
            &.item01 {
              display: none;
              padding: 5.7rem 0 7.1rem;
              .side-box {
                &.right {
                  margin-top: 4.6rem;
                }
              }
            }
            &.item03 {
              padding: 7.8rem 0 0;
            }
          }
          .info {
            padding: 0 1.6rem;
            margin-top: 7.8rem;
            .subject {
              line-height: 30px;
              word-break: keep-all;
              br {
                display: block;
              }
            }
            .text {
              word-break: keep-all;
              white-space: unset;
              min-height: 7.5rem;
              br {
                display: none;
              }
            }
          }
        }
      }
      .arrow-btn-wrap {
        button {
          top: 81px;
          transform: rotate(90deg);
        }
      }
    }
  }
`
