import React, { useEffect, useRef, useState } from 'react'
import { TechWrap } from './styles'
import Processing from './processing'
import AiData from './aiData'
import { useRecoilValue } from 'recoil'
import { langAtom } from '../../store/recoil/lang'

const Tech = () => {

  const lang = useRecoilValue(langAtom)

  const skillTitle = useRef<any>(null)
  const [skillTitleOn, setSkillTitleOn] = useState(false)
  const skillBox01 = useRef<any>(null)
  const [skillBox01On, setSkillBox01On] = useState(false)
  const skillBox02 = useRef<any>(null)
  const [skillBox02On, setSkillBox02On] = useState(false)
  const skillBox03 = useRef<any>(null)
  const [skillBox03On, setSkillBox03On] = useState(false)

  const [offset, setOffset] = useState(0)

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth >= 500) {
        setOffset(300)
      } else {
        setOffset(200)
      }
    }

    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      const skillTitleRect = skillTitle?.current.getBoundingClientRect()
      if (skillTitleRect.top < window.innerHeight - offset) {
        setSkillTitleOn(true)
      } else {
        setSkillTitleOn(false)
      }
      const skillBox01Rect = skillBox01?.current.getBoundingClientRect()
      if (skillBox01Rect.top < window.innerHeight - offset) {
        setSkillBox01On(true)
      } else {
        setSkillBox01On(false)
      }
      const skillBox02Rect = skillBox02?.current.getBoundingClientRect()
      if (skillBox02Rect.top < window.innerHeight - offset) {
        setSkillBox02On(true)
      } else {
        setSkillBox02On(false)
      }
      const skillBox03Rect = skillBox03?.current.getBoundingClientRect()
      if (skillBox03Rect.top < window.innerHeight - offset) {
        setSkillBox03On(true)
      } else {
        setSkillBox03On(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])



  // 타이틀 변경
  useEffect(() => {
    const titleElement = document.getElementsByTagName('title')[0]
    if (titleElement) {
      titleElement.innerHTML = 'Tech | 비큐AI'
    }

    return () => {
      titleElement.innerHTML = '비큐AI'
    }
  }, [])

  return (
    <TechWrap>
      <div className='visual'>
        <div className="container">
          <div className="title-wrap">
            {
              lang ? (
                <h2>
                  WIGO는<br className='mobile' /> BECUAI의 <br/>
                  기술 브랜드입니다.
                </h2>
              ) : (
                <h2>
                  WIGO is the<br className='mobile' /> technology <br/>
                  brand of BECUAI
                </h2>
              )
            }
            {
              lang ? (
                <span>
                  ‘BECUAI’의 기술 브랜드인 ‘WIGO(What Is <br className='mobile'/>Going On)’는 고객의 관점에서 <br className='pc'/>
                  고객의 필요와 <br className='mobile'/>요구를 해결하겠다는 의지를 담고 있습니다.
                </span>
              ) : (
                <span>
                  WIGO (What Is Going On), the technology brand of BECUAI,
                  reflects the determination to address customer needs and
                  requirements from their perspective.
                </span>
              )
            }
          </div>
        </div>
      </div>
      <section className='sec01'>
        <div className="container">
          {
            lang ? (
              <h2 
                ref={skillTitle}
                className={skillTitleOn ? 'on' : ''}
              >
                WIGO는 3가지 기술을<br/>
                연구합니다.
              </h2>
            ) : (
              <h2 
                ref={skillTitle}
                className={skillTitleOn ? 'on' : ''}
              >
                WIGO explores <br/>
                3 kinds of AI Technology.
              </h2>
            )
          }
          <div 
            className={skillBox01On ? 'box on' : 'box'}
            ref={skillBox01}
          >
            <img src="/image/tech/airoot-icon.svg" alt="airoot icon" className='icon1' />
            <h3>AIROOT annotation S/W Platform</h3>
            <p>Platform specialized for text annotation</p>
          </div>
          <div 
            className={skillBox02On ? 'box on' : 'box'}
            ref={skillBox02}
          >
            <img src="/image/tech/database.svg" alt="database icon" className='icon2' />
            <h3>Database</h3>
            <p>Ready For Generative AI, RAG</p>
          </div>
          <div 
            className={skillBox03On ? 'box on' : 'box'}
            ref={skillBox03}
          >
            <img src="/image/tech/ai-builder-icon.svg" alt="airoot icon" className='icon3' />
            <h3>AI Builder </h3>
            <p>Prompt Engineering Platform, RPA, API STORE</p>
          </div>
        </div>
      </section>
      <Processing />
      <AiData />
    </TechWrap> 
  )
}

export default Tech
