import React, { useEffect } from 'react'
import {
  Route,
  Routes,
  useLocation,
} from 'react-router-dom'
import ScrollToTop from './ScrollToTop'

/*************
 layout
 *************/

import DefaultLayout from '../layout/DefaultLayout'
import EnDefaultLayout from '../layout/EnDefaultLayout'

/*************
 public
 *************/

import Home from '../page/home'
import Data from '../page/data'
import Tech from '../page/tech'
import Products from '../page/products'
import IRPR from '../page/invest'
import NoticeDetail from '../page/invest/notice/detail'
import Company from '../page/company'

import EnHome from '../page/en/home'
import EnData from '../page/en/data'
import EnTech from '../page/en/tech'
import EnProducts from '../page/en/products'
import EnIRPR from '../page/en/invest'
import EnNoticeDetail from '../page/en/invest/notice/detail'
import EnCompany from '../page/en/company'

import Error from '../page/error'
import { useRecoilState } from 'recoil'
import { langAtom } from '../store/recoil/lang'

interface RouteInterface {
  name: string
  path: string
  element: any
  child?: RouteInterface[]
}

const Routers = () => {

  const [lang, setLang] = useRecoilState(langAtom)
  const location = useLocation()

  const publicRoutes: RouteInterface[] = [
    {name: 'Home', path: '', element: Home},
    {name: 'Data', path: '/data', element: Data},
    {name: 'Tech', path: '/tech', element: Tech},
    {name: 'Company', path: '/company', element: Company},

    {name: 'IRPR', path: '/invest', element: IRPR},
    {name: 'IRPR', path: '/invest/:type', element: IRPR},
    {name: 'newsDetail', path: '/invest/notice/:pageNum', element: NoticeDetail},

    {name: 'Products', path: '/products', element: Products},
  ]

  const enPublicRoutes: RouteInterface[] = [
    {name: 'EnHome', path: '/en', element: EnHome},
    {name: 'EnData', path: '/en/data', element: EnData},
    {name: 'EnTech', path: '/en/tech', element: EnTech},
    {name: 'EnCompany', path: '/en/company', element: EnCompany},
    {name: 'EnIRPR', path: '/en/invest', element: EnIRPR},
    {name: 'EnIRPR', path: '/en/invest/:type', element: EnIRPR},
    {name: 'EnnewsDetail', path: '/en/invest/notice/:pageNum', element: EnNoticeDetail},
    {name: 'EnProducts', path: '/en/products', element: EnProducts},
  ]

  // en쪽이 나중 소스라 그런지, 군더더기가 없는듯 한 느낌... 추후 디렉토리별 소스 비교해서 한쪽 날릴 것.
  // 위의 En 유무쪽만 한쪽으로 맞춰주면 해결.


  const layoutRoute: RouteInterface[] = [
    {name: 'defaultLayout', path: '/', element: DefaultLayout, child: publicRoutes},
    {name: 'EnDefaultLayout', path: '/en', element: EnDefaultLayout, child: enPublicRoutes},
    {name: 'Error', path: '/*', element: Error}
  ]

  useEffect(() => {
    if(location.pathname.includes('/en')) {
      setLang(false)
    } else {
      setLang(true)
    }
  }, [])

  return (
    <>
      <ScrollToTop/>
      <Routes>
        {renderRoutes(layoutRoute)}
      </Routes>
    </>
  )
}

function renderRoutes(routes: any) {
  return routes.map((route: any, index: number) => (
    <Route
      key={index}
      path={route.path}
      element={<route.element />}
    >
      {route.child && renderRoutes(route.child)}
    </Route>
  ));
}

export default Routers
