import React, { useEffect, useState } from 'react'
import { CoreTechnologyWrap } from './styles'
import { useRecoilValue } from 'recoil'
import { langAtom } from '../../store/recoil/lang'

const CoreTechnology = () => {

  const lang = useRecoilValue(langAtom)

  const listItem = [
    {
      title: lang ? '잡지' : 'Magazines',
      value: 180,
    },
    {
      title: lang ? '지면 신문' : 'Newspapers',
      value: 350,
    },
    {
      title: lang ? '카페,블로그,SNS' : 'Broadcasting & Telecommunications',
      value: 60,
    },
    {
      title: lang ? '인터넷신문' : 'Internet News',
      value: 2600,
    },
    {
      title: lang ? '방송&통신사' : 'Social Media',
      value: 60,
    },
  ]

  const [list, setList] = useState([])
  const [listIcon, setListIcon] = useState([
    '/image/product/searchIcon/img-1.png',
    '/image/product/searchIcon/img-2.png',
    '/image/product/searchIcon/img-3.png',
    '/image/product/searchIcon/img-4.png',
    '/image/product/searchIcon/img-5.png',
  ])

  const [fillList, setFillList] = useState(false)
  const [topValue, setTopValue] = useState(9)
  const [active, setActive] = useState({
    cnt: 0,
    titleTopValue: '0rem',
    iconTopValue: '0rem',
  })

  useEffect(() => {
    let temp:any = []

    Array.from({length: 5}).forEach((it, index) => {
      temp.push( index === 0 ? [...listItem] : temp[index - 1] )
      const firstItem = temp[index][0]
      const newList = temp[index].slice(1);
      temp[index] = [...newList, firstItem]
    })
    setList(temp)
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      setActive(prevActive => {
        const cnt = prevActive.cnt + 1;
        let titleTopValue, iconTopValue;
  
        if (window.innerWidth >= 768) {
          titleTopValue = `-${topValue * cnt}rem`;
          iconTopValue = `-${10 * cnt}rem`;
        } else {
          titleTopValue = `-11.7188vw`;
          iconTopValue = `-12.5vw`;
        }
  
        return {
          cnt: cnt,
          titleTopValue: titleTopValue,
          iconTopValue: iconTopValue,
        };
      });
    }, 2550);
  
    return () => clearInterval(interval);
  }, [fillList]);

  useEffect(() => {
    if(list.length > 0) setFillList(true)
  }, [list])

  useEffect(() => {
    if(active.cnt > 0) {

      setTimeout(() => {
        setListIcon(prev => {
          const temp = [...prev];
          let swap: any = temp.shift();
          temp.push(swap);
          return [...temp];
        });
      }, 500)

      setTimeout(() => {
        setList(prev => {
          let temp: any = []

          prev.map((listItem: any) => {
            const item = [...listItem]
            let swap = item.shift()
            item.push(swap)
            temp.push(item )
          })

          return temp
        })
        setActive({
          cnt: 0,
          titleTopValue: `0rem`,
          iconTopValue: `0rem`,
        });
      }, 500)

    }
  }, [active])

  useEffect(() => {
    if(active.cnt === 0) {
      setListIcon(listIcon)
    }
  }, [active])

  return (
    <CoreTechnologyWrap>
      <img src="/image/tech/sec04-img.svg" alt="배경 이미지" className='pc' />
      <img src="/image/mobile/tech/sec04-img.png" alt="배경 이미지" className='mobile' />
      <div className='searchList-wrap'>

        <div className='search-icon-wrap'>
          <div style={{transition: active.cnt == 0 ? '' : '.5s', top: active.iconTopValue}}>
            {
              listIcon.map((icon, iconKey) => (<img key={iconKey} src={icon} alt={icon} />))
            }
          </div>
        </div>
        {
          list.map((it: any, key) => (
            <div className='search-list-item' key={key}>
              {
                it.map((item: any, key2: number) => {
                  return <div className='search-list-item-wrap'key={key2}>
                    <div>
                      {key !== 1 && <p className={'number'}>{key + 1}</p>}
                      <p style={{top: active.titleTopValue, transition: active.cnt == 0 ? '' : '.5s'}}>{item.title}</p>
                    </div>
                    {key === 1 && <p className={'value'}>
                      <span style={{top: active.titleTopValue, transition: active.cnt == 0 ? '' : '.5s'}}>{Number(item.value).toLocaleString('ko-KR')}</span>
                      {lang ? '매체' : 'partners'}
                    </p>}
                  </div>
                })
              }

            </div>
          ))
        }
      </div>
    </CoreTechnologyWrap>
  )
}

export default CoreTechnology
