import styled from "styled-components";

export const Wrap = styled.div`
  background-color: #FFFFFF;
`

export const CeoImag = styled.img`
  flex: 1;
  position: absolute;
  height: auto;
  bottom: 0;
  &.mobile {
    display: none;
  }
  @media screen and (max-width:1200px){
    position: relative;
    width: 100%;
    height: auto;
    padding-bottom: 12rem;
  }

  @media screen and (max-width: 500px) {
    &.pc {
      display: none;
    }
    &.mobile {
      display: block;
    }
  }
`

export const CeoBody = styled.div`
  padding-top: 12rem;
  position: relative;
  min-width: 375px;
  width: 1200px;
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width:1200px){
    flex-direction: column-reverse;
    div:first-child{
      width: 100%;
    }
    div:last-child {
      padding: 12rem 1.6rem 0;
      display: inline;
    }
  }
  @media screen and (max-width: 500px) {
    padding-top: 0;
    min-width: unset;
    width: 100%;
    div:last-child {
      padding: 0 1.6rem;
      display: block;
    }
  }
`


export const CEOInfomation = styled.p`
  font-size: 5rem;
  font-weight: 800;
  padding-bottom: 4rem;
  line-height: 5.967rem;
  text-align: left;
  color: #333333;

  @media screen and (max-width: 500px) {
    font-size: 3rem;
    line-height:3.58rem;
    padding-top: 12rem;
  }
`

export const CeoInfoTitle = styled.p`
  font-size: 18px;
  font-weight: 800;
  text-align: left;
  color: #333333;
  margin-bottom: 2rem;

  @media screen and (max-width: 500px) {
    font-size: 1.6rem;
    line-height: 2.8rem;
  }
`
export const CeoInfoContent = styled.p`
  font-size: 18px;
  font-weight: 300;
  line-height: 3.8rem;
  text-align: left;
  color: #666666;
  padding: 0 0 2rem 0;

  @media screen and (max-width: 500px) {
    font-size: 1.6rem;
    line-height: 2.8rem;
    word-break: break-all;
    br {
      display: none;
    }
  }
`

export const CeoSign = styled.p`
  padding-top: 3rem;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;

  @media screen and (max-width: 500px) {
    font-size: 1.6rem;
    line-height: 3.4rem;
    padding-top: 1rem;
    margin-bottom: 3rem;
    strong {
      font-size: 2.4rem;
    }
  }
`
