import React, { useEffect, useState } from 'react'
import { AiDataWrap } from './styles'
import { useRecoilValue } from 'recoil'
import { langAtom } from '../../store/recoil/lang'

const aiImage = [
  "/image/tech/core-technology-img01.svg",
  "/image/tech/core-technology-img02.svg",
  "/image/tech/core-technology-img03.svg",
]

const AiData = () => {

  // 다국어
  const lang = useRecoilValue(langAtom)

  const [timer, setTimer] = useState(5000)

  const [stepActive, setStepActive] = useState(0);
  let interval: NodeJS.Timeout


  const handleClick = (i: number) => {
    setTimer(10000)
    clearInterval(interval)
    setStepActive(i)
  }

  useEffect(() => {
    interval = setInterval(() => {
      setStepActive(prevStepActive => {
        return prevStepActive === 2 ? 0 : prevStepActive + 1
      })
      setTimer(5000)
    }, timer)
    return () => {
      clearInterval(interval)
    }
  }, [timer])

  return (
    <AiDataWrap>
      <section className='sec02'>
        <div className='container'>
          <div className='about'>
            <img src='/image/mckinsey-degital.svg' alt='mckinsey degital' className='pc' />
            <img src='/image/mckinsey-degital.png' alt='mckinsey degital' className='mobile' />
            <dl>
              <dt>
                McKinsey<br/>
                Digital
              </dt>
              <dd>12 May 2023</dd>
            </dl>
          </div>
          <div className='info'>
            <p>
              {
                lang ? `“Generative AI의 핵심은 특정 비즈니스 상황이나 문제에 맞게 연마된 데이터에 대한 유연한 엑세스입니다.
                데이터를 효과적으로 연계시키고 데이터에 즉시 엑세스할 수 있는 방법을 아직 찾지 못한 기업은 Generative AI를 파인튜닝할 수 없으며, 잠재력 큰 혁신의 기회로 연계할 수 없습니다. 기업들에게 비즈니스 가치에 기반을 둔 명확한 데이터&인프라 전략을 통한 Generative AI에 파생되는 경쟁 우위는 더욱 더 중요해 질 것 입니다.”` : `“The lifeblood of generative AI is fluid access to data honed for a specific business context or problem. Companies that have not yet found ways to effectively harmonize and provide ready access to their data will be unable to finetune generative AI to unlock more of its potentially transformative uses. … A clear data and infrastructure strategy anchored on the business value and competitive advantage derived from generative AI will be critical.”`
              }
            </p>
            <span>What every CEO should know<br className='mobile'/> about generative AI</span>
          </div>
        </div>
      </section>
      <section className='sec03'>
        <div className="container">
          <div className="title-wrap">
            <h2>WIGO AI+Data</h2>
            {
              lang ? (
                <p>
                  BECUAI는 데이터 중심의 인공지능 도입을 적극적으로 추진하고, 패러다임 변화를 지원하는 <br/>
                  기술 및 데이터 인터페이스 제공을 목표로 합니다.
                </p>
              ) : (
                <p>
                  BECUAI is dedicated to introducing data-centric artificial intelligence and <br/>
                  endeavors to offer technological and data interfaces that facilitate a paradigm shift.
                </p>
              )
            }
          </div>
          <div className="flex-box">
            <div className='img-box'>
              <img src='/image/tech/wigo-ai-data-img.svg' alt='WIGO AI+Data' />
            </div>
            <div className="text-box">
              <h3>Modern AI Stack</h3>
              <p>
                {lang ? `최신 인공지능 기술의 진보와 함께, 데이터의 유연한 액세스와 결합을 목적으로 인공지능 기술의 Stack을 연구 개발합니다.` : `We are conducting research and development on the artificial intelligence technology stack, aiming to integrate flexible access to data alongside advancements in the latest artificial intelligence technologies.`}
              </p>
              <ul>
                <li>
                  <img src="/image/tech/wigo-ai-data-icon01.svg" alt="wigo-ai-data-icon01" />
                  {
                    lang ? (
                      <span>저작권이 확보된 최신 데이터 활용</span>
                    ) : (
                      <span>Use of the latest copyrighted database</span>
                    )
                  }
                </li>
                <li>
                  <img src="/image/tech/wigo-ai-data-icon02.svg" alt="wigo-ai-data-icon02" />
                  {
                    lang ? (
                      <span>정확한 전처리와 메타 데이터 분석 탑재</span>
                    ) : (
                      <span>Accurate pre-processing and metadata analysis</span>
                    )
                  }
                </li>
                <li>
                  <img src="/image/tech/wigo-ai-data-icon03.svg" alt="wigo-ai-data-icon03" />
                  {
                    lang ? (
                      <span>명료하고 환각효과 없는 결과 도출</span>
                    ) : (
                      <span>Clear outcome generation without hallucination</span>
                    )
                  }
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className='sec04'>
        <div className='container'>
          <div className='title-wrap'>
            <span>CORE TECHNOLOGY</span>
            
            {
              lang ? (
                <>
                  <h2>핵심기술 및 연구범위</h2>
                  <p>
                    최신 AI 활용을 위해서는 각 분야에 맞는 연구, 내/외부 솔루션의 적용 노하우, 그리고 LLM의 <br/>
                    장점 활용을 위한 적합한 기반 기술과 응용력이 필요합니다. BECUAI는 최신의 인공지능 기술들을 <br/>
                    빠르고 간편하게 연결하고, 최상의 퍼포먼스를 제공하기 위해 핵심 기술들을 연구합니다. 
                  </p>
                </>
              ) : (
                <>
                  <h2>Core technology & <br/>Scope of the study</h2>
                  <p>
                    Utilizing the latest AI solution requires specialized research, know-how in applying <br/>
                    solutions both internally and externally, and the advantage of LLM. BECUAI <br/>
                    researches the core technologies to easily and quickly connect the latest AI <br/>
                    technologies and provide the best performance.
                  </p>
                </>
              )
            }
          </div>
          <div className="flex-box">
            <div className="step-box">
              <ul className={stepActive === 2 ? 'flex-start' : ''}>
                <li 
                  className={stepActive === 0 ? 'on' : ''}
                  onClick={() => handleClick(0)}
                >
                  <div className="mo-img">
                    <img src='/image/mobile/tech/core-technology-img01.png' alt="core-technology-img" />
                  </div>
                  <h3>Data Pipeline</h3>
                  <div className={lang ? "text-wrap" : "text-wrap en"}>
                    <p>
                      {lang ? '기업이 자체적인 생성형 AI 솔루션 구축을 위해 신뢰할 수 있는 최신 데이터를 필요로 하는 경우 API를 통해 Stream Data를 제공하는 기술과 Batch Data를 제공하는 기술을 연구합니다.' : `BECUAI is researching technologies to provide stream and batch Data via API, catering to companies that require dependable and up-to-date data for developing their own generative AI.`}
                    </p>
                    <div className="list-box">
                      <dl>
                        <dt>
                          <img src="/image/tech/tech-icon01.svg" alt="tech-icon01" />
                        </dt>
                        <dd>
                          <span>Improved data quality</span>
                          {lang ? '형식 표준화 및 중복 제거' : 'Format standardization and deduplication'}
                        </dd>
                      </dl>
                      <dl>
                        <dt>
                          <img src="/image/tech/tech-icon02.svg" alt="tech-icon02" />
                        </dt>
                        <dd>
                          <span>Efficient data processing</span>
                          {lang ? '반복 작업 자동화' : 'Automating repetitive tasks'}
                        </dd>
                      </dl>
                      <dl>
                        <dt>
                          <img src="/image/tech/tech-icon03.svg" alt="tech-icon03" />
                        </dt>
                        <dd>
                          <span>Comprehensive data integration</span>
                          {lang ? '통합 및 검증' : 'Integration and validation'}
                        </dd>
                      </dl>
                    </div>
                  </div>
                </li>
                <li 
                  className={stepActive === 1 ? 'on' : ''}
                  onClick={() => handleClick(1)}
                >
                  <div className="mo-img">
                    <img src='/image/mobile/tech/core-technology-img02.png' alt="core-technology-img" />
                  </div>
                  <h3>RAG</h3>
                  <div className={lang ? "text-wrap" : "text-wrap en"}>
                    <p>
                      {lang ? '이미 최적화 되어 운용되고 있는 데이터를 별도의 자체 RAG 구축과정 없이 즉시 자체 서비스에 연동 적용하고 싶어하는 고객을 위해, Ranking Algorithm을 반영한 고품질 Data 리턴을 제공하기 위한 연구 개발을 진행하고 있습니다.' : 'We are conducting research and development to offer high-quality data returns by integrating a Ranking Algorithm, for customers seeking seamless integration into their services without RAG setup process.'}
                    </p>
                    <div className="list-box">
                      <dl>
                        <dt>
                          <img src="/image/tech/tech-icon04.svg" alt="tech-icon04" />
                        </dt>
                        <dd>
                          <span>Embedding model</span>
                          {lang ? '다양한 모델 활용 및 유연성 연구' : 'Research on flexibility and utilization of various models'}
                        </dd>
                      </dl>
                      <dl>
                        <dt>
                          <img src="/image/tech/tech-icon05.svg" alt="tech-icon05" />
                        </dt>
                        <dd>
                          <span>Vectordatabase</span>
                          {lang ? '활용 가능한 데이터베이스 특장점 연구' : 'Research on usable database'}
                        </dd>
                      </dl>
                      <dl>
                        <dt>
                          <img src="/image/tech/tech-icon06.svg" alt="tech-icon06" />
                        </dt>
                        <dd>
                          <span>APIs</span>
                          {lang ? '접목 가능한 API를 통한 활용성 향상 연구' : 'Research on enhancing usability through the applicable APIs'}
                        </dd>
                      </dl>
                    </div>
                  </div>
                </li>
                <li 
                  className={stepActive === 2 ? 'on' : ''}
                  onClick={() => handleClick(2)}
                >
                  <div className="mo-img">
                    <img src='/image/mobile/tech/core-technology-img03.png' alt="core-technology-img" />
                  </div>
                  <h3>Prompt Engineering</h3>
                  <div className={lang ? "text-wrap" : "text-wrap en"}>
                    <p>
                      {lang ? `Generative AI를 직접적으로 더 활용하기 위한 일환으로써, 프롬프트와 모델을 평가하고 RAG 및 API를 사용하여 에이전트와 통합한 다음 프로덕션에 배포하고 지속적으로 개선하는 플랫폼 개발을 목표로 Prompt Engineering을 연구합니다.` : `As part of the effort to more directly leverage Generative AI, we are researching Prompt Engineering to evaluate prompts and models, integrating with agents using RAG and API, deploying to production and continuously improving the platform.`}
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="img-box">
              <img src={aiImage[stepActive]} alt="core-technology-img" />
            </div>
          </div>
        </div>
      </section>
    </AiDataWrap>
  )
}

export default AiData
