import React from 'react'
import { FooterWrap } from './styles'
import { Link } from 'react-router-dom'
import {useTranslation} from "react-i18next";
import { useRecoilValue } from 'recoil';
import { langAtom } from '../../store/recoil/lang';

const Footer = () => {
  const { t } = useTranslation()

  // 다국어
  const lang = useRecoilValue(langAtom)

  const siteMap = [
    {
      name: 'Data',
      link: '/data'
    },
    {
      name: 'Tech',
      link: '/tech'
    },
    {
      name: 'Products',
      link: '/products'
    },
    {
      name: 'IR·PR',
      link: '/invest/finance'
    },
    {
      name: 'Company',
      link: '/company'
    },
    /*{
      name: 'Careers',
      link: '/careers'
    },*/
  ]

  return (
    <FooterWrap>
      <div className='container'>
        <div className='left-box'>
          <Link to={'/'}>
            <img src='/image/f-logo.svg' alt='BECUAI 로고' />
          </Link>
          {
            lang ? (
              <h3>
                우리는 데이터로 미래를 만듭니다. <br/>Trustworthy Data & Technology
              </h3>
            ) : (
              <h3>
                We create the future with data. <br/>Trustworthy Data & Technology
              </h3>
            )
          }
        </div>
        <div className='right-box'>
          <div className='company-info'>
            <h4>Contact us</h4>
            <div className='contact-us'>
              <div className="info">
                {
                  lang ? (
                    <>
                      <p>(주)비큐AI   대표이사 : 임경환</p>
                      <p>서울특별시 중구 퇴계로 385 준타워 9층</p>
                      <p>사업자등록번호 : 221-81-13339</p>
                    </>
                  ) : (
                    <>
                      <p>ⓒBECUAI   CEO : Kyoung-Hwan Im</p>
                      <p>Jun Tower 9th FLOOR, 385, Toegye-ro, Jung-gu, Seoul, Republic of Korea</p>
                      <p>Business Registration Number : 221-81-13339</p>
                    </>
                  )
                }
              </div>
              <div className="service">
                <p>
                  {
                    lang ? (
                      <span>전화</span>
                    ) : (
                      <span className='en'>TEL</span>
                    )
                  }
                  02-3487-0215
                </p>
                <p>
                  {
                    lang ? (
                      <span>팩스</span>
                    ) : (
                      <span className='en'>FAX</span>
                    )
                  }
                  02-3487-0216
                </p>
                <p>
                  {
                    lang ? (
                      <span>제휴문의</span>
                    ) : (
                      <span className='en'>Partnership inquiries</span>
                    )
                  }
                  sales@becuai.com
                </p>
              </div>
            </div>
          </div>
          <img className={'kosdac-logo pc'} src={'/image/kosdac-logo.svg'} alt={'kosdac-logo'}/>
          <img className={'kosdac-logo mo'} src={'/image/kosdac-logo.png'} alt={'kosdac-logo'}/>
          <div className='copyright'>© BECUAI Corp. ALL RIGHTS RESERVED.</div>
        </div>
      </div>
    </FooterWrap>
  )
}

export default Footer
