import {InvestNewsBody, IRPRWrap, TabLi, TabUl, Title} from "./styles";
import {Body, FlexRowWrap, WrapPadding} from "../../common/commonStyle";
import {useEffect, useState} from "react";
import Finance from "./finance";
import Disclosure from "./disclosure";
import News from "./news";
import Notice from "./notice";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Svg from "../../module/svg";
import { useRecoilValue } from "recoil";
import { langAtom } from "../../store/recoil/lang";

const Index = () => {

  // 다국어
  const lang = useRecoilValue(langAtom)

  const tabTitle = ['재무정보', '보도자료', '공지사항', '공시정보']
  const enTabTitle = ['Financial Information', 'Press Release', 'Notice', 'Disclosure Information']
  const tabType = ['finance', 'news', 'notice']

  const location = useLocation()
  const navigate = useNavigate()
  const {type} = useParams()

  const [tabActive, setTabActive] = useState<any>(null)

  const [tabClass, setTabClass] = useState(false)

  const handleTabPage = () => {
    switch (tabActive) {
      case 'finance':
        return <Finance />
      case 'news':
        return <News />
      case 'notice':
      default:
        return <Notice />
    }
  }

  // 타이틀 변경
  useEffect(() => {
    const titleElement = document.getElementsByTagName('title')[0]
    if (titleElement) {
      titleElement.innerHTML = 'IR | 비큐AI'
    }

    return () => {
      titleElement.innerHTML = '비큐AI'
    }
  }, [])

  useEffect(() => {
    setTabActive(type)
  }, [type])

  useEffect(() => {
    // 첫 화면 진입시 finance 이동
    location.pathname === '/invest' && navigate('/invest/finance')

    location.pathname === '/invest/news' ? setTabClass(true) : setTabClass(false)
  }, [location])

  return <IRPRWrap>
    <WrapPadding/>
    <FlexRowWrap> <Title>IR·PR</Title> </FlexRowWrap>
    <FlexRowWrap>
      <InvestNewsBody className={tabClass ? 'p10' : ''}>
        <TabUl>
          {(lang ? tabTitle : enTabTitle).map((title, key) => (
            <TabLi key={key}>
              {key === 3 ? (
                <div onClick={() => window.open("https://dart.fss.or.kr/dsab001/main.do?autoSearch=true&textCrpNm=비플라이소프트", "_blank")}>
                  {title}<Svg
                  icon={'linkArrow'}
                  width={11}
                  height={11}
                  fill={'#ccc'}
                />
                </div>
              ) : (
                <div className={`${tabActive === tabType[key] ? 'active' : ''}`} onClick={() => navigate(`/invest/${tabType[key]}`)}>
                  {title}
                </div>
              )}
            </TabLi>
          ))}
        </TabUl>
      </InvestNewsBody>
    </FlexRowWrap>
    <FlexRowWrap>
      <Body pdbottom={'14rem'} flexdirection={'column'}>
        { handleTabPage() }
      </Body>
    </FlexRowWrap>
  </IRPRWrap>
}

export default Index
