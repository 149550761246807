import React, { useEffect } from 'react'
import {WrapPadding} from '../../common/commonStyle'
import {ProductsWrap} from './styles'
import Section01 from "./section/section01";
import Sec02 from './section/sec02';
import Sec03 from './section/sec03';

const Products = () => {

  // 타이틀 변경
  useEffect(() => {
    const titleElement = document.getElementsByTagName('title')[0]
    if (titleElement) {
      titleElement.innerHTML = 'Products | 비큐AI'
    }

    return () => {
      titleElement.innerHTML = '비큐AI'
    }
  }, [])

  return (
    <ProductsWrap>
      <WrapPadding />
      <Section01 />
      <Sec02 />
      <Sec03 />
    </ProductsWrap>
  )
}

export default Products
