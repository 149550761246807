import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from './enDefault/Header'
import Footer from './enDefault/Footer'
import { Wrap } from './styles'

const EnDefaultLayout = () => {
  return (
    <>
      <Header/>
      <Wrap>
        <Outlet/>
      </Wrap>
      <Footer/>
    </>
  )
}

export default EnDefaultLayout
