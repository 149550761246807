import {defaultService} from "../service";

const PopupAction = () => {

  const getPopup = async (setItem: any) => {
    return await defaultService.handleService({
      method: 'get',
      endPoint: `/v1/popup`
    }).then(
      response => setItem(response.data.payload),
      error => console.log(error.message)
    )
  }

  const view = async (id: string) => {
    console.log('=-[=============')
    return await defaultService.handleService({
      method: 'get',
      endPoint: `/v1/popup/view/${id}`
    }).then(
      response => response.data.payload,
      error => console.log(error.message)
    )
  }

  return {
    getPopup,
    view
  }
}

export default PopupAction
