import React from "react";
import styled from "styled-components";

const DivFrame = styled.div`
  position: fixed;

  z-index: 24;
  width: 244px;
  height: 88px;

  right: 60px;
  bottom: 50px;
  @media screen and (max-width: 1024px) {
    right: 20px;
    bottom: 20px;
  }
`;

const DivClose = styled.div`
  width:100%;
  text-align: right;

  .icon-area {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }

`;


const StyledPcFabBanner = styled.div`
  align-self: stretch;
  position: relative;
  cursor: pointer;

  background-color: #c80000;
  border-radius: 100px;
  height: 56px;
  overflow: hidden;
  position: relative;
  //width: 100%;
  width: 244px;

    // font-family: "Pretendard-Regular", Helvetica;

  & .overlap-group {
    pointer-events: none;
    height: 73px;
    position: relative;
  }

  & .bg {
    height: 56px;
    left: 0;
    position: absolute;
    top: 0;
    width: 244px;
  }

  & .main-text {
    color: #ffffff;
    font-size: 11px;
    font-weight: 400;
    left: 21px;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    top: 9px;
    white-space: nowrap;
  }

  & .text-wrapper {
    color: #ffffff;
    font-size: 12px;
    font-weight: 400;
    left: 17px;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    top: 53px;
    white-space: nowrap;
  }

  & .div {
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    left: 21px;
    letter-spacing: 0;
    line-height: 20px;
    position: absolute;
    top: 26px;
    white-space: nowrap;
  }

  & .phone {
    height: 32px;
    left: 170px;
    position: absolute;
    top: 13px;
    width: 52px;
  }
`;


export default function FloatingVuIntroBanner() {
  return (
    <DivFrame id='ppp'>
      <DivClose>
        <img className="icon-area" alt="Icon area"
      onClick={()=>{document.getElementById('ppp')?.remove(); }}
        
         src="/img/banner_close_icon_area.svg" />
      </DivClose>

      <StyledPcFabBanner>
      <a target="_blank" href="https://intro-vu.aisurfer.com">
      <div className="overlap-group">
        <img className="bg" alt="Bg" src="/img/banner_bg_vu.png" />
        <p className="main-text">홍보 전문가를 위한 기업 뉴스룸</p>
        <div className="text-wrapper">바로가기</div>
        <p className="div">AI서퍼 VU PC 버전 오픈 </p>
        <img className="phone" alt="Phone" src="/img/banner_laptop.svg" />
      </div>
      </a>
    </StyledPcFabBanner>

      {/* <img
        className="pc-FAB-banner"
        alt="Pc FAB banner"
        src="https://c.animaapp.com/I4gASIsk/img/pc-fab-banner@2x.png"
      /> */}
    </DivFrame>
  );
};
