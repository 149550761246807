import React, { useEffect, useState } from 'react'
import { Sec02Wrap } from './styles'
import { useRecoilValue } from 'recoil'
import { langAtom } from '../../../../store/recoil/lang'


const Sec02 = () => {

  // 다국어
  const lang = useRecoilValue(langAtom)

  const [width, setWidth] = useState(0)

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth)
    }

    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <Sec02Wrap>
      <section className='sec02-01'>
        <div className="container">
          {
            lang ? (
              <div className="text-box">
                <span>Pre data processing SW Platform</span>
                <h2>AI 기반 데이터 <br/>라벨링 & 전처리 플랫폼</h2>
                <p className='pb'>
                  다양한 형태의 데이터(TEXT, IMAGE, VIDEO)를 <br/>
                  수요자의 요청에 맞게 커스터마이징하여 최적화된 <br/>
                  데이터 라벨링&정제 가공 서비스를 제공합니다.
                </p>
              </div>
            ) : (
              <div className="text-box">
                <span>Pre data processing SW Platform</span>
                {
                  width <= 1024 ? (
                    <h2>
                      Data preprocessing <br/>
                      AI platform
                    </h2>
                  ) : (
                    <h2>
                      Data labeling & <br/>
                      Pre-processing <br/>
                      AI platform
                    </h2>
                  )
                }
                <p className='pb'>
                  Customized data labeling and refinement <br className='pc'/>services for all <br className='tablet'/>types of data (text, image, video)
                </p>
              </div>
            )
          }
          <div className="img-box">
            <img src="/image/product/new/section02-logo01.svg" alt="AIROOT" />
          </div>
        </div>
      </section>
      <section className='sec02-02'>
        <div className="container">
          {
            lang ? (
              <div className="text-box">
                <span>MCP</span>
                <h2>
                  AI 학습과 서비스를 위한 <br/>
                  Real time <br/>
                  data pipeline 플랫폼
                </h2>
                <p>
                  생성형 AI 학습에 필요한 데이터를 수요자가 필요한 형태로 <br/>
                  가공하여 대량 공급 또는 실시간 API로 제공합니다.
                </p>
                <button 
                  type='button'
                  onClick={() => window.open('https://www.rdpline.com/', '_blank')}
                >
                  더 보기
                </button>
              </div>
            ) : (
              <div className="text-box">
                <span>MCP</span>
                <h2>
                  Real-time <br/>
                  Data pipeline for <br/>
                  AI training
                </h2>
                <p>
                  Real-time data supply pipelines for 'Training <br className='pc'/>Generative AI <br className='tablet'/>Models' and various services.
                </p>
                <button 
                  type='button'
                  onClick={() => window.open('https://www.rdpline.com/', '_blank')}
                >
                  More
                </button>
              </div>
            )
          }
          <div className="img-box">
            <img src="/image/product/new/section02-logo02.svg" alt="AIROOT" />
          </div>
        </div>
      </section>
    </Sec02Wrap>
  )
}

export default Sec02