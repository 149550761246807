import React, { useEffect, useRef, useState } from "react";
import { DataCountWrap } from "./styles";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import SwiperCore from "swiper";
import moment from "moment";
import DataAction from "../../store/action/data";
import { useRecoilValue } from "recoil";
import { langAtom } from "../../store/recoil/lang";

const DataCount = () => {
  // 다국어
  const lang = useRecoilValue(langAtom);

  const dataCountRef = useRef<any>(null);
  const [isOn, setIsOn] = useState(false);

  const { getValue } = DataAction();

  // Slider Active Index
  const [sliderActive, setSliderActive] = useState(0);

  const [dataList, setDataList] = useState([
    {
      title: lang ? "총 데이터 수집량" : "Total Data Quantatiy",
      date: lang
        ? moment().format("YYYY년 M월 DD일 기준")
        : moment().format("MMMM DD, YYYY"),
      count: 0,
      maxCount: 584262913,
    },
    {
      title: lang ? "1일 평균 데이터 처리량" : "Daily production of data",
      date: "",
      count: 0,
      maxCount: 150000,
    },
    {
      title: lang ? "데이터 수집채널" : "Multi-Channel Data Collection",
      date: "",
      count: 0,
      maxCount: 3000,
    },
  ]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setDataList(prevDataList => {
  //       const newDataList = [...prevDataList]
  //       newDataList[0].count += 3
  //       return newDataList
  //     })
  //   }, 3000)

  //   return () => clearInterval(interval)
  // }, [])

  const [swiper, setSwiper] = useState<SwiperCore>();

  const nextSlider = (index: number) => {
    setTimeout(() => {
      try {
        if (swiper && swiper.slideTo && typeof swiper.slideTo === "function") {
          swiper?.slideTo(index);
        }
      } catch (e) {}
    }, 1000);
  };

  useEffect(() => {}, []);

  useEffect(() => {
    getValue("dataValue").then((res) => {
      const diff = moment().diff("2024-03-28", "days");
      const temp = [...dataList];

      temp[0].maxCount = Number(res);

      setDataList([...temp]);
    });
  }, []);

  useEffect(() => {
    if (isOn) {
      if (sliderActive === 0) {
        const intervalId = setInterval(() => {
          if (dataList[0].count < dataList[0].maxCount) {
            setDataList((prevDataList) => {
              const newDataList = [...prevDataList];
              newDataList[0].count += Number(
                (Number(dataList[0].maxCount) / 102).toFixed(0)
              );
              return newDataList;
            });
          } else {
            clearInterval(intervalId);
            dataList[0].count !== dataList[0].maxCount && nextSlider(1);
            setDataList((prevDataList) => {
              const newDataList = [...prevDataList];
              newDataList[0].count = newDataList[0].maxCount;
              return newDataList;
            });
          }
        }, 10);

        return () => clearInterval(intervalId);
      } else if (sliderActive === 1) {
        const intervalId = setInterval(() => {
          if (dataList[1].count < dataList[1].maxCount) {
            setDataList((prevDataList) => {
              const newDataList = [...prevDataList];
              newDataList[1].count += Number(
                (Number(dataList[1].maxCount) / 102).toFixed(0)
              );
              return newDataList;
            });
          } else {
            clearInterval(intervalId);
            dataList[1].count !== dataList[1].maxCount && nextSlider(2);
            setDataList((prevDataList) => {
              const newDataList = [...prevDataList];
              newDataList[1].count = newDataList[1].maxCount;
              return newDataList;
            });
          }
        }, 10);

        return () => clearInterval(intervalId);
      } else if (sliderActive === 2) {
        const intervalId = setInterval(() => {
          if (dataList[2].count < dataList[2].maxCount) {
            setDataList((prevDataList) => {
              const newDataList = [...prevDataList];
              newDataList[2].count += Number(
                (Number(dataList[2].maxCount) / 102).toFixed(0)
              );
              return newDataList;
            });
          } else {
            clearInterval(intervalId);
            dataList[2].count !== dataList[2].maxCount && nextSlider(0);
            setDataList((prevDataList) => {
              const newDataList = [...prevDataList];
              newDataList[2].count = newDataList[2].maxCount;
              return newDataList;
            });
          }
        }, 10);

        return () => clearInterval(intervalId);
      } else {
        setDataList((prevDataList) =>
          prevDataList.map((item) => ({
            ...item,
            count: 0,
          }))
        );
      }
    }
  }, [sliderActive, dataList, isOn]);

  useEffect(() => {
    const handleScroll = () => {
      if (dataCountRef.current) {
        const { top } = dataCountRef.current.getBoundingClientRect();
        if (window.innerWidth >= 500) {
          if (window.innerHeight - top > 400) {
            setIsOn(true);
          } else {
            setIsOn(false);
          }
        } else {
          if (window.innerHeight - top > 250) {
            setIsOn(true);
          } else {
            setIsOn(false);
          }
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <DataCountWrap
      ref={dataCountRef}
      style={{
        background: `url('/image/data/data-count-bg0${
          (sliderActive % 3) + 1
        }.png') no-repeat center / cover #D80101`,
      }}
    >
      <div className="container">
        <div className="count-box-wrap">
          <Swiper
            onSwiper={setSwiper}
            loop={true}
            modules={[Navigation, Pagination]}
            slidesPerView={1}
            navigation={{
              prevEl: ".count-slider-prev",
              nextEl: ".count-slider-next",
            }}
            pagination={{ clickable: true }}
            onSlideChange={(swiper: any) => setSliderActive(swiper.realIndex)}
          >
            {dataList.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="data-count-item">
                    <div className="title-wrap">
                      <h3>{item.title}</h3>
                      <span>{item.date}</span>
                    </div>
                    <div className="count-wrap">
                      <span>
                        {item.count === 0
                          ? "-"
                          : item.count.toLocaleString("ko-KR")}
                      </span>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <div className="btn-wrap">
            <button type="button" className="count-slider-prev">
              <img src="/image/data/arrow-left.svg" alt="이전 화살표" />
            </button>
            <button type="button" className="count-slider-next">
              <img src="/image/data/arrow-right.svg" alt="다음 화살표" />
            </button>
          </div>
        </div>
      </div>
    </DataCountWrap>
  );
};

export default DataCount;
