import styled from "styled-components";

export const MobileGnbWrap = styled.div`
  opacity: 0;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  z-index: -1;
  transition: all ease 0.3s;
  .gnb {
    margin-top: 7rem;
    li {
      padding: 2rem 0;
      text-align: center;
      a {
        display: inline-block;
        font-size: 2rem;
        font-weight: 700;
        color: #333;
      }
    }
  }
  .btn-wrap {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    left: 0;
    bottom: 3rem;
    padding: 0 1.6rem;
    a {
      display: block;
      width: 9rem;
      height: 4rem;
      border: 0.1rem solid #333;
      font-size: 1.8rem;
      line-height: 4rem;
      font-weight: 600;
      color: #333;
      text-align: center;
    }
    .lang {
      display: flex;
      align-items: center;

      li {
        &:first-child {
          padding-right: 1.8rem;
          padding-left: 1.2rem;
          position: relative;

          &::after {
            content: '';
            display: block;
            width: 0.1rem;
            height: 1.4rem;
            background-color: #ddd;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            transition: all ease 0.3s;
          }
        }
        &:last-child {
          padding-left: 1.8rem;
          padding-right: 1.2rem;
        }

        button {
          font-size: 2rem;
          font-weight: 500;
          color: #aaa;
          transition: all ease 0.3s;

          &.active {
            font-weight: 700;
            color: #333;
          }
        }
      }
    }
  }
  &.on {
    opacity: 1;
    z-index: 99;
  }
`