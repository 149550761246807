import {defaultService} from "../service";

const NoticeAction = () => {

  const detail = (id: any, setDetail: any) => {
    console.log(id)
    defaultService.handleService({
      method: 'get',
      endPoint: `/v1/notice/detail/${id}`
    }).then(
      response => setDetail(response.data.payload),
      error => console.log(error)
    )
  }

  const view = (id: any) => {
    console.log(id)
    defaultService.handleService({
      method: 'get',
      endPoint: `/v1/notice/view/${id}`
    }).then(
      response => response.data.payload,
      error => console.log(error)
    )
  }

  const list = (params: any, setList: any) => {
    defaultService.handleService({
      method: 'get',
      endPoint: `/v1/notice?currentPage=${params.currentPage}&size=${params.size}`
    }).then(
      response => {
        console.log(response.data.payload)
        setList(response.data.payload)
      },
      error => console.log(error)
    )
  }

  return {
    view,
    detail,
    list
  }
}

export default NoticeAction
