import styled from "styled-components";

export const TableColWrap = styled.div`
  display: flex;
  flex-direction: column;
`
export const TableWrap = styled.table`
  width: 100%;
  th {
    border-top: 1px solid #AAAAAA;
    padding: 2.35rem
  }
  td {
    border-top: 1px solid #EEEEEE;
  }
`

export const TableThead = styled.thead`
  th {
    background-color: #FAFAFA;
  }
`

export const TableBody  = styled.tbody``
