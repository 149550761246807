import { useRecoilValue } from "recoil";
import { Body, ExtraFont, FlexBox } from "../../../../common/commonStyle";
import { FlexRowWrap } from "../../../../common/commonStyle";
import {
  CompanyMapBody,
  CompanyMapFlexBody,
  CompanyMapWrap,
  GoogleMapIframe,
  GoogleMapWrap,
  MapContact,
  MapTitle,
  SubTitle
} from "./styles";
import { langAtom } from "../../../../store/recoil/lang";

interface GoogleMapComponentInterface {
  src: string;
  name: string
  address: string
  tel?: string
  fax?: string
}

const Index = () => {

  const lang = useRecoilValue(langAtom)

  return <CompanyMapWrap>
    <CompanyMapBody>
      <ExtraFont size={'50px'}>Global Network</ExtraFont>
      <SubTitle>{lang ? 'BECUAI 찾아오시는 길' : 'Company Location'}</SubTitle>

      <GoogleMapComponent
        src={'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3162.560340937201!2d127.01177317722265!3d37.56542102418981!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca33e8d920e5b%3A0x3f81bdaa8f06ac96!2z7ISc7Jq47Yq567OE7IucIOykkeq1rCDth7Tqs4TroZwgMzg1!5e0!3m2!1sko!2skr!4v1711499168605!5m2!1sko!2skr'}
        name={lang ? 'Seoul 본사' : 'Seoul'}
        address={lang ? '서울특별시 중구 퇴계로 385 준타워 9층' : 'Jun Tower 9th FLOOR, 385, Toegye-ro, Jung-gu, Seoul, Republic of Korea'}
        tel={'02-3487-0215'}
        fax={'02-3487-0216'}
      />

      <GoogleMapComponent
        src={'https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d979.8743425551659!2d106.68136109999999!3d10.773166699999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTDCsDQ2JzIzLjQiTiAxMDbCsDQwJzUyLjkiRQ!5e0!3m2!1sko!2skr!4v1714037269691!5m2!1sko!2skr'}
        name={lang ? 'Vietnam 법인' : 'Vietnam'}
        address={'90 Đường số 2 Cư Xá Đô Thành, Phường 4, Quận 3, TPHCM'}
      />
    </CompanyMapBody>
  </CompanyMapWrap>
}

const GoogleMapComponent = ({src, name, address, tel, fax}:GoogleMapComponentInterface) => {
  const lang = useRecoilValue(langAtom)
  return <GoogleMapWrap>
    <GoogleMapIframe>
      <iframe
        src={src}
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"/>
    </GoogleMapIframe>
    <FlexRowWrap>
      <CompanyMapFlexBody>
        <FlexBox flex={3} responsiveFlexDisable={true}><MapTitle>{name}</MapTitle></FlexBox>
        <FlexBox flex={4} responsiveFlexDisable={true}>
          <MapContact>
            <span>{lang ? '주소' : 'Address'}</span> {address}
            {tel && <><br/> <span>{lang ? '전화' : 'TEL'}</span> {tel} </> }
            {fax && <> <br/> <span>{lang ? '팩스' : 'FAX'}</span> {fax} </> }
          </MapContact>
        </FlexBox>
      </CompanyMapFlexBody>
    </FlexRowWrap>
  </GoogleMapWrap>
}

export default Index
