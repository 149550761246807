import styled from "styled-components";

export const Sec02Wrap = styled.div`
  width: 100%;
  height: auto;
  section {
    width: 100%;
    height: auto;
    min-height: 74rem;
    display: flex;
    align-items: center;
    justify-content: center;
    &.sec02-01 {
      background: #FAFAFA url(/image/product/section02-white.png) no-repeat center / cover;
      color: #333;
      .text-box {
        span {
          margin-bottom: 11.6rem;
        }
      }
    }
    &.sec02-02 {
      background: #D80101 url(/image/product/section02-red.png) no-repeat center / cover;
      color: #fff;
      .text-box {
        span {
          margin-bottom: 5.8rem;
        }
      }
    }
    .container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .text-box {
      width: 49.417%;
      height: auto;
      span {
        display: block;
        font-size: 1.8rem;
        line-height: 2rem;
        font-weight: 700;
      }
      h2 {
        font-size: 4rem;
        line-height: 5.8rem;
        font-weight: 800;
      }
      p {
        font-size: 1.8rem;
        font-weight: 300;
        line-height: 3.2rem;
        margin-top: 1.6rem;
        &.pb {
          padding-bottom: 13.2rem;
        }
        .tablet {
          display: none;
        }
      }
      button {
        width: 8rem;
        height: 4.1rem;
        background-color: #fff;
        font-size: 1.4rem;
        font-weight: 500;
        color: #D80101;
        margin-top: 13.5rem;
      }
    }
    .img-box {
      width: 50.583%;
      height: auto;
      text-align: center;
    }
  }

  @media screen and (max-width: 1024px) {
    section {
      display: block;
      padding-top: 9rem;
      &.sec02-01 {
        background: #FAFAFA url('/image/product/new/sec02-tablet-bg1.png') no-repeat center / cover;
        min-height: 64rem;
        position: relative;
        .text-box {
          span {
            margin-bottom: 2.2rem;
          }
        }
        .img-box {
          position: absolute;
          bottom: 148px;
          left: 0;
          img {
            width: 25rem;
          }
        }
      }
      &.sec02-02 {
        background: #D80101 url('/image/product/new/sec02-tablet-bg2.png') no-repeat center / cover;
        min-height: 70rem;
        .text-box {
          span {
            margin-bottom: 2.2rem;
          }
        }
        .img-box {
          margin-top: 8.9rem;
          img {
            width: 25.4rem;
          }
        }
      }
      .container {
        display: block;
      }
      .text-box {
        width: 100%;
        text-align: center;
        h2 {
          font-size: 3.2rem;
          line-height: 4.6rem;
        }
        p {
          font-size: 1.6rem;
          line-height: 2.8rem;
          margin-top: 2.4rem;
          &.pb {
            padding-bottom: 0;
          }
          .pc {
            display: none;
          }
          .tablet {
            display: block;
          }
        }
        button {
          margin-top: 4.8rem;
        }
      }
      .img-box {
        width: 100%;
        text-align: center;
      }
    }
  }

  @media screen and (max-width: 500px) {
    section {
      padding-top: 10.6rem;
      &.sec02-01 {
        .img-box {
          /* margin-top: 11rem; */
          bottom: 153px;
          img {
            width: 21rem;
          }
        }
      }
      &.sec02-02 {
        .img-box {
          margin-top: 7rem;
          img {
            width: 21.6rem;
          }
        }
      }
      .text-box {
        h2 {
          font-size: 2.6rem;
          line-height: 3.8rem;
        }
        p {
          padding: 0 1.1rem;
          br {
            display: none;
          }
          .tablet {
            display: none;
          }
        }
      }
    }
  }
`

export const Sec03Wrap = styled.div`
  width: 100%;
  height: auto;
  padding-bottom: 8rem;
  section {
    padding: 16rem 0;
    .container {
      display: flex;
    }
    .text-box {
      width: 42.6rem;
      padding-right: 4.4rem;
      position: relative;
      span {
        display: block;
        font-size: 1.8rem;
        font-weight: 700;
        line-height: 2rem;
        margin-bottom: 2rem;
        &.blue {
          color: #215BFE;
        }
        &.red {
          color: #D80101;
        }
      }
      h2 {
        font-size: 4rem;
        font-weight: 800;
        line-height: 5.9rem;
        color: #333;
        word-break: keep-all;
        .tablet {
          display: none;
        }
      }
      p {
        font-size: 1.8rem;
        font-weight: 300;
        line-height: 3.2rem;
        margin-top: 1.2rem;
        word-break: keep-all;
        color: #333;
      }
      > button {
        width: 8rem;
        height: 4.1rem;
        background-color: #333;
        font-size: 1.4rem;
        font-weight: 500;
        color: #fff;
        margin-top: 7.2rem;
      }
    }
    .btn-wrap {
      display: flex;
      gap: 4rem;
      &.pc {
        position: absolute;
        left: 0;
        bottom: 0;
        &.b18 {
          bottom: -7rem;
        }
      }
      &.tablet {
        display: none;
      }
      button {
        width: 5.2rem;
        height: 5.2rem;
        border: 0.1rem solid #333;
        transition: all ease 0.3s;
        img {
          transition: all ease 0.3s;
        }
        &.disabled {
          opacity: 0.2;
        }
        &.product-slider-prev {
          img {
            transform: rotate(180deg);
          }
        }
        &:not(.disabled) {
          &:hover {
            background-color: #333;
            img {
              filter: invert(1);
            }
          }
        }
      }
    }
    .logo-box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(100% - 42.6rem);
      height: 60rem;
      border: 0.1rem solid #EEE;
      background-color: #FAFAFA;
      .swiper-slide {
        text-align: center;
      }
      img {
        width: 56.15rem;
      }
    }
  }

  @media screen and (max-width: 1232px) {
    section {
      .logo-box {
        height: 48.7013vw;
        img {
          width: 45.5357vw;
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    //padding-bottom: 6rem;
    section {
      &.sec03-01 {
        padding: 6rem 0 7.2rem;
      }
      &.sec03-02 {
        padding: 6rem 0;
      }
      .container {
        display: block;
      }
      .text-box {
        width: 100%;
        padding-right: 0;
        text-align: center;
        span {
          margin-bottom: 2.2rem;
        }
        h2 {
          font-size: 3.2rem;
          line-height: 4.6rem;
          .tablet {
            display: block;
          }
        }
        p {
          max-width: 41.2rem;
          min-height: 8.4rem;
          font-size: 1.6rem;
          line-height: 2.8rem;
          margin: 2.4rem auto 0;
        }
        > button {
          margin-top: 3rem;
        }
      }
      .logo-box {
        width: 42rem;
        height: 29.8rem;
        margin: 4rem auto 0;
        img {
          width: 31.8rem;
          &.w274 {
            width: 27.4rem;
          }
        }
      }
      .btn-wrap {
        &.pc {
          display: none;
        }
        &.tablet {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 3.2rem;
          margin-top: 2.8rem;
          button {
            width: 4.4rem;
            height: 4.4rem;
            img {
              width: 1.4rem;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 500px) {
    section {
      .text-box {
        h2 {
          font-size: 2.6rem;
          line-height: 3.8rem;
        }
      }
      .logo-box {
        width: 100%;
        height: 24.3rem;
        img {
          width: 26rem;
          &.w274 {
            width: 22.4rem;
          }
        }
      }
    }
  }
`
