import CardList from "../../../module/cardList";
import {useEffect, useRef, useState} from "react";
import NewsAction from "../../../store/action/news";

const Index = () => {

  const {list} = NewsAction()

  const [board, setBoard] = useState({ count:0 ,row: [] })
  const [size, setSize] = useState(20)
  const target = useRef<any>(null)
  const [loading, setLoading] = useState(false)

  const pageUp = () => {
    setLoading(true)
    setSize(prev => prev + 20)
    setLoading(false)
  }

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) return
      if (loading) return
      pageUp()
    })
  })

  useEffect(() => {
    observer.observe(target.current)
  }, [])

  useEffect(() => {
    list({currentPage: 0, size: size}, setBoard)
  }, [size])

  return <>
    <CardList data={board.row} />
    <div ref={target}></div>
  </>
}

export default Index
