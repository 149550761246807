import React, {useEffect, useRef, useState} from 'react'
import {DataWrap} from './styles'
import DataCount from '../../components/dataCount'
import Partners from '../../components/partners'
import { useRecoilValue } from 'recoil'
import { langAtom } from '../../store/recoil/lang'

const Data = () => {

  // 다국어
  const lang = useRecoilValue(langAtom)

  // Ref
  const service = useRef(null)
  const sales = useRef<any>(null)

  // 원형 애니메이션 이벤트
  const cirCleWrap = useRef<any>(null)
  const [isOn, setIsOn] = useState(false)
  useEffect(() => {
    const handleScroll = () => {

      if (cirCleWrap.current) {
        const {top} = cirCleWrap.current.getBoundingClientRect()
        if (top < window.innerHeight - 300) {
          setIsOn(true)
        } else {
          setIsOn(false)
        }
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  // 타이틀 변경
  useEffect(() => {
    const titleElement = document.getElementsByTagName('title')[0];
    if (titleElement) {
      titleElement.innerHTML = 'Data | 비큐AI';
    }

    return () => {
      titleElement.innerHTML = '비큐AI'
    }
  }, []);


  return (
    <DataWrap>
      <div
        ref={service}
        className='service-wrap'
      >
        <section className='sec01'>
          <div className='container'>
            <div className="box-wrap">
              {
                lang ? (
                  <div className='text-box'>
                    <h2>
                      국내 최대 규모의<br/>
                      ‘Media Big Data &<br/>
                      Real-time Data PipeLine’<br/>
                      (One-Stop Solution)
                    </h2>
                    <p>
                      <strong>20년간 고도화</strong>된 실시간 뉴스를 위한 최고의 인공지능 <br/>
                      오토 라벨링 기술 “AI Root”를 통해 RDPLINE이 AI 학습과 실시간 <br/>
                      정보 서비스에 <strong>최적화된 양질의 뉴스 빅데이터를 제공</strong>합니다.
                    </p>
                  </div>
                ) : (
                  <div className='text-box'>
                    <h2>
                      The largest <br/>
                      Media Big-data & <br/>
                      Real-time Data PipeLine
                    </h2>
                    <p>
                      ''RDPLINE' provides the media big-data optimized for AI <br/>
                      learning and real-time data service by 'AI Root' which is the <br/>
                      best auto-labelling technology highly advanced for <br/>
                      20 years.
                    </p>
                  </div>
                )
              }
              <div className='img-box'>
                <img src='/image/data/mcp-circle-img01.svg' alt="MCP 이미지" className='circle'/>
                <img src="/image/data/mcp-circle-logos01.svg" alt="mcp-circle-logos01" data-aos="zoom-in" data-aos-offset={0} />
                <span>MCP</span>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section
        ref={cirCleWrap}
        className='sec02'
      >
        <div className='container'>
          <div className='box-wrap reverse'>
            <div
              className={isOn ? 'circle-map img-box on' : 'circle-map img-box'}
            >
              <img src='/image/data/mcp-circle-img01.svg' alt="MCP 이미지" className='circle-img big-circle'/>
              <img src='/image/data/mcp-circle-img02.png' alt="MCP 이미지" className='circle-img small-circle'/>
              <div className='logo-wrap'>
                <img src='/image/data/logo01.svg' alt="logo01" className='logo01'/>
                <img src='/image/data/logo02.svg' alt="logo02" className='logo02'/>
                <img src='/image/data/logo03.svg' alt="logo03" className='logo03'/>
                <img src='/image/data/logo04.svg' alt="logo04" className='logo04'/>
                <img src='/image/data/logo05.svg' alt="logo05" className='logo05'/>
                <img src='/image/data/logo06.svg' alt="logo06" className='logo06'/>
                <img src='/image/data/logo07.svg' alt="logo07" className='logo07'/>
                <img src='/image/data/logo08.svg' alt="logo08" className='logo08'/>
              </div>
              <div className="show-logos">
                <img src="/image/data/mcp-circle-logos02.svg" alt="mcp-circle-logos" />
              </div>
              <span>MCP</span>
            </div>
            {
              lang ? (
                <div className='text-box'>
                  <h2>
                    20년 이상 지속된<br/>
                    다수의 주요 매체사,<br/>
                    다수의 레퍼런스<br/>
                  </h2>
                  <p>
                    BECUAI는 <strong>국내 3,000여개 언론사들과 컨텐츠 공급 계약이 <br/>
                    체결</strong>되어 있으며, 이를 통해 고객사가 수많은 미디어 매체와의 개별 <br/>
                    계약에 대한 부담감과 컨텐츠 정제에 대한 부담 없이 <strong>약 6억 건의 <br/>
                    보유된 누적 기사</strong>와 <strong>매일 생성되는 15만 건의 기사</strong>를 한 번에 쉽고 <br/>
                    빠르게 공급합니다.
                  </p>
                </div>
              ) : (
                <div className='text-box'>
                  <h2>
                    Over 20 years of <br/>
                    partnerships with major <br/>
                    media outlets and <br/>
                    numerous references
                  </h2>
                  <p>
                    ''BECUAI' has the right to supplying contents with more <br/>
                    than <strong>3,000 media channels in Korea,</strong> which allows <br/>
                    customers to easily and quickly supply <strong>600 million news <br/>
                    articles</strong> from media big data and <strong>150 thousand articles</strong> <br/>
                    generated daily at once, without the burden of individual <br/>
                    contracts with numerous media outlets and the burden of <br/>
                    content refinement.
                  </p>
                </div>
              )
            }
          </div>
        </div>
      </section>

      <DataCount/>

      <section className='sec04' ref={sales}>
        <div className='container'>
          <div className='title-wrap'>
            <span>{lang ? '모두가 선택한 서비스' : <> Trusted by major media<br className={'pc'}/> & companies </>}</span>
            <h2>2 Sided Market</h2>
          </div>
          <div className='sales-wrap'>
            <div className="box">
              <div className="circle-wrap">
                <div className="info">
                  <div className='count'>3,000</div>
                  <span>Media Partnerships</span>
                </div>
              </div>
              {
                lang ? (
                  <p>
                    <strong>약 3,000여개 미디어</strong>로부터 데이터를 수집하는 <br/>
                    국내 최대 규모의 미디어 빅 데이터 플랫폼
                  </p>
                ) : (
                  <p>
                    The largest big-data platform center through <br/>
                    the data from 3,000 media channels
                  </p>
                )
              }
            </div>
            <div className="box">
              <div className="circle-wrap">
                <div className="info">
                  <div className='count'>1,500</div>
                  <span>Clients</span>
                </div>
              </div>
              {
                lang ? (
                  <p>
                    실시간으로 수집 및 가공된 데이터를 <strong>정부기관과 민간기업 등 <br/>
                    약 1,500여개의 회사</strong>에 미디어 융합 서비스 플랫폼으로 제공
                  </p>
                ) : (
                  <p>
                    Produced and refined data is served to government agencies <br/>
                    and companies in real-time by a media platform
                  </p>
                )
              }
            </div>
          </div>
        </div>
      </section>
      <Partners/>
    </DataWrap>
  )
}

export default Data
