import styled from "styled-components";

export const InfoAreaWrap = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

export const Title = styled.div`
  flex: 3;
  min-width: 40rem;
  @media screen and (max-width: 1200px) {
    & {
      padding: 0 1.6rem 4rem;
    }
  }
  @media screen and (max-width: 500px) {
    padding: 0 1.6rem 4rem;
    min-width: unset;
  }
`
export const Typo = styled.div`
  position: relative;
  flex: 4;
  min-width: 40rem;
  @media screen and (max-width: 1200px) {
  & {
    padding: 0 1.6rem;
  }
  @media screen and (max-width: 500px) {
    min-width: unset;
  }
}
`

export const CompanyInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4.7rem;
  gap: 1rem;
  padding-bottom: 31.15rem;

  @media screen and (max-width: 1200px) {
    & {
      margin-top: 4rem;
      padding-bottom: 10rem;
    }
  }

  @media screen and (max-width: 500px) {
    gap: 4rem;
    padding-bottom: 6rem;
  }
`

export const CompanyInfoText = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media screen and (max-width: 500px) {
    display: block;
    width: 100%;
  }
`
export const CompanyInfoTitle = styled.div`
  width: 12rem;
  > p {
    font-weight: 400;
    line-height: 3.5rem;
  }
  &.en {
    width: 19.1rem;
  }
  @media screen and (max-width: 500px) {
    > p {
      line-height: 1.9rem;
    } 
  }
`
export const CompanyInfoContent = styled.div`
  flex: 1;
  max-width: 379px;
  > p {
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    line-height: 3.5rem;
  }

  @media screen and (max-width: 500px) {
    margin-top: 1rem;
    > p {
      font-size: 1.6rem;
      line-height: 2.6rem;
      font-weight: 700;
    }
  }
`

export const SubTypo = styled.div`
  flex: 4;
  @media screen and (max-width: 1200px) {
  & {
    padding: 0 1.6rem;
    float: left;
    p {
      display: inline;
    }
  }
}
`

export const CompanyInfoImage = styled.img`
  position: absolute;
  bottom: -20%;
  width: 100vw;
  max-width: 120rem;
  @media screen and (min-width: 801px) {
    & {
      left: 50%;
      transform: translateX(-50%);
    }
  }
  @media screen and (max-width: 1200px) {
    & {
      max-width: 70rem;
      min-width: 70rem;

    }
  }
  @media screen and (max-width: 800px) {
    & {
      position: absolute;
      right: 0;
      transform: none;
      min-width: 70rem;
    }
  }

  @media screen and (max-width: 500px) {
    position: unset;
    width: 100%;
    max-width: unset;
    min-width: unset;
  }
`

export const DownloadButton = styled.button`
  margin-top: 4rem;
  padding: 8px 22px 8px 22px;
  border: 1px solid #CCCCCC;
`
