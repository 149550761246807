import {defaultService} from "../service";

const NewsAction = () => {

  const list = (params: any, setBoard: any) => {
    defaultService.handleService({
      method: 'get',
      endPoint: `/v1/news?currentPage=${params.currentPage}&size=${params.size}`
    }).then(
      response => setBoard(response.data.payload),
      error => console.log(error)
    )
  }

  const view = (id: any) => {
    defaultService.handleService({
      method: 'get',
      endPoint: `/v1/news/view/${id}`
    })
  }

  return {
    list,
    view
  }
}

export default NewsAction
