import {
  ContentBody,
  ContentBodyDescription,
  ContentBodyImage, ContentButtonWrap,
  ContentDate, ContentFileDownload, ContentListButton, ContentPrevNextWrap,
  ContentTitle,
  ContentTitleWrap,
  ContentWrap,
  Download,
  TempCenterText
} from './styles'
import {useEffect, useState} from "react";
import {Body} from "../../../../../common/commonStyle";
import {useLocation} from 'react-router-dom';
import moment from "moment";
import { langAtom } from '../../../../../store/recoil/lang';
import { useRecoilValue } from 'recoil';

interface ContentInterface {
  content: any
  handlePrev: any
  handleNext: any
  handleMenu: any
}

const Content = (props: ContentInterface) => {

  const lang = useRecoilValue(langAtom)

  const location = useLocation()

  const [movePage, setMovePage] = useState({
    prev: null,
    next: null
  })

  const [file, setFile] = useState({
    fileName: null,
    url: null
  })

  const handleDownloadFile = (e: any) => {
    if (file.url && file.fileName) {
      e.stopPropagation();
      const downloadUrl = process.env.REACT_APP_BASE_URL + props.content.filePath;

      fetch(downloadUrl)
        .then(response => response.blob())
        .then(blob => {
          const blobUrl = URL.createObjectURL(blob);

          const link = document.createElement('a');
          link.href = blobUrl;
          link.setAttribute('download', '');
          link.style.display = 'none';

          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);

          URL.revokeObjectURL(blobUrl);
        })
        .catch(error => {
          console.error('이미지를 다운로드하는 중 오류가 발생했습니다:', error);
        });
    }
  }



  useEffect(() => {
    if (props.content.filePath && props.content.fileName) {
      setFile({
        fileName: props.content.fileName,
        url: process.env.REACT_APP_BASE_URL + props.content.filePath,
      })
    }
  }, [props.content.filePath, props.content.fileName])

  useEffect(() => {
    setMovePage({
      prev: props.content.previous_id,
      next: props.content.next_id,
    })
  }, [props.content.previous_id, props.content.next_id])

  return <ContentWrap>

    <ContentTitleWrap>
      <ContentTitle>
        {props.content.title}
      </ContentTitle>
      <ContentDate>
        {moment(props.content.createdAt).format('YYYY. MM. DD')}
      </ContentDate>
    </ContentTitleWrap>

    <ContentBody>
      {props.content.image && (
        <ContentBodyImage src={props.content.image} alt={'thumbnail'}/>
      )}

      <ContentBodyDescription dangerouslySetInnerHTML={{__html: props.content.content}}/>

      {/*<TempCenterText>
        {moment(props.content.createdAt).format('YYYY년 M월 D일')}<br/>
        비플라이소프트 주식회사
        {props.content.tempCenterText}
      </TempCenterText>*/}

      {
        file.url && file.fileName && <Download>
          <ContentFileDownload onClick={handleDownloadFile}>
            {file.fileName}
            <img src={'/image/irpr/download.svg'}/>
          </ContentFileDownload>
        </Download>
      }
    </ContentBody>

    <ContentButtonWrap>

      <ContentPrevNextWrap>
        <div
          className={!movePage.prev ? 'disabled left' : 'left'}
          onClick={props.handlePrev}
        >
          <img src={'/image/data/arrow-left-black.svg'} alt={'arrow-left'}/>
          <a>{lang ? '이전글' : 'Previous post'}</a>
        </div>

        <div className={'splitLine'}/>

        <div
          className={!movePage.next ? 'disabled right' : 'right'}
          onClick={props.handleNext}
        >
          <a>{lang ? '다음글' : 'Next post'}</a>
          <img src={'/image/data/arrow-right-black.svg'} alt={'arrow-right'}/>
        </div>
      </ContentPrevNextWrap>

      <ContentListButton onClick={props.handleMenu}>
        {lang ? '목록으로' : 'View List'}
      </ContentListButton>
    </ContentButtonWrap>

  </ContentWrap>
}

export default Content
