import styled from "styled-components";

export const HistoryWrap = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #D80101;
  padding-bottom: 12rem;
`

export const HistoryTopWrap = styled.div`
  width: 120rem;
`

export const HistoryBody = styled.div`
  padding-top: 12rem;
  position: relative;
  min-width: 375px;
  width: 1200px;
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: 1200px) {
    flex-direction: column;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }

  @media screen and (max-width: 500px) {
    width: 100%;
    min-width: unset;
    display: block;
  }
`

export const HistoryContentText = styled.p`
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 300;
  line-height: 34px;
  text-align: left;

  @media screen and (max-width: 1200px) {
    padding-top: 4rem;
  }

  @media screen and (max-width: 500px) {
    font-size: 1.6rem;
    line-height: 2.8rem;
    br {
      display: none;
    }
  }
`

export const HistoryLineWrap = styled.div`
  margin-top: 7rem;
  height: .1rem;
  width: 100vw;
  position: relative;
  @media screen and (max-width: 900px) {
    margin-top: 0;
    position: absolute;
    background-color: antiquewhite;

  }
`
export const HistoryLineLeftFade = styled.div`
  z-index: 6;
  position: absolute;
  top: 0;
  left: 0;
  width: 12.8rem;
  height: 30rem;
  background-image: linear-gradient(90deg, #D80101 20%, transparent);
  @media screen and (max-width: 1200px) {
    background-image: none;
  }
`
export const HistoryLine = styled.div`
  z-index: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  @media screen and (max-width: 900px) {
    width: 190rem;
  }

  @media screen and (max-width: 500px) {
    width: 170rem;
  }
`
export const HistoryLineRightFade = styled.div`
  z-index: 8;
  position: absolute;
  top: 0;
  right: 0;
  width: 12.8rem;
  height: 30rem;
  background-image: linear-gradient(-90deg, #D80101 10%, transparent 100%);
  @media screen and (max-width: 1200px) {
    background-image: none;
  }
`

export const HistoryYearListWrap = styled.div`
  @media screen and (max-width: 900px) {
    margin-top: 10rem;
    position: relative;
    width: 100vw;
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 1rem 1.6rem 0;

    /* 스크롤바 숨기기 */
    &::-webkit-scrollbar {
      display: none;
    }
  }
`


export const HistoryYearList = styled.div<{ left: string }>`
  position: relative;
  max-width: 120rem;
  min-width: 120rem;
  display: flex;
  gap: 17.8rem;
  z-index: 5;
  top: -1rem;
  left: ${props => props.left};
  transition: .5s;
  @media screen and (max-width: 1200px) {
    margin-left: -11rem;
  }
  @media screen and (max-width: 900px) {
    margin-left: 0;
    left: 0;
    gap: 5rem;
  }
`

export const HistoryCircle = styled.div`
  width: 1.9rem;
  height: 1.9rem;
  background-color: #D80101;
  border: .4rem solid #FFFFFF;
  border-radius: 4rem;
  margin-bottom: 2.55rem;
`
export const HistoryYearWrap = styled.div`
  
`

export const HistoryYearText = styled.div`
  font-size: 34px;
  font-weight: 800;
  line-height: 28px;
  text-align: left;
  color: #FFFFFF;
  white-space: nowrap;

  @media screen and (max-width: 500px) {
    font-size: 2.8rem;
  }
`

export const HistoryUl = styled.ul`
  padding: 0;
  margin-top: 2.1rem;

  @media screen and (max-width: 500px) {
    margin-top: 1.2rem;
  }
`
export const HistoryLi = styled.li`
  color: #ffffff;
  line-height: 3rem;
  position: relative;
  font-weight: 500;
  font-size: 1.6rem;
  text-wrap: none;
  white-space: nowrap;

  @media screen and (max-width: 500px) {
    font-size: 1.4rem;
  }
`

export const ButtonWrap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4rem;

  @media screen and (max-width: 900px) {
    display: none;
  }
`

export const PageBtnWrap = styled.div<{
  disabled: boolean;
  reverse: boolean;
}>`
  position: relative;
  opacity: ${props => props.disabled ? '.3' : '1'};
  ${props => props.reverse && 'transform: scaleX(-1);'}
  width: 5.2rem;
  height: 5.2rem;
  border: .1rem solid white;
  transition: .5s;
  > svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    transition: .5s;
    fill: white;
  }

  &:hover {
    ${props => !props.disabled && 'background-color: white;'}
    >svg {
      ${props => !props.disabled && 'fill: #D80101;'}
    }
  }

`

export const PageBtn = styled.img<{
  disabled: boolean;
  reverse: boolean;
  hover?: boolean
}>`
  opacity: ${props => props.disabled ? '.5' : '1'};
  transition: .5s;

  ${props => props.reverse && 'transform: scaleX(-1);'}
  ${props => props.hover && 'cursor: pointer;'}
  &:hover {
    background-color: white;
  }
`
