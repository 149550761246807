import {DetailWrap} from "./styles";
import {Body, FlexRowWrap, WrapPadding} from "../../../../../common/commonStyle";
import Content from "./components/Content";
import { useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import NoticeAction from "../../../../../store/action/notice";



const Index = () => {

  const {pageNum} = useParams();
  const navigate = useNavigate();

  const {detail, view} = NoticeAction()
  const [content, setContent] = useState({
    previous_id: null,
    next_id: null,
  })

  const handleChangePage = (i: any) => {
    view(i)
    navigate(`/en/invest/notice/${i}`)
  }

  const handleMenu = () => navigate(`/en/invest/notice`)


  // 타이틀 변경
  useEffect(() => {
    const titleElement = document.getElementsByTagName('title')[0]
    if (titleElement) {
      titleElement.innerHTML = 'IR | 비큐AI'
    }

    return () => {
      titleElement.innerHTML = '비큐AI'
    }
  }, [])

  useEffect(() => {
    detail(pageNum, setContent)
  }, [pageNum])

  return <DetailWrap>
    <WrapPadding/>
    <FlexRowWrap>
      <Body>
        <Content
          content={content}
          handlePrev={() => content.previous_id ? handleChangePage(content.previous_id) : null}
          handleNext={() => content.next_id ? handleChangePage(content.next_id) : null}
          handleMenu={handleMenu}
        />
      </Body>
    </FlexRowWrap>
  </DetailWrap>
}

export default Index
