import {Body, FlexRowWrap} from "../../../common/commonStyle";
import Table from "../../../module/table";
import {NoticeWrap, CreateTd, TitleTd, Tr, NoticeUl, NoticeLi} from "./styles.";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import Pagination from "../../../module/pagination";
import NoticeAction from "../../../store/action/notice";
import { useRecoilValue } from "recoil";
import { langAtom } from "../../../store/recoil/lang";

const Index = () => {

  // 다국어
  const lang = useRecoilValue(langAtom)

  const pageSize = 20
  const [notice, setNotice] = useState({
    count: 0,
    row: []
  })

  const {list, view} = NoticeAction()

  const navigate = useNavigate();

  const handleViewDetail = (id: number) => {
    view(id)
    navigate(`/invest/notice/${id}`)
  }

  const [windowWidth, setWindowWidth] = useState<boolean>(false);

  const [currentPage, setCurrentPage] = useState<number>(0)

  useEffect(() => {
    const handleResize = () => setWindowWidth(Number(window.innerWidth) <= 785);
    handleResize()

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // 빈 배열은 이펙트가 처음 한 번만 실행됨을 의미합니다.

  useEffect(() => {
    list({
      currentPage: currentPage,
      size: pageSize
    }, setNotice)
  }, [currentPage])

  return <NoticeWrap>
    {
      notice.count > 0 && !windowWidth ?
        <Table
          colSize={['920', '280']}
          pageSize={pageSize}
          totalCount={notice.count}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          data={notice.row}
          header={lang ? ['제목', '등록일'] : ['Title', 'Date']}
          render={(row: any, key: number) => (
            <Tr key={key} onClick={() => handleViewDetail(row.id)}>
              <TitleTd>
                <p>{row.title}</p>
              </TitleTd>
              <CreateTd>{moment(row.createdAt).format('YYYY.MM.DD')}</CreateTd>
            </Tr>
          )}
        /> :
        <>
          <NoticeUl>
            {
              notice.row.map((it: any, key) => (
                <NoticeLi key={key} onClick={() => handleViewDetail(it.id)}>
                  <p className={'notice-li-title'}>{it.title}</p>
                  <p className={'notice-li-date'}>{moment(it.createdAt).format('YYYY.MM.DD')}</p>
                </NoticeLi>
              ))
            }
          </NoticeUl>
          <Pagination
            totalRecord={notice.count}
            pageSize={pageSize}
            pageNumToShow={10}
            currentPage={currentPage}
            onChange={(page) => setCurrentPage(page)}
          />
        </>
    }
  </NoticeWrap>
}

export default Index
