import React from 'react'
import { ProcessingWrap } from './styles'
import CoreTechnology from './CoreTechnology'
import { useRecoilValue } from 'recoil'
import { langAtom } from '../../store/recoil/lang'

const Processing = () => {

  // 다국어
  const lang = useRecoilValue(langAtom)

  return (
    <ProcessingWrap>
      <section className='sec02'>
        <div className="container">
          {
            lang ? (
              <div className="title-wrap">
                <h2>
                  국내 AI·데이터 기업은<br className='mobile'/> AI 개발 시간의 80%,<br/>
                  비용의 75%가<br className='mobile'/> 데이터 확보에 필요
                </h2>
                <p>한국지능정보사회진흥원 (NIA),  IT&FUTURE<br/> STRATEGY (2020.12)</p>
              </div>
            ) : (
              <div className="title-wrap">
                <h2>
                  Within domestic AI and data enterprises, <br/>
                  80% of the time and 75% of the expenses <br/>
                  are allocated to data acquisition during AI development.
                </h2>
                <p>NIA(National Information Society Agency),  IT&FUTURE STRATEGY (2020.12)</p>
              </div>
            )
          }
        </div>
      </section>
      <section className='sec03'>
        <div className="container">
          <div className="title-wrap">
            <h2>WIGO<br/> Pre-Processing</h2>
            {
              lang ? (
                <p>
                  Low Value의 Data를 가공하여 정확하고 신뢰할 수<br className='mo'/> 있는 작업을 통해 <br className='pc'/>
                  High Value의 데이터를 생산<br className='mo'/> 제공하는 기술적 기반을 제공합니다. 
                </p>
              ) : (
                <p>
                  We provide the technical foundation that enables the <br className='pc'/>
                  transformation of low-value data into high-value data <br className='pc'/>
                  through accurate and trustworthy tasks.
                </p>
              )
            }
          </div>
          <div className='processing-box'>
            <img src='/image/tech/wigo-processing-img.svg' alt='WIGO Pre-Processing' />
          </div>
          <div className="video-box">
            <video muted autoPlay loop playsInline>
              <source src="/video/wigo-processing.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      </section>
      <section className='sec04'>
        <div className='container'>
          <div className='title-wrap'>
            <span>CORE TECHNOLOGY</span>
            <h2>{lang ? '핵심기술' : 'Core Technology'}</h2>
            {
              lang ? (
                <p>
                  Pre-Processing의 핵심 기술들은 ‘데이터 정제 단계’에서부터, ‘작업자 투입 전 자동 준비 작업’, <br/>
                  ‘데이터 가공 및 라벨링 작업’, ‘가공 완료된 정보에 대한 검수’ 등 전체 프로세스에 적용되어 데이터 품질을 <br/>
                  높여 작업자의 효율은 높이고, 인건비는 절감시켜 프로젝트 수행시간을 단축시키는 효과를 제공합니다.
                </p>
              ) : (
                <p>
                  The core technologies of pre-processing are applied throughout the entire process, from the <br/>
                  data cleansing stage to tasks such as automated preparation before worker input, data <br/>
                  processing and labeling, and inspection of processed information. This enhances data <br/>
                  quality, and the improved quality of data enables increased worker efficiency, reduced labor <br/>
                  costs, and shortened project completion time.
                </p>
              )
            }
          </div>
          <ul>
            <li>
              <div className='icon'>
                <img src='/image/tech/vision-icon.svg' alt='vision icon' />
              </div>
              <dl>
                <dt>Vision</dt>
                {
                  lang ? (
                    <dd>인공지능 시각 기술</dd>
                  ) : (
                    <dd>AI technology that <br/>comprehends visuals</dd>
                  )
                }
              </dl>
            </li>
            <li>
              <div className='icon'>
                <img src='/image/tech/ocr-icon.svg' alt='ocr icon' />
              </div>
              <dl>
                <dt>OCR</dt>
                {
                  lang ? (
                    <dd>광학문자 인식 기술</dd>
                  ) : (
                    <dd>Optical character <br/>recognition</dd>
                  )
                }
              </dl>
            </li>
            <li>
              <div className='icon'>
                <img src='/image/tech/nlp-icon.svg' alt='nlp icon' />
              </div>
              <dl>
                <dt>NLP</dt>
                {
                  lang ? (
                    <dd>자연어 처리 기술</dd> 
                  ) : (
                    <dd>Natural language <br/>processing</dd>
                  )
                }
              </dl>
            </li>
          </ul>
          <CoreTechnology />
        </div>
      </section>
      <section className='sec05'>
        <div className='container'>
          <div className='title-wrap'>
            <span>AIROOT FOR NEWS </span>
            <h2>{lang ? '뉴스데이터 Pre-Processing 과정' : 'News data Pre-Processing process'}</h2>
          </div>
          <div className='processing-box'>
            <img src='/image/tech/news-processing-img.svg' alt='뉴스데이터 Pre-Processing 과정' />
          </div>
          <div className="video-box">
            <video muted autoPlay loop playsInline>
              <source src="/video/news-processing.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      </section>
    </ProcessingWrap>
  )
}

export default Processing