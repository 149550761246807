import React from 'react'
import { Sec02Wrap } from './styles'
import { useRecoilValue } from 'recoil'
import { langAtom } from '../../../store/recoil/lang'


const Sec02 = () => {

  // 다국어
  const lang = useRecoilValue(langAtom)

  return (
    <Sec02Wrap>
      <section className='sec02-01'>
        <div className="container">
          {
            lang ? (
              <div className="text-box">
                <span>Pre data processing SW Platform</span>
                <h2>AI 기반 데이터 <br/>라벨링 & 전처리 플랫폼</h2>
                <p className='pb'>
                  다양한 형태의 데이터(TEXT, IMAGE, VIDEO)를 <br className='pc'/>
                  수요자의 요청에 <br className='tablet'/>맞게 커스터마이징하여 최적화된 <br className='pc'/>
                  데이터 라벨링&정제 가공 서비스<br className='tablet'/>를 제공합니다.
                </p>
              </div>
            ) : (
              <div className="text-box">
                <span>Pre data processing SW Platform</span>
                <h2>
                  Data labeling & <br/>
                  pre-processing <br/>
                  AI platform 
                </h2>
                <p className='pb'>
                  Customized data labeling and refinement <br/>services for all types of data (text, image, <br/>video)
                </p>
              </div>
            )
          }
          <div className="img-box">
            <img src="/image/product/new/section02-logo01.svg" alt="AIROOT" />
          </div>
        </div>
      </section>
      <section className='sec02-02'>
        <div className="container">
          {
            lang ? (
              <div className="text-box">
                <span>MCP</span>
                <h2>
                  AI 학습과 서비스를 위한 <br/>
                  Real time <br/>
                  data pipeline 플랫폼
                </h2>
                <p>
                  생성형 AI 학습에 필요한 데이터를 수요자가 필요한 형태로 <br className='pc'/>
                  가공하<br className='tablet'/>여 대량 공급 또는 실시간 API로 제공합니다.
                </p>
                <button 
                  type='button'
                  onClick={() => window.open('https://www.rdpline.com/', '_blank')}
                >
                  더 보기
                </button>
              </div>
            ) : (
              <div className="text-box">
                <span>MCP</span>
                <h2>
                  Real-time <br/>
                  Data pipeline for <br/>
                  AI training
                </h2>
                <p>
                  Real-time data supply pipelines for 'Training <br/>Generative AI Models' and various services.
                </p>
                <button 
                  type='button'
                  onClick={() => window.open('https://www.rdpline.com/', '_blank')}
                >
                  More
                </button>
              </div>
            )
          }
          <div className="img-box">
            <img src="/image/product/new/section02-logo02.svg" alt="AIROOT" />
          </div>
        </div>
      </section>
    </Sec02Wrap>
  )
}

export default Sec02