import React from 'react'
import styled from 'styled-components'

const NotFoundError = styled.div`
  width: 100%;
  height: 100vh;
  background: #fcfcfc;
  display: flex;
  align-items: center;
  justify-content: center;
  .content {
    width: 100%;
    height: auto;
    h1 {
      font-family: sans-serif;
      font-weight: 900;
      font-size: 8rem;
      color: #666;
      text-align: center;
    }
  }
  @media screen and (max-width: 500px) {
    .content {
      h1 {
        font-size: 3rem;
      }
    }
  }
`

const Error = () => {
  return(
    <NotFoundError>
      <div className="content">
        <h1>404</h1>
      </div>
    </NotFoundError>
  )
}

export default Error
