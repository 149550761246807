import React from 'react'
import { PartnersWrap } from './styles'
import { partnersList01, partnersList02 } from './dummy'

const Partners = () => {
  return (
    <PartnersWrap>
      <div className='container-fluid'>
        <div className="partners-list">
          <ul className='slide original'>
            {
              partnersList01.map((item, index) => {
                return (
                  <li key={index}>
                    <img src={item.logo} alt="로고 이미지" />
                  </li>
                )
              })
            }
            {
              partnersList01.map((item, index) => {
                return (
                  <li key={index}>
                    <img src={item.logo} alt="로고 이미지" />
                  </li>
                )
              })
            }
          </ul>
          <ul className='slide clone'>
            {
              partnersList01.map((item, index) => {
                return (
                  <li key={index}>
                    <img src={item.logo} alt="로고 이미지" />
                  </li>
                )
              })
            }
            {
              partnersList01.map((item, index) => {
                return (
                  <li key={index}>
                    <img src={item.logo} alt="로고 이미지" />
                  </li>
                )
              })
            }
          </ul>
        </div>
        <div className="partners-list reverse">
          <ul className='slide original'>
            {
              partnersList02.map((item, index) => {
                return (
                  <li key={index}>
                    <img src={item.logo} alt="로고 이미지" />
                  </li>
                )
              })
            }
            {
              partnersList02.map((item, index) => {
                return (
                  <li key={index}>
                    <img src={item.logo} alt="로고 이미지" />
                  </li>
                )
              })
            }
          </ul>
          <ul className='slide clone'>
            {
              partnersList02.map((item, index) => {
                return (
                  <li key={index}>
                    <img src={item.logo} alt="로고 이미지" />
                  </li>
                )
              })
            }
            {
              partnersList02.map((item, index) => {
                return (
                  <li key={index}>
                    <img src={item.logo} alt="로고 이미지" />
                  </li>
                )
              })
            }
          </ul>
        </div>
      </div>
    </PartnersWrap>
  )
}

export default Partners