import styled from "styled-components";

/**
 * CardList
 */
export const CardListWrap = styled.div`
  @media screen and (min-width: 786px) {
    width: 120rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(36rem, 36rem));
    gap: 6rem;
    grid-row-gap: 7rem;
    justify-content: center; /* 수평 중앙 정렬 */
    align-items: center; /* 수직 중앙 정렬 */
  }
  @media screen and (max-width: 785px) {
    
  }
`;


/**
 * Card
 */
export const CardBody = styled.div`
  @media screen and (min-width: 786px) {
    height: 100%;
    cursor: pointer;
  }
  
  @media screen and (max-width: 785px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 1.6rem;
    padding: 2.45rem 0;
    border-bottom: 1px solid #EEEEEE;
  }
`

export const CardImageWrap = styled.div`
  
  justify-content: center;
  align-items: center;
  border-radius: .6rem;
  overflow: hidden;
  border: 1px solid #EEEEEE;
  height: 21.2rem;
  
  @media screen and (min-width: 786px) {
    display: flex;
    flex: 1;
  }

  @media screen and (max-width: 785px) {
    width: 12rem;
    height: 7rem;
    margin: 0 1.2rem 0 0;
  }
  
`

export const CardImage = styled.img`
  width: 100%;
  height: 100%;
`

export const CardDescWrap = styled.div`
  @media screen and (max-width: 785px) {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
`

export const CardTitle = styled.p`
  height: 5.2rem;
  margin: 1rem 0 .4rem;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  text-align: left;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 2줄까지만 표시 */
  -webkit-box-orient: vertical;
  word-break: break-all;
  @media screen and (max-width: 785px) {
    height: auto;
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

`

export const CardContent = styled.p`
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: #666666;
  padding: 0 0 .4rem 0;
`

export const CardDate = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: #aaaaaa;
  @media screen and (max-width: 785px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.71px;
    text-align: left;
    margin-top: 0.4rem;
  }
`
