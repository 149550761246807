import styled from "styled-components";

export const WrapPadding = styled.div`
  padding-top: 8rem;

  @media screen and (max-width: 1024px) {
    padding-top: 7rem;
  }
`


/**
 * BasicBox
 */
export const FlexRowWrap = styled.div<{ color?: string }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  ${props => props.color && `background-color:${props.color}`}
`

export const Body = styled.div<{
  pdtop?: string,
  pdbottom?: string,
  flexend?: boolean,
  width?: string,
  flexdirection?: string
}>`
  //background-color: aliceblue;
  position: relative;
  min-width: 375px;
  width: ${props => props.width ? props.width :'1200px'};
  //min-width: 37.5rem ;
  flex-direction: ${props => props.flexdirection ? props.flexdirection :'row'};
  display: flex;
  flex-wrap: wrap;
  ${props => props.pdtop && `padding-top:${props.pdtop};`}
  ${props => props.pdbottom && `padding-bottom:${props.pdbottom};`}
  ${props => props.flexend && `justify-content: flex-end;`}

  @media screen and (max-width: 500px) {
    width: 100%;
    min-width: unset;
    display: block;
  }
`

export const FlexBox = styled.div<{
  flex: number,
  responsiveFlexDisable?: boolean,
}>`
  position: relative;
  flex: ${props => props.flex};
  @media screen and (max-width: 800px) {
    & {
      ${props => props.responsiveFlexDisable && 'flex: 0'}
    }
  }
`
export const BasicTableWrap = styled.div`
  @media screen and (max-width: 1200px) {
    padding: 0 1.6rem;
    width: 100%;
    overflow-x: scroll;
    /* 스크롤바 숨기기 */
    &::-webkit-scrollbar {
      display: none;
    }
  }
`
export const BasicTable = styled.table`
  width: 120rem;
  background-color: #FFFFFF;
  text-align: center;

  th, td {
    padding: 2.25rem 0;
    border-bottom: 1px solid #EEEEEE;
    border-top: 1px solid #AAAAAA;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    &:not(&:first-child) {
      border-left: 1px solid #EEE;
    }
  }
  
  th {
    background-color: #FAFAFA;
    font-weight: 700;
    br {
      display: none;
    }
  }

  td.right {
    text-align: right;
    padding: 0 3.2rem 0 0
  }

  td.bold {
    font-weight: 700;
  }
  td.red {
    color: #D80101;
  }

  @media screen and (max-width: 1024px) {
    width: 120rem;
    &.w56 {
      width: 120rem;
    }
    th, td {
      font-size: 1.5rem;
      padding: 1.6rem 0;
    }
    th {
      br {
        display: block;
      }
    }
  }

  @media screen and (max-width: 768px) {
    width: 80rem;
    &.w56 {
      width: 80rem;
    }
    th, td {
      font-size: 1.4rem;
      line-height: 18px;
      padding: 1.4rem 0;
    }
    th {
      br {
        display: block;
      }
    }
  }

  @media screen and (max-width: 500px) {
    width: 70rem;
    &.w56 {
      width: 56rem;
    }
    th, td {
      font-size: 1.4rem;
      padding: 1.4rem 0;
    }
    th {
      br {
        display: block;
      }
    }
  }

`

/**
 * TEXT
 */

export const ExtraFont = styled.p<{ size: string; color?: string }>`
  font-weight: 800;
  font-size: ${props => props.size};
  line-height: 59.67px;
  ${props => props.color && `color: ${props.color};`}
`
export const SemiFont = styled.p<{ size: string; color?: string }>`
  font-weight: 500;
  font-size: ${props => props.size};
  line-height: 30px;
  ${props => props.color && `color: ${props.color};`}
`

export const Extra50 = styled.p<{ color?: string }>`
  font-weight: 800;
  font-size: 50px;
  line-height: 59.67px;
  ${props => props.color && `color: ${props.color};`}

  @media screen and (max-width: 500px) {
    font-size: 3rem;
    line-height: 3.58rem;
  }
`

export const Bold18 = styled.p<{ color?: string }>`
  font-size: 18px;
  font-weight: 700;
  line-height: 21.48px;
  ${props => props.color && `color: ${props.color};`}
  text-align: left;
`

export const SemiBold24 = styled.p<{ color?: string }>`
  font-size: 24px;
  font-weight: 500;
  line-height: 45px;
  ${props => props.color && `color: ${props.color};`}
  text-align: left;

  @media screen and (max-width: 500px) {
    font-size: 1.8rem;
    line-height: 3.2rem;
  }
`

export const SemiBold18 = styled.p<{ color?: string }>`
  font-size: 18px;
  font-weight: 500;
  line-height: 34px;
  text-align: left;
  ${props => props.color && `color: ${props.color};`}
`

export const Normal18 = styled.p<{ color?: string }>`
  font-size: 18px;
  font-weight: 400;
  line-height: 34px;
  ${props => props.color && `color: ${props.color};`}
  text-align: left;

  @media screen and (max-width: 500px) {
    font-size: 1.6rem;
    line-height: 2.8rem;
    br {
      display: none;
    }
  }
`
