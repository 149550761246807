import {
  ModalBody,
  Container,
  ModalWrap,
  ModalButtonWrap,
  ModelButtonBox,
  Title,
  Content,
  ModalContent,
  DetailButton, ModalImage
} from "./styles";
import {useEffect, useState} from "react";
import PopupAction from "../../store/action/popup";
import {useRecoilState, useRecoilValue} from "recoil";
import {popupAtom} from "../../store/recoil/popup";
import { langAtom } from "../../store/recoil/lang";


const Index = ({item}: any) => {

  const lang = useRecoilValue(langAtom)

  const [popup, setPopup] = useRecoilState(popupAtom)

  const [content, setContent] = useState('')

  const [isChecked, setIsChecked] = useState(false)
  const [isView, setIsView] = useState(false)

  const {view} = PopupAction()

  const handleCloseModal = () => {
    if(isChecked) setPopup((prev: any) => [...prev, item.id])
    setIsView(false)
  }

  const handleOpenLink = () => {
    switch(item.linkType) {
      case 1:
        window.open(item.link, '_blank'); // 새 창에서 링크 열기
        break;
      case 0:
        window.location.href = item.link; // 현재 탭에서 링크 열기
        break;
      default:
        console.error('올바르지 않은 링크 타입입니다.');
    }

    view(item.id)
  }

  useEffect(() => {
    if (item.id) {
      setIsView(popup.indexOf(item.id) < 0)
    }
  }, [item])

  useEffect(() => {
    if(item.content) {
      let temp = item.content
      if(temp.length > 200) temp = temp.slice(0,200) + '...'

      setContent(temp)
    }
  }, [item.content])

  return isView ? (
    <Container> <ModalWrap>
    {
      !item.thumbnail ? <ModalBody>
        <Title>{item.title}</Title>
        <ModalContent>
          <Content dangerouslySetInnerHTML={{__html: content}} />
        </ModalContent>
        {
          item.link && <DetailButton onClick={handleOpenLink}>
            {lang ? '자세히 보러 가기' : 'More'}
          </DetailButton>
        }
      </ModalBody> :
        <ModalImage
          hover={!!item.link}
          src={process.env.REACT_APP_BASE_URL + item.thumbnail} onClick={() =>{
          item.link && window.open(item.link)
        }}/>
    }
    <ModalButtonWrap>
      <ModelButtonBox onClick={() => setIsChecked(!isChecked)} >
        <div>
          {
            isChecked ?
              <img src={'/img/checked_active.svg'} alt={'active'}/> :
              <img src={'/img/checked_disabled.svg'} alt={'disabled'}/>
          }
        </div>
        {lang ? '다시 보지 않기' : <span>Don't show<br/>this message again</span>}
      </ModelButtonBox>
      <ModelButtonBox onClick={handleCloseModal}>
        {lang ? '닫기' : 'Close'}
      </ModelButtonBox>
    </ModalButtonWrap>
  </ModalWrap>
  </Container>
  ) : null
}

export default Index
