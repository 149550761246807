import {Body, Bold18, Extra50, FlexBox, FlexRowWrap, Normal18, SemiBold24} from "../../../../../common/commonStyle";
import React, { useEffect, useState } from "react";
import {
  CompanyInfoContent,
  CompanyInfoImage,
  CompanyInfoText,
  CompanyInfoTitle,
  CompanyInfoWrap, DownloadButton,
  InfoAreaWrap,
  SubTypo,
  Title,
  Typo
} from "./styles";
import { useRecoilValue } from "recoil";
import { langAtom } from "../../../../../store/recoil/lang";

const Index = () => {

  // 다국어
  const lang = useRecoilValue(langAtom)

  const [paddingTop, setPaddingTop] = useState(0)
  const [paddingTop2, setPaddingTop2] = useState(0)
  const [paddingBottom, setPaddingBottom] = useState(0)
  const [backgroundColor, setBackgroundColor] = useState('#fafafa')
  const [imgSrc, setImgSrc] = useState('/image/company/company-info.png')

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 500) {
        setPaddingTop(11)
        setPaddingTop2(4)
        setPaddingBottom(12)
        setBackgroundColor('#fff')
        setImgSrc('/image/mobile/company/company-info.png')
      } else {
        setPaddingTop(20)
        setPaddingTop2(25.2)
        setPaddingBottom(12)
        setBackgroundColor('#fafafa')
        setImgSrc('/image/company/company-info.png')
      }
    }

    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return <InfoAreaWrap>
    <FlexRowWrap color={backgroundColor}>
      <Body pdtop={`${paddingTop}rem`}>
        <Title>
          <Extra50>
            ABOUT<br/>
            OUR COMPANY
          </Extra50>
        </Title>
        <Typo>
          {
            lang ? (
              <Normal18>
                우리는 개별 데이터 셋의 품질을 높은 수준으로 고도화 해 시대를 앞서 준비하고, 데이터 중심 <br/>
                인공지능 도입을 통해 경쟁 패러다임의 변화를 시도하는 기업들을 효과적이고 효율적으로 지원하는 <br/>
                기술과 데이터의 인터페이스를 연구합니다.
              </Normal18>
            ) : (
              <Normal18>
                We are conducting research on technologies and data interfaces to empower <br/>
                companies to lead the way in embracing change. This includes improving the quality <br/>
                of individual datasets and enabling companies to drive a shift in the competitive <br/>
                paradigm through the adoption of data-centric artificial intelligence.
              </Normal18>
            )
          }
          <CompanyInfoWrap>

            <CompanyInfoText>
              <CompanyInfoTitle className={lang ? '' : 'en'}>
                <Normal18>
                  {
                    lang ? '회사명' : 'Corporate name'
                  }
                </Normal18>
              </CompanyInfoTitle>
              <CompanyInfoContent>
                <Bold18>
                  {
                    lang ? '비큐AI' : 'BECUAI INC.'
                  }
                </Bold18>
              </CompanyInfoContent>
            </CompanyInfoText>

            <CompanyInfoText>
              <CompanyInfoTitle className={lang ? '' : 'en'}>
                <Normal18>
                  {lang ? '설립일' : 'Establishment date'}
                </Normal18>
              </CompanyInfoTitle>
              <CompanyInfoContent>
                <Bold18>1998.08.25</Bold18>
              </CompanyInfoContent>
            </CompanyInfoText>

            <CompanyInfoText>
              <CompanyInfoTitle className={lang ? '' : 'en'}>
                <Normal18>{lang ? '대표이사' : 'CEO'}</Normal18>
              </CompanyInfoTitle>
              <CompanyInfoContent>
                <Bold18>{lang ? '임경환' : 'Kyoung-Hwan Im'}</Bold18>
              </CompanyInfoContent>
            </CompanyInfoText>

            <CompanyInfoText>
              <CompanyInfoTitle className={lang ? '' : 'en'}>
                <Normal18>{lang? '사업분야' : 'Business Area'}</Normal18>
              </CompanyInfoTitle>
              <CompanyInfoContent>
                {
                  lang ? (
                    <Bold18>
                      소프트웨어 개발 및 제조/판매<br/>
                      데이터베이스 및 온라인정보제공업<br/>
                      인터넷 뉴스 서비스 사업 외
                    </Bold18>
                  ) : (
                    <Bold18>
                      SW development and service <br/>
                      Database and provision of online information <br/>
                      Internet news service business
                    </Bold18>
                  )
                }
              </CompanyInfoContent>
            </CompanyInfoText>

            <CompanyInfoText>
              <CompanyInfoTitle className={lang ? '' : 'en'}>
                <Normal18>{lang ? '본사 소재지' : 'Address'}</Normal18>
              </CompanyInfoTitle>
              <CompanyInfoContent>
                {lang ? (
                  <Bold18>
                    서울특별시 중구 퇴계로 385 준타워(흥인동) 5, 6, 9층
                  </Bold18>
                ) : (
                  <Bold18>
                    Jun Tower 9th FLOOR, 385, Toegye-ro, <br/>Jung-gu, Seoul, Republic of Korea
                  </Bold18>
                )}
              </CompanyInfoContent>
            </CompanyInfoText>

            <CompanyInfoText>
              <CompanyInfoTitle className={lang ? '' : 'en'}>
                <Normal18>{lang ? '전화' : 'TEL'}</Normal18>
              </CompanyInfoTitle>
              <CompanyInfoContent>
                <Bold18>02.3487.0215</Bold18>
              </CompanyInfoContent>
            </CompanyInfoText>

            <CompanyInfoText>
              <CompanyInfoTitle className={lang ? '' : 'en'}>
                <Normal18>{lang ? '팩스' : 'FAX'}</Normal18>
              </CompanyInfoTitle>
              <CompanyInfoContent>
                <Bold18>02.3487.0216</Bold18>
              </CompanyInfoContent>
            </CompanyInfoText>


          </CompanyInfoWrap>


        </Typo>
        <CompanyInfoImage src={imgSrc} alt={'company-info-image'}/>
      </Body>
    </FlexRowWrap>


    <FlexRowWrap color={'#F3F3F3'}>
      <Body pdtop={`${paddingTop2}rem`} pdbottom={`${paddingBottom}rem`}>
        <FlexBox flex={3} responsiveFlexDisable={true}/>
          {
            lang ? (
              <SubTypo>
                <SemiBold24>BECUAI는 Data Pre-Processing 단계부터 </SemiBold24>
                <SemiBold24>End-Point인 뉴스 데이터 공급과 모니터링 서비스까지 </SemiBold24>
                <SemiBold24>다양한 솔루션 및 서비스를 제공하고 있으며 </SemiBold24>
                <SemiBold24><b>글로벌 MCP (Multi Contents Provider)</b>로 확장해 나가고 있습니다</SemiBold24>
              </SubTypo>
            ) : (
              <SubTypo>
                <SemiBold24>BECUAI offers a range of solutions and services, </SemiBold24>
                <SemiBold24>from data pre-processing to end-point news data supply </SemiBold24>
                <SemiBold24>and monitoring services, as it expands its business </SemiBold24>
                <SemiBold24>as a <b>Global Multi-Contents Provider.</b></SemiBold24>
              </SubTypo>
            )
          }
      </Body>
    </FlexRowWrap>
  </InfoAreaWrap>
}

export default Index
