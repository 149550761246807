import {TableBody, TableColWrap, TableThead, TableWrap} from "./styles"
import {useEffect, useState} from "react";
import Pagination from "../pagination";

interface TableInterface {
  colSize?: string[]
  pageSize: number
  totalCount: number
  currentPage?: number
  setCurrentPage?: any
  data: any[]
  header: string[]
  render: any
}

const Index = (props: TableInterface) => {

  const [value, setValue] = useState<any[]>(props.data)

  const [currentPage, setCurrentPage] = useState<number>(props.currentPage || 0)
  const [pageSize, setPageSize] = useState<number>(props.pageSize)

  useEffect(() => {
    if(props.setCurrentPage) {
      props.setCurrentPage(currentPage)
    }
  }, [currentPage])

  useEffect(() => {
    props.currentPage && setCurrentPage(props.currentPage)
  }, [props.currentPage])

  useEffect(() => {
    setValue(props.data)
  }, [props.data])

  return <TableColWrap>
    <TableWrap>
      {
        props.colSize && <colgroup>
          {
            props.colSize.map((col, key) => (
              <col key={key} width={col} />
            ))
          }
        </colgroup>
      }
      <TableThead>
        <tr>{props.header.map((header, key) => <th key={key} >{header}</th> )}</tr>
      </TableThead>
      <TableBody>
        {
          value.map((item, key) => props.render(item, key))
        }
      </TableBody>
    </TableWrap>

    <Pagination
      totalRecord={props.totalCount}
      pageSize={pageSize}
      pageNumToShow={10}
      currentPage={currentPage}
      onChange={(page) => setCurrentPage(page)}
    />

  </TableColWrap>
}

export default Index
