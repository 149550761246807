import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { HeaderWrap } from "./styles";
import MobileGnb from "../enMobile/Gnb";
import { useRecoilState } from "recoil";
import { langAtom } from "../../store/recoil/lang";

const Header = () => {
  // 다국어
  const [lang, setLang] = useRecoilState(langAtom);

  const [mobileGnbActive, setMobileGnbAcitve] = useState(false);

  // location
  const location = useLocation();

  // gnbList
  const gnbList = [
    {
      title: "Data",
      link: "/en/data",
      dep2List: [
        {
          title: "Service",
          link: "/en/data",
        },
        {
          title: "Sales",
          link: "/en/data",
        },
      ],
    },
    {
      title: "Tech",
      link: "/en/tech",
      dep2List: [
        {
          title: "Pre-Processing",
          link: "/en/tech#processing",
        },
        {
          title: "AI+Data",
          link: "/en/tech#data",
        },
      ],
    },
    {
      title: "Products",
      link: "/en/products",
      dep2List: [
        {
          title: "AI Root",
          link: "/en/products",
        },
        {
          title: "RDP Line",
          link: "/en/products",
        },
        {
          title: "AI Surfer",
          link: "/en/products",
        },
      ],
    },
    {
      title: "IR·PR",
      link: "/en/invest",
      dep2List: [
        {
          title: "재무정보",
          link: "/en/invest",
        },
        {
          title: "보도자료",
          link: "/en/invest",
        },
        {
          title: "공지사항",
          link: "/en/invest",
        },
        {
          title: "공시정보",
          link: "/en/invest",
        },
      ],
    },
    {
      title: "Company",
      link: "/en/company",
    },
  ];

  // header hover
  const [headerHover, setHeaderHover] = useState(false);

  // Header Active
  const [isHeaderActive, setHeaderActive] = useState(false);

  const handleToTop = () => {
    if (window.location.pathname === "/") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 0) {
        setHeaderActive(true);
      } else {
        setHeaderActive(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/invest/notice");
  };

  const [backStatus, setBackStatus] = useState(false);

  useEffect(() => {
    setMobileGnbAcitve(false);

    function handleResize() {
      if (window.innerWidth <= 500) {
        location.pathname.includes("/invest/notice/")
          ? setBackStatus(true)
          : setBackStatus(false);
      } else {
        setBackStatus(false);
      }
    }

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [location]);

  const kr = location.pathname.split("/en");
  const handleLang = (b: boolean) => {
    setLang(b);
    const pathname = location.pathname
      .replace(/\/en\//g, "/")
      .replace("/en", "");
    navigate(
      b
        ? kr[1] === ""
          ? "/"
          : kr[1]
        : pathname === "/"
        ? "/en"
        : `/en${pathname}`
    );
  };

  return (
    <>
      <HeaderWrap
        className={
          headerHover
            ? isHeaderActive
              ? "active hover"
              : location.pathname === "/en" || location.pathname === "/en/tech"
              ? "white hover"
              : "hover"
            : isHeaderActive
            ? "active"
            : location.pathname === "/en" || location.pathname === "/en/tech"
            ? "white"
            : ""
        }
      >
        <div className="container">
          <h1
            id="logo"
            className={mobileGnbActive ? "hidden" : backStatus ? "dn" : ""}
          >
            <Link to={"/en"} className="ir" onClick={handleToTop}>
              BECUAI
            </Link>
          </h1>
          <button
            type="button"
            onClick={handleBack}
            className={backStatus ? "back-btn" : "screen-hidden"}
          >
            <img src="/image/mobile/back-icon.png" alt="뒤로가기" />
          </button>
          <div id="gnb">
            <ul className="dep1">
              {gnbList.map((item, index) => {
                return (
                  <li key={index}>
                    <NavLink to={item.link} className={"gnbLinkButton"}>
                      {item.title}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="btn-wrap">
            <a
              href={process.env.REACT_APP_CAREER_URL}
              target="_blank"
              rel="noreferrer"
            >
              Careers
            </a>
            <ul className="lang">
              <li>
                <button
                  type="button"
                  className={lang ? "active" : ""}
                  onClick={() => handleLang(true)}
                >
                  KR
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className={lang ? "" : "active"}
                  onClick={() => handleLang(false)}
                >
                  EN
                </button>
              </li>
            </ul>
          </div>
          <button
            type="button"
            className={mobileGnbActive ? "mobile-menu on" : "mobile-menu"}
            onClick={() => setMobileGnbAcitve(!mobileGnbActive)}
          >
            <span></span>
            <span></span>
          </button>
        </div>
      </HeaderWrap>
      <MobileGnb active={mobileGnbActive} />
    </>
  );
};

export default Header;
