import styled from "styled-components";

export const IRPRWrap = styled.div``;

export const Title = styled.p`
  font-size: 48px;
  font-weight: 800;
  text-align: center;
  line-height: 19.3rem;
  @media screen and (max-width: 785px) {
    font-size: 26px;
    font-weight: 800;
    line-height: 3.103rem;
    text-align: center;
    padding: 2.4rem 0 2.8rem;
  }
`;

export const InvestNewsBody = styled.div`
  position: relative;
  width: 1200px;
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 8.6rem;
  @media screen and (max-width: 1200px) {
    width: 100vw;
    flex-direction: column;
  }
  @media screen and (max-width: 785px) {
    padding-bottom: 3.2rem;
    &.p10 {
      padding-bottom: 1rem;
    }
  }
`;

export const TabUl = styled.ul`
  position: relative;
  left: 0;
  display: flex;
  margin: 0 auto;
  justify-content: center;
`;

export const TabLi = styled.li`
  position: relative;
  display: inline-block;
  padding: 0 4rem;
  .df {
    display: flex;
    align-items: center;
  }
  span {
    br {
      display: none;
    }
  }
  @media screen and (min-width: 726px) {
    &:not(:first-child) {
      &:after {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 1.4rem;
        width: 0.1rem;
        background-color: #ddd;
        content: ".";
        color: transparent;
      }
    }
  }
  @media screen and (max-width: 725px) {
    padding: 0 2rem;
    span {
      br {
        display: block;
      }
    }
  }
  > div {
    font-size: 20px;
    line-height: 25px;
    @media screen and (max-width: 725px) {
      font-size: 14px;
      font-weight: 500;
      line-height: 25px;
      text-align: center;
    }
    text-align: center;
    cursor: pointer;
    color: #cccccc;
    transition: 0.5s;
    font-weight: 500;
    &.active {
      color: #d80101;
      font-weight: 700;
    }
    > svg {
      margin-left: 0.67rem;
    }
  }
`;
