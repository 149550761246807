import {useEffect, useState} from "react";
import {PageBtn, PageBtnWrap} from "./styles";
import Svg from "../../../../module/svg";

interface PageButtonInterface {
  disabled: boolean
  reverse?: boolean
  handleClick: () => void
}

const PageButton = ({disabled, reverse, handleClick}:PageButtonInterface) => {

  const [state, setState] = useState(false)

  useEffect(() => {
    setState(disabled)
  }, [disabled])

  return <>
    <PageBtnWrap disabled={disabled} reverse={reverse || false} onClick={handleClick}>
      <Svg
        icon={'arrow'}
        width={22}
      />
    </PageBtnWrap>
    </>
/*  return <PageBtn
    disabled={state}
    reverse={reverse || false}
    hover={true}
    src={'/image/page-button.svg'}
    alt={'page-btn'}
    onClick={handleClick}
  />*/
}

export default PageButton
