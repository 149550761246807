import styled from "styled-components";

export const TechWrap = styled.div`
  .visual {
    width: 100%;
    height: 90rem;
    position: relative;
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background: linear-gradient(135deg, rgba(14,77,255,1) 0%, rgba(216,1,1,1) 100%);
      background-size: 200% 200%;
      animation: bg 4s alternate both infinite;
      position: absolute;
      left: 0;
      top: 0;
    }
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background: url('/image/tech/visual-img.png') no-repeat center / cover;
      position: absolute;
      left: 0;
      top: 0;
      mix-blend-mode: multiply;
    }
    .container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .title-wrap {
      position: relative;
      padding-top: 10rem;
      z-index: 9;
      h2 {
        font-size: 6.6rem;
        line-height: 1.33;
        font-weight: 800;
        color: #fff;
        text-align: center;
        .mobile {
          display: none;
        }
      }
      span {
        display: block;
        font-size: 1.8rem;
        line-height: 1.78;
        font-weight: 400;
        color: #fff;
        text-align: center;
        width: 54.4rem;
        margin: 3rem auto 0;
        .mobile {
          display: none;
        }
        
        @media screen and (max-width: 768px) {
          margin: 3rem auto 0;
          width: 100%;
        }
      }
    }
  }
  @keyframes bg {
    0% {background-position: 0% 0%;}
    100% {background-position: 100% 100%;}
  }
  .tab-btn-wrap {
    width: 100%;
    height: auto;
    margin-bottom: 6rem;
    .btn-wrap {
      width: 100%;
      height: 7rem;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 9;
      background-color: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur(5px);
      button {
        display: block;
        font-size: 2.4rem;
        font-weight: 800;
        color: #999;
        &.active {
          color: #333;
        }
        &:first-child {
          padding-right: 2.4rem;
          margin-right: 2.4rem;
          position: relative;
          &::after {
            content: '';
            display: block;
            width: 0.1rem;
            height: 1.7rem;
            background-color: #ccc;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
      &.fixed {
        position: fixed;
        left: 0;
        top: 8rem;
        z-index: 99;
      }
    }
  }
  section {
    width: 100%;
    height: auto;
    padding: 16.5rem 0;
  }
  .sec01 {
    padding: 18rem 0 25rem;
    h2 {
      font-size: 6.6rem;
      line-height: 1.3;
      font-weight: 800;
      text-align: center;
      background-image: linear-gradient(135deg, rgba(14,77,255,1) 0%, rgba(216,1,1,1) 100%);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      margin-bottom: 11rem;
      opacity: 0.12;
      transition: all ease 0.3s;
      position: relative;
      bottom: -100px;
      &.on {
        opacity: 1;
        bottom: 0;
      }
    }
    .box {
      text-align: center;
      opacity: 0.12;
      transition: all ease 0.5s;
      position: relative;
      bottom: -100px;
      &:not(:last-of-type) {
        margin-bottom: 9rem;
      }
      img {
        margin-bottom: 1rem;
      }
      h3 {
        font-size: 4rem;
        line-height: 1.45;
        font-weight: 800;
        background-image: linear-gradient(135deg, rgba(14,77,255,1) 0%, rgba(216,1,1,1) 100%);
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
      }
      p {
        font-size: 2.6rem;
        line-height: 1.46;
        font-weight: 700;
        background-image: linear-gradient(135deg, rgba(14,77,255,1) 0%, rgba(216,1,1,1) 100%);
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
      }
      &.on {
        opacity: 1;
        bottom: 0;
      }
    }
  }
  .title-wrap {
    width: 100%;
    height: auto;
    text-align: center;
    margin-bottom: 8rem;
    span {
      display: block;
      font-size: 1.8rem;
      font-weight: 700;
      color: #7F53FF;
      margin-bottom: 2rem;
    }
    h2 {
      font-size: 5rem;
      line-height: 1.3;
      font-weight: 800;
      color: #333;
    }
    p {
      font-size: 1.8rem;
      line-height: 1.78;
      font-weight: 300;
      color: #333;
      margin-top: 2rem;
    }
  }
  .processing-box {
    width: 100%;
    height: auto;
    background-color: #fafafa;
    border: 0.1rem solid #eee;
    border-radius: 0.2rem;
    padding: 7rem 8.8rem;
    img {
      display: block;
      width: 100%;
    }
  }

  @media screen and (max-width: 1024px) {
    .title-wrap {
      h2 {
        font-size: 3.8rem;
      }
      p {
        font-size: 1.6rem;
      }
    }
  }
  

  @media screen and (max-width: 768px) {
    section {
      padding: 9rem 0 12rem;
    }
    .visual {
      .title-wrap {
        h2 {
          font-size: 5.5rem;
        }
        span {
          font-size: 1.6rem;
        }
      }
    }
    .sec01 {
      h2 {
        font-size: 5.5rem;
        bottom: -50px;
      }
      .box {
        bottom: -50px;
        h3 {
          font-size: 3rem;
        }
        p {
          font-size: 2rem;
        }
      }
    }
  }

  @media screen and (max-width: 570px) {
    /* .title-wrap {
      margin-bottom: 6rem;
      h2 {
        word-break: keep-all;
        font-size: 2.6rem;
        line-height: 3.8rem;
      }
      p {
        word-break: keep-all; 
      }
    } */

    .visual {
      .title-wrap {
        h2 {
          .mobile {
            display: block;
          }
        }
      }
    }
  }

  @media screen and (max-width: 540px) {
    .sec01 {
      padding: 10.4rem 0;
      h2 {
        font-size: 2.6rem;
        margin-bottom: 4.8rem;
      }
      .box {
        &:not(:last-of-type) {
          margin-bottom: 3.6rem;
        }
        .icon1 {
          width: 4.2rem;
        }
        .icon2, .icon3 {
          width: 2.8rem;
        }
        h3 {
          font-size: 1.8rem;
        }
        p {
          font-size: 1.3rem;
        }
      }
    }
  }

  @media screen and (max-width: 510px) {
    .title-wrap {
      margin-bottom: 6rem;
      h2 {
        word-break: keep-all;
        font-size: 2.6rem;
        line-height: 3.8rem;
      }
      p {
        word-break: keep-all; 
      }
    }
  }

  @media screen and (max-width: 500px) {
    .visual {
      height: 59.4rem;
      &::after {
        background: url('/image/mobile/tech/visual-img.svg') no-repeat center / cover;
      }
      .title-wrap {
        h2 {
          font-size: 3.4rem;
          line-height: 5.2rem;
          word-break: keep-all;
        }
        span {
          word-break: keep-all;
          .pc {
            display: none;
          }
          .mobile {
            display: block;
          }
        }
      }
    }
  }
`

export const ProcessingWrap = styled.div`
  .sec02 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 54rem;
    padding: 0;
    background-color: #333;
    .container {
      height: auto;
    }
    .title-wrap {
      margin-bottom: 0;
      h2 {
        line-height: 1.36;
        color: #fff;
        .mobile {
          display: none;
        }
      }
      p {
        color: #aaa;
        margin-top: 4rem;
        br {
          display: none;
        }
      }
    }
  }
  .sec03 {
    .title-wrap {
      h2 {
        br {
          display: none;
        }
      }
      p {
        .mo {
          display: none;
        }
      }
    }
    video {
      display: none;
    }
  }
  .sec04 {
    padding: 16.5rem 0 20rem;
    background-color: #333;
    .title-wrap {
      margin-bottom: 6rem;
      h2 {
        color: #fff;
      }
      p {
        color: #fff;
        margin-top: 3rem;
      }
    }
    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5.2rem;
      li {
        display: flex;
        align-items: center;
        gap: 2rem;
        .icon {
          width: 6.6rem;
          height: 6.6rem;
          background-color: rgba(255, 255, 255, 0.12);
          line-height: 6.6rem;
          text-align: center;
        }
        dl {
          dt {
            font-size: 2.2rem;
            font-weight: 500;
            color: #fff;
            margin-bottom: 1rem;
          }
          dd {
            font-size: 1.8rem;
            line-height: 1.22;
            font-weight: 400;
            color: #aaa;
            .mo {
              display: none;
            }
          }
        }
      }
    }
  }
  .sec05 {
    video {
      display: none;
    }
  }

  @media screen and (max-width: 779px) {
    .sec04 {
      padding: 10rem 0 20rem;
      ul {
        gap: 0.6rem;
        li {
          display: block;
          max-width: 6.6rem;
          &.en {
            min-width: 14rem;
            text-align: center;
            .icon {
              margin: 0 auto;
            }
          }
          dl {
            text-align: center;
            margin-top: 2rem;
            dt {
              font-size: 2rem;
            }
            dd {
              font-size: 1.6rem;
              line-height: 1.5;
              word-break: keep-all;
              .mo {
                display: block;
              }
              .pc {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .processing-box {
      display: none;
    }
    .sec03 {
      .video-box {
        position: relative;
        &::after {
          content: '';
          display: block;
          width: 7.2rem;
          height: calc(100% - 0.2rem);
          background: linear-gradient(-90deg, #FAFAFA 0%, rgba(250, 250, 250, 0) 100%);
          position: absolute;
          right: 0.1rem;
          top: 0.1rem;
        }
        video {
          display: block;
          border: 0.1rem solid #eee;
        }
      }
    }
    .sec05 {
      .video-box {
        position: relative;
        &::after {
          content: '';
          display: block;
          width: 7.2rem;
          height: calc(100% - 0.2rem);
          background: linear-gradient(-90deg, #FAFAFA 0%, rgba(250, 250, 250, 0) 100%);
          position: absolute;
          right: 0.1rem;
          top: 0.1rem;
        }
        video {
          display: block;
          border: 0.1rem solid #eee;
        }
      }
    }
  }

  @media screen and (max-width: 678px) {
    .sec04 {
      .title-wrap {
        p {
          word-break: keep-all;
          br {
            display: none;
          }
        }
      }
    }
  }

  @media screen and (max-width: 570px) {
    .sec02 {
      padding: 9rem 0;
      height: auto;
      .title-wrap {
        p {
          margin-top: 3rem;
        }
      }
    }
  }

  @media screen and (max-width: 500px) {
    .sec02 {
      .title-wrap {
        h2 {
          line-height: 3.8rem;
          .mobile {
            display: block;
          }
        }
        p {
          br {
            display: block;
          }
        }
      }
    }
    .sec03 {
      padding: 9rem 0 12rem;
      .title-wrap {
        h2 {
          br {
            display: block;
          }
        }
        p {
          .pc {
            display: none;
          }
          .mo {
            display: block;
          }
        }
      }
    }
    .sec04 {
      padding: 10rem 0 12rem;
      .title-wrap {
        margin-bottom: 5rem;
      }
      ul {
        li {
          &.en {
            min-width: 10rem;
          }
          .icon {
            width: 4.6rem;
            height: 4.6rem;
            line-height: 4.6rem;
            img {
              width: 2.4rem;
            }
          }
          dl {
            margin-top: 1.2rem;
            dt {
              font-size: 1.4rem;
              margin-bottom: 0.5rem;
            }
            dd {
              font-size: 1.2rem;
            }
          }
        }
      }
    }
    .sec05 {
      padding: 10rem 0 12rem;
    }
  }
`

export const AiDataWrap = styled.div`
  .sec02 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 54rem;
    padding: 0;
    background-color: #333;
    .container {
      display: flex;
      height: auto; 
    }
    .about {
      width: 28.5rem;
      height: auto;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .mobile {
        display: none;
      }
      dl {
        dt {
          font-size: 3.3rem;
          line-height: 1.15;
          font-weight: 600;
          color: #fff;
        }
        dd {
          font-size: 1.8rem;
          font-weight: 400;
          color: #aaa;
          margin-top: 1.5rem;
        }
      }
    }
    .info {
      width: calc(100% - 28.5rem);
      height: auto;
      padding-left: 13.6rem;
      p {
        font-size: 1.8rem;
        line-height: 1.78;
        font-weight: 300;
        color: #fff;
        word-break: keep-all;
      }
      span {
        display: block;
        font-size: 1.8rem;
        color: #aaa;
        margin-top: 3rem;
        .mobile {
          display: none;
        }
      }
    }
  }
  .sec03 {
    
    .flex-box {
      display: flex;
      gap: 4rem;
      .img-box {
        flex: 1;
        height: auto;
        background-color: #fafafa;
        border: 0.1rem solid #eee;
        border-radius: 0.2rem;
        padding: 7.5rem 8rem;
        img {
          display: block;
          width: 100%;
        }
      }
      .text-box {
        width: 30rem;
        padding-top: 1.2rem;
        h3 {
          font-size: 2.8rem;
          font-weight: 700;
          color: #333;
        }
        p {
          font-size: 1.6rem;
          line-height: 1.75;
          font-weight: 300;
          color: #333;
          margin-top: 1.2rem;
        }
        ul {
          margin-top: 4rem;
          li {
            display: flex;
            align-items: center;
            padding: 1.4rem 1.5rem;
            background-color: #FAFAFA;
            border-radius: 0.1rem;
            >img {
              margin-right: 1.1rem;
            }
            &:nth-child(2) {
              margin-top: 1rem;
            }
            &:last-child {
              margin-top: 4.8rem;
              position: relative;
              &::after {
                content: ' ';
                display: block;
                width: 2.4rem;
                height: 2.4rem;
                background: url('/image/tech/arrow-down-icon.svg') no-repeat center / cover;
                position: absolute;
                left: 50%;
                top: -3.6rem;
                transform: translateX(-50%);
              }
            }
            span {
              display: block;
              font-size: 1.4rem;
              line-height: 1.5;
              font-weight: 500;
              color: #333;
            }
          }
        }
      }
    }
  }
  .sec04 {
    .title-wrap {
      p {
        max-width: 68.6rem;
        margin: 2rem auto 0;
      }
    }
    .flex-box {
      display: flex;
      gap: 3.6rem;
      .step-box {
        width: 35rem;
        height: auto;
        display: flex;
        gap: 1.6rem;
        ul {
          padding: 1.5rem 0;
          position: relative;
          display: flex;
          flex-direction: column;
          gap: 3rem;
          justify-content: space-between;
          &.flex-start {
            justify-content: flex-start;
          }
          &::after {
            content: '';
            display: block;
            width: 0.1rem;
            height: calc(100% - 7.5rem);
            border-left: 0.1rem dashed #aaa;
            position: absolute;
            left: 1.6rem;
            top: 49%;
            transform: translateY(-50%);
          }
          li {
            margin-left: 1.6rem;
            padding-left: 3.2rem;
            position: relative;
            z-index: 9;
            &::after {
              content: '';
              display: block;
              width: 0.8rem;
              height: 0.8rem;
              background-color: #aaa;
              border-radius: 50%;
              position: absolute;
              left: -0.33rem;
              top: 1rem;
              transition: all ease 0.3s;
            }
            .mo-img {
              display: none;
            }
            h3 {
              font-size: 2.8rem;
              font-weight: 700;
              color: #333;
              cursor: pointer;
            }
            .text-wrap {
              height: 0;
              overflow: hidden;
              transition: all ease 0.6s;
              margin-top: 1.2rem;
              opacity: 0;
            }
            p {
              font-size: 1.6rem;
              line-height: 1.75;
              font-weight: 300;
              color: #333;
            }
            .list-box {
              margin-top: 3.2rem;
              dl {
                display: flex;
                align-items: center;
                gap: 1rem;
                &:not(:last-child) {
                  margin-bottom: 3.2rem;
                }
                dt {
                  width: 4.4rem;
                  height: 4.4rem;
                  background-color: #FAFAFA;
                  line-height: 4.4rem;
                  text-align: center;
                }
                dd {
                  flex: 1;
                  font-size: 1.4rem;
                  line-height: 1.5;
                  color: #AAAAAA;
                  span {
                    display: block;
                    font-weight: 600;
                    color: #333333;
                    margin-bottom: 0.2rem;
                  }
                }
              }
            }
            &.on {
              .text-wrap {
                opacity: 1;
              }
              &::after {
                background-color: #7F53FF;
              }
              &:first-child {
                .text-wrap {
                  height: 35.2rem;
                  &.en {
                    height: 38.9rem;
                  }
                }
              }
              &:nth-child(2) {
                .text-wrap {
                  height: 38rem;
                  &.en {
                    height: 43.8rem;
                  }
                }
              }
              &:last-child {
                .text-wrap {
                  height: 16.8rem;
                }
              }
            }
          }
        }
      }
      .img-box {
        position: relative;
        flex: 1;
        img {
          display: block;
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .sec02 {
      display: block;
      height: auto;
      padding: 9rem 0 11rem;
      .info {
        padding-left: 10rem;
      }
    }
    .sec03 {
      .flex-box {
        align-items: flex-start;
        .img-box {
          padding: 3.4rem 3rem;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .sec02 {
      .container {
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      .info {
        width: 100%;
        padding-left: 0;
        text-align: center;
        margin-top: 6rem;
      }
    }
    .sec03 {
      .title-wrap {
        p {
          word-break: keep-all;
          br {
            display: none;
          }
        }
      }
      .flex-box {
        display: block;
        .text-box {
          width: 100%;
          padding-top: 0;
          margin-top: 4.2rem;
          h3 {
            text-align: center;
          }
          p {
            text-align: center;
            word-break: keep-all;
          }
        }
      }
    }
    .sec04 {
      .title-wrap {
        margin-bottom: 6.4rem;
      }
      .flex-box {
        flex-direction: column-reverse;
        .img-box {
          display: none;
          width: 100%;
          height: auto;
          flex: unset;
        }
        .step-box {
          width: 100%;
          ul {
            padding: 0;
            gap: 8.4rem;
            &::after {
              display: none;
            }
            li {
              padding-left: 0;
              margin-left: 0;
              &::after {
                display: none;
              }
              .mo-img {
                display: block;
                margin-bottom: 2.6rem;
              }
              .text-wrap {
                height: auto;
                opacity: 1;
              }
              &.on {
                &:nth-child(1) {
                  .text-wrap {
                    height: auto;
                    &.en {
                      height: auto;
                    }
                  }
                }
                &:nth-child(2) {
                  .text-wrap {
                    height: auto;
                    &.en {
                      height: auto;
                    }
                  }
                }
                &:nth-child(3) {
                  .text-wrap {
                    height: auto;
                  }
                }
              }
            }
          }
        }
      }
      
    }
  }

  @media screen and (max-width: 640px) {
    .sec04 {
      .title-wrap {
        p {
          br {
            display: none;
          }
        }
      }
    }
  }

  @media screen and (max-width: 500px) {
    .sec02 {
      .about {
        width: 100%;
        justify-content: center;
        gap: 2rem;
        align-items: center;
        img {
          width: 9rem;
          &.pc {
            display: none;
          }
          &.mobile {
            display: block;
          }
        }
        dl {
          dt {
            font-size: 2rem;
          }
          dd {
            margin-top: 1.1rem;
          }
        }
      }
      .info {
        p {
          font-size: 1.6rem;
        }
        span {
          font-size: 1.6rem;
          margin-top: 2.4rem;
        }
      }
    }
    .sec03 {
      padding: 9rem 0 10rem;
      .flex-box {
        display: block;
        .text-box {
          width: 100%;
          padding-top: 0;
          margin-top: 4.2rem;
          h3 {
            font-size: 1.8rem;
          }
          p {
            font-size: 1.4rem;
          }
          ul {
            margin-top: 3rem;
            li {
              span {
                font-size: 1.3rem;
              }
              &:last-child {
                margin-top: 5.2rem;
                &::after {
                  width: 2rem;
                  height: 2rem;
                }
              }
            }
          }
        }
      }
    }
    .sec04 {
      padding: 6rem 0 10rem;
      .flex-box {
        gap: 1.6rem;
        .step-box {
          ul {
            &::after {
              height: calc(100% - 5.5rem);
            }
            li {
              h3 {
                font-size: 2rem;
              }
              p {
                font-size: 1.4rem;
              }
              .text-wrap {
                margin-top: 1.2rem;
              }
              .list-box {
                margin-top: 3rem;
                dl {
                  &:not(:last-child) {
                    margin-bottom: 2.8rem;
                  }
                  dd {
                    font-size: 1.3rem;
                  }
                }
              }
              &::after {
                top: 0.6rem;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 394px) {
    .sec02 {
      .info {
        span {
          line-height: 2.8rem;
          .mobile {
            display: block;
          }
        }
      }
    }
  }
`

export const CoreTechnologyWrap = styled.div`
  position: relative;
  width: 72rem;
  height: auto;
  margin: 14rem auto 2.2rem;
  .mobile {
    display: none;
  }
  .searchList-wrap {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 41.2rem;
    background-color: white;
    margin: 0 auto;
    border-radius: .4rem;

    > div:nth-child(2) {
      margin-bottom: 10rem;
    }

    > div:nth-child(3) {
      position: absolute;
      z-index: 9;
      left: 50%;
      transform: translateX(-50%);
      top: 9rem;
      border-radius: .4rem;
      width: 58rem;
      height: 12rem;
      padding-top: .7rem;
      border: .5rem solid #D80101 !important;
      background-color: white;

      > div {
        height: 9rem;
        align-items: center;
      }

      p {
        position: relative;
        font-size: 3rem;
        line-height: 3.6rem;
        font-weight: 800;
        color: #D80101;
      }

      p.value {
        display: flex;
        font-size: 2.6rem;
        font-weight: 600;
        gap: 1rem;
        align-items: center;
        > span {
          position: relative;
          top: -.2rem;
          font-weight: 900;
          font-size: 5rem;
        }
      }
    }
    .search-icon-wrap {
      position: absolute;
      left: -7rem;
      top: 1rem;
      display: flex;
      flex-direction: column;
      z-index: 8;
      width: 17rem;
      height: 10rem;
      overflow: hidden;
      > div{
        position: absolute;
        //transition: .5s;
        > img {
          width: 17rem;
          height: 10rem;
        } 
      }
    }
    .search-list-item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 10rem;
      overflow: hidden;

      > div {
        display: flex;
        flex-direction: row;
        gap: 1rem;
      }

      &:not(:nth-child(6)) {
        border-bottom: .1rem solid #EEEEEE;
      }

      p {
        font-size: 24px;
        line-height: 30px;
        font-weight: 600;
        text-align: left;
      }
      .search-list-item-wrap {
        position: relative;
        //transition: .5s;
        top: .2rem;
        padding: 0 3.5rem;
        min-height: 9rem;
        justify-content: space-between;

        > div {
          display: flex;
          align-items: center;
          flex-direction: row;
          line-height: 3.5rem;

          > p{
            position: relative;
          }
          > p.number {
            padding-right: 1.4rem;
          }
        }
        > p{
          position: relative;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 21.3333vw 0 2.8646vw;
    .pc {
      display: none;
    }
    .mobile {
      display: block;
    }
    .searchList-wrap {
      width: 53.6458vw;
      border-radius: 0.5208vw;
      .search-icon-wrap {
        left: -9.1146vw;
        top: 1.3021vw;
        width: 22.1354vw;
        height: 13.0208vw;
        > div {
          > {
            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }
      .search-list-item {
        height: 13.0208vw;
        > div {
          gap: 1.3021vw;
        }
        .search-list-item-wrap {
          top: 0.2604vw;
          padding: 0 4.5573vw;
          min-height: 11.7188vw;
          > div {
            line-height: 4.5573vw;
            > p {
              &.number {
                padding-right: 1.8229vw;
              }
            }
          }
        }
        p {
          font-size: 3.1250vw;
          line-height: 3.9063vw;
        }
      } 
      > div {
        &:nth-child(2) {
          margin-bottom: 13.0208vw;
        }

        &:nth-child(3) {
          top: 11.7188vw;
          width: 75.5208vw;
          height: 15.6250vw;
          padding-top: 0.9115vw;
          border-radius: 0.5208vw;
          border: 0.6510vw solid #D80101!important;
          > div {
            height: 11.7188vw;
            p {
              font-size: 3.9063vw;
              line-height: 4.6875vw;
              &.value {
                font-size: 3.3854vw;
                gap: 1.3021vw;
                > span {
                  font-size: 6.5104vw;
                }
              }
            }
          }
        }
      }
    }
  }
`