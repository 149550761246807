import {atom} from 'recoil'
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist({
  key: 'persist_root',
  storage: localStorage,
})

export const popupAtom = atom({
  key: 'popupAtom',
  default: [],
  effects_UNSTABLE: [persistAtom],
})
