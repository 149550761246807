import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  padding: 1rem;
  margin-top: 5rem;
  gap: 2rem;
  @media screen and (max-width: 785px) {
    gap: 5rem;
  }
  @media screen and (max-width: 500px) {
    gap: 3rem;
  }
`

const Button = styled.button`
  padding: .7rem 1rem .5rem;
  text-align: left;
  color: #CCCCCC;
  cursor: pointer;
  transition: .5s;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;


  @media (max-width: 768px) {
    padding: .5rem .8rem .2rem .8rem;
  }
  
  &.prev-btn, &.next-btn {
    position: relative;
    border: 1px solid #333;
    padding: 0;
    width: 4.4rem;
    height: 4.4rem;
    &.disabled {
      border: 1px solid #ccc;
    }
    svg{ 
      position: absolute;
      left: 50%;
      height: 50%;
      transform: translateY(-50%) translateX(-50%);
    }
    &:not(&.disabled) {
      &:hover{
        background-color: #333;
        svg {
          filter: invert(1);
        }
      }
    }
  }


  &.active {
    color: #333333;
  }
`

const Pages = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
`

const PageItem = styled.li`
  margin: 0 .4rem;
`

const PageCeil = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 .6rem;
  font-size: 14px;
  font-weight: 600;
  line-height: 25px;
  text-align: left;
  color: #333;
  >span {
    color: #DDDDDD;
  }

`


export const Style = {
  Container,
  Button,
  Pages,
  PageItem,
  PageCeil
}
