import { useEffect, useState, useRef } from 'react';

interface ViewportSize {
  width: number;
  height: number;
}
function useViewportSize(): ViewportSize {
  const [viewport, setViewportSize] = useState<ViewportSize>({
    width: 0,
    height: 0,
  });

  let timer = useRef<NodeJS.Timeout>();
  const sizeDebouncer = () => {
    clearTimeout(timer.current);
    timer.current = setTimeout(handleSize, 1000);
  };
  const handleSize = () => {
    setViewportSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };
  useEffect(() => {
    handleSize();
    window.addEventListener('resize', sizeDebouncer);
    return () => {
      window.removeEventListener('resize', sizeDebouncer);
    };
  }, []);

  return viewport;
}

export default useViewportSize;