import React, { useEffect, useState } from 'react'
import { HomeWrap } from './styles';
import BusinessSlider from '../../components/businessSlider';
import NewsSlider from '../../components/newsSlider';
import Partners from '../../components/partners';
import Modal from "../../components/modal";
import {useTranslation} from "react-i18next";
import PopupAction from "../../store/action/popup";
import { useRecoilValue } from 'recoil';
import { langAtom } from '../../store/recoil/lang';
import FloatingVuIntroBanner from './FloatingVuIntroBanner';


const Home = () => {
  const { t, i18n } = useTranslation()

  // 번역
  const lang = useRecoilValue(langAtom)

  const {getPopup} = PopupAction()

  const [popupList, setPopupList] = useState([])
  const [aosOffset, setAosOffset] = useState(0)

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        setAosOffset(300)
      } else {
        setAosOffset(400)
      }
    }

    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    getPopup(setPopupList)
  }, [])

  return (
    <HomeWrap>
      <section className='visual'>
        <div className='container'>
          <div className="visual-img">
            <img src='/image/main-visual.svg' alt='메인 비쥬얼' className='pc' />
            <img src='/image/mobile/main-visual.svg' alt='메인 비쥬얼' className='mo' />
            <span className='dots dots01'></span>
            <span className='dots dots02'></span>
            <span className='dots dots03'></span>
            <span className='dots dots04'></span>
            <span className='dots dots05'></span>
            <span className='dots dots06'></span>
            <span className='dots dots07'></span>
            <span className='dots dots08'></span>
            <span className='dots dots09'></span>
          </div>
          <img src="/image/mobile/arrow-down-icon.svg" alt="화살표" className='mo arrow-down' />
          <div className="title-wrap">
            <div className="title">
              <h2>
                <span
                  data-aos="slide-right"
                  data-aos-offset={0}
                >TRUSTWORTHY</span>
              </h2>
            </div>
            <div className="title">
              <h2>
                <span
                  data-aos="slide-left"
                  data-aos-offset={0}
                >DATA</span>
              </h2>
            </div>
            <div className="title">
              <h2>
                <span
                  data-aos="slide-right"
                  data-aos-offset={0}
                >TECHNOLOGY</span>
              </h2>
            </div>
          </div>
        </div>
      </section>
      <section className='sec01'>
        <div className='container'>
          <div className='logo' data-aos="fade-up" data-aos-delay="0" data-aos-offset={aosOffset}>
            <img src='/image/becuai-logo.svg' alt='becuai 로고' />
          </div>
          {
            lang ? (
              <p data-aos="fade-up" data-aos-delay="100" data-aos-offset={aosOffset}>
                ‘BECUAI’는 Pre Data Processing SW Platform ‘AIROOT’를 기반으로 뉴스, 미디어, 법률, <br/>
                논문, 경제/기업 정보 등 다양한 섹터의 고급 지식 데이터(High value informative data)들을 <br/>
                수집/아카이브 하여 가치를 높이고, 생성형 인공지능 서비스에서 빠르고 유연하게 적용이 가능하도록 <br/>
                최적화된 상태로 제공하는 Tech 기반의 Multi Contents Provider 입니다. <br/>
                <span>BECUAI는 기업이 ‘인공지능(AI)’ 확장에 필요한 지식 데이터 및 관련 솔루션의 통합 공급(One-Stop Solution) <br/>
                서비스를 제공합니다.</span>
              </p>
            ) : (
              <p data-aos="fade-up" data-aos-delay="100" data-aos-offset={aosOffset}>
                ‘BECUAI’ is the Multi Contents Provider, which is based on the Pre-Data Processing SW <br/>
                Platform ‘AIROOT’, that can raise the value of data by gathering advanced informative data <br/>
                from various fields such as News, Media, Law, Research, Economy/Company and can provide <br/>
                optimized service for generative AI faster and flexibly. ‘BECUAI’ is pursuing the One-Stop <br/>
                Service of data and solutions for AI to any company that wants business expansion by AI.
              </p>
            )
          }
        </div>
      </section>
      <section className='sec02'>
        <div className='container'>
          <div className='about'>
            <img src='/image/mckinsey-degital.svg' alt='mckinsey degital' className='pc' />
            <img src='/image/mckinsey-degital.png' alt='mckinsey degital' className='mobile' />
            <dl>
              <dt>
                McKinsey<br/>
                Digital
              </dt>
              <dd>12 May 2023</dd>
            </dl>
          </div>
          <div className='info'>
            {
              lang ? (
                <p>
                  “Generative AI의 핵심은 특정 비즈니스 상황이나 문제에 맞게 연마된 데이터에 대한 유연한 엑세스입니다.
                  데이터를 효과적으로 연계시키고 데이터에 즉시 엑세스할 수 있는 방법을 아직 찾지 못한 기업은 Generative AI를 파인튜닝할 수 없으며, 잠재력 큰 혁신의 기회로 연계할 수 없습니다. 기업들에게 비즈니스 가치에 기반을 둔 명확한 데이터&인프라 전략을 통한 Generative AI에 파생되는 경쟁 우위는 더욱 더 중요해 질 것 입니다.”
                </p>
              ) : (
                <p>
                  “The lifeblood of generative AI is fluid access to data honed for a specific business context or problem. Companies that have not yet found ways to effectively harmonize and provide ready access to their data will be unable to finetune generative AI to unlock more of its potentially transformative uses. … A clear data and infrastructure strategy anchored on the business value and competitive advantage derived from generative AI will be critical.”
                </p>
              )
            }
            <span>What every CEO should know<br/> about generative AI</span>
          </div>
        </div>
      </section>
      <BusinessSlider />
      <NewsSlider />
      <Partners />
      {
        popupList.map((item, key) => <Modal key={key} item={item} />)
      }
      <FloatingVuIntroBanner />
    </HomeWrap>
  )
}

export default Home
