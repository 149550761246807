import { useRecoilValue } from "recoil";
import {Body, ExtraFont, FlexBox, FlexRowWrap, SemiFont} from "../../../../common/commonStyle";
import {CeoBody, CeoImag, CeoInfoContent, CEOInfomation, CeoInfoTitle, CeoSign, Wrap} from "./styles";
import { langAtom } from "../../../../store/recoil/lang";

const Index = () => {

  const lang = useRecoilValue(langAtom)

  return <Wrap>
    <FlexRowWrap>
      <CeoBody>
        <FlexBox flex={5} responsiveFlexDisable={true}>
          <CeoImag src={'/image/company/ceo-profile3.png'} className="pc" />
          <CeoImag src={'/image/mobile/company/ceo-profile3.png'} className="mobile" />
        </FlexBox>
        <FlexBox flex={7} responsiveFlexDisable={true}>
          <CEOInfomation>
            {lang ? 'CEO Message' : 'Message from the CEO'}
          </CEOInfomation>
          <CeoInfoTitle>{lang ? '㈜비큐AI 대표이사 임경환 입니다.' : 'CEO of BECUAI, Kyoung-Hwan Im'}</CeoInfoTitle>
          <CeoInfoContent>
            {
              lang ? '㈜비큐AI는 지난 20여 년간 국내 최대 규모의 미디어 빅데이터 센터를 운영하며, 독자적인 데이터 전처리 기술력과 뉴스 데이터 서비스 플랫폼으로서의 차별화된 노하우를 갖고 성장해 왔습니다.' : `For over 20 years, we've cultivated our unique data preprocessing technology and expertise as a news data service platform, all while running the largest domestic media big data center.`
            }
          </CeoInfoContent>
          {
            lang ? (
              <>
                <CeoInfoContent>
                  이제 다가오는 4차 산업시대에 발맞추어, ‘인공지능(AI)’ 확장에 필요한 지식 데이터 및 솔루션 <br/>
                  통합 공급 서비스 제공을 통해 한 단계 더 도약하려 합니다.
                </CeoInfoContent>
                <CeoInfoContent>
                  이를 위해, ㈜비큐AI는 데이터 전처리 기술을 다양한 범위에 적용하여 인공지능을 위한 높은 <br />
                  가치의 데이터를 확보하고, MCP(Multi Contents Provider)로서 AI 시장에 핵심적인 역할을 <br/>
                  하겠습니다. 그 뿐만 아니라, 인공지능을 위한 인프라와 플랫폼 구축을 통해 수요고객들의 다양한 <br/>
                  비즈니스 영역에서 AI 개발을 통해 혁신을 이룰 수 있도록 기반을 만들어 드리겠습니다. <br/>
                  대한민국의 인공지능 AI 산업 발전에 한 축이 되는 ㈜비큐AI가 되겠습니다. <br />
                  <br/>
                  감사합니다.<br/>
                </CeoInfoContent>
                <CeoSign>
                  ㈜비큐AI 대표이사<br />
                  <strong>임 경 환</strong>
                </CeoSign>
              </>
            ) : (
              <>
                <CeoInfoContent>
                  In the era of the Fourth Industrial Revolution, we are going to have big step to advance comprehensive service further by offering a comprehensive service, providing a large and reliable database and integrated solutions to support the expansion of artificial intelligence (AI).
                </CeoInfoContent>
                <CeoInfoContent>
                For this, BECUAI will play a leading role in the AI market as MCP(Multi Contents Provider) by applying the pre-data processing technology to a wide range and producing high-value data. In addition, we are ready to support our customers to innovate their business areas through AI development by infrastructure and platforms for AI. BECUAI will be a key player in the development of the AI industry in Korea.<br/><br/>
                Thank you.
                </CeoInfoContent>
                <CeoSign>
                  CEO of BECUAI<br />
                  <strong>Kyoung-Hwan Im</strong>
                </CeoSign>
              </>
            )
          }
        </FlexBox>
      </CeoBody>
    </FlexRowWrap>
  </Wrap>
}

export default Index
