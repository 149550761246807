import styled from "styled-components";

export const DataWrap = styled.div`
  width: 100%;
  height: auto;
  .service-wrap {
    width: 100%;
    height: auto;
    padding-top: 8rem;
  }
  .tab-btn-wrap {
    width: 100%;
    height: auto;
    margin-top: 7rem;
    .btn-wrap {
      width: 100%;
      height: 7rem;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 9;
      background-color: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur(5px);
      button {
        display: block;
        font-size: 2.4rem;
        font-weight: 800;
        color: #999;
        &.active {
          color: #333;
        }
        &:first-child {
          padding-right: 2.4rem;
          margin-right: 2.4rem;
          position: relative;
          &::after {
            content: '';
            display: block;
            width: 0.1rem;
            height: 1.7rem;
            background-color: #ccc;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
      &.fixed {
        position: fixed;
        left: 0;
        top: 8rem;
        z-index: 99;
      }
    }
  }
  section {
    padding: 16rem 0;
  }
  .box-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .text-box {
      h2 {
        font-size: 4rem;
        line-height: 1.45;
        font-weight: 800;
        color: #333;
      }
      p {
        font-size: 1.8rem;
        line-height: 1.89;
        font-weight: 500;
        color: #666;
        margin-top: 3rem;
        strong {
          font-weight: 800;
          color: #333;
        }
      }
    }
    .img-box {
      position: relative;
      .circle {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      span {
        display: block;
        font-size: 4.5rem;
        font-weight: 800;
        color: #333;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      .on {
        transform: scale(1);
      }
    }
    .circle-map {
      width: 56rem;
      height: 43rem;
      position: relative;
      .circle-img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      .logo-wrap {
        img {
          position: absolute;
          transition: all ease 1s;
        }
        .logo01 {
          top: 0;
          left: 50%;
          transform: translateX(-50%);
        }
        .logo02 {
          top: 18%;
          left: 75%;
        }
        .logo03 {
          top: 50%;
          left: 85%;
          transform: translateY(-50%);
        }
        .logo04 {
          top: 80%;
          left: 75%;
        }
        .logo05 {
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
        .logo06 {
          top: 80%;
          left: 11%;
        }
        .logo07 {
          top: 50%;
          left: 6%;
          transform: translateY(-50%);
        }
        .logo08 {
          top: 18%;
          left: 12%;
        }
      }
      .show-logos {
        margin: 0 auto;
        text-align: center;
        img {
          transition: all ease 1s;
          transform: scale(0.5);
          opacity: 0;
        }
      }
      &.on {
        .logo-wrap {
          .logo01 {
            top: 25%;
            transform: translateX(-50%) scale(0.5);
          }
          .logo02 {
            top: 33%;
            left: 58%;
            transform: scale(0.5);
            opacity: 0;
          }
          .logo03 {
            left: 63.5%;
            transform: translateY(-50%) scale(0.5);
          }
          .logo04 {
            top: 68%;
            left: 54.7%;
            transform: scale(0.5);
          }
          .logo05 {
            bottom: 22%;
            transform: translateX(-50%) scale(0.5);
            opacity: 0;
          }
          .logo06 {
            top: 67.5%;
            left: 30.6%;
            transform: scale(0.5);
          }
          .logo07 {
            left: 25.7%;
            transform: translateY(-50%) scale(0.5);
          }
          .logo08 {
            top: 31%;
            left: 29%;
            transform: scale(0.5);
            opacity: 0;
          }
        }
        .show-logos {
          img {
            transform: scale(1);
            opacity: 1;
          }
        }
      }
    }
  }
  .sec02 {
    background-color: #FAFAFA; 
  }
  .sec04 {
    padding-bottom: 0rem;
    .title-wrap {
      width: 100%;
      height: auto;
      text-align: center;
      span {
        display: block;
        font-size: 2.4rem;
        font-weight: 600;
        color: #333;
        margin-bottom: 1rem;
        line-height: 2.6rem;
        .pc {
          display: none;
        }
      }
      h2 {
        font-size: 4rem;
        font-weight: 800;
        color: #333;
        line-height: 4.8rem;
      }
    }
    .sales-wrap {
      display: flex;
      gap: 5.2rem;
      .box {
        flex: 1;
        .circle-wrap {
          width: 100%;
          background-color: #FAFAFA;
          padding-top: 100%;
          position: relative;
          border-radius: 50%;
          margin-top: 2rem;
          .count {
            font-size: 12rem;
            font-weight: 800;
            color: #d80101;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
          span {
            display: block;
            font-size: 2.4rem;
            color: #D80101;
            position: absolute;
            left: 50%;
            bottom: 6.5rem;
            transform: translateX(-50%);
          }
        }
        p {
          font-size: 1.8rem;
          line-height: 1.89;
          font-weight: 500;
          color: #666;
          text-align: center;
          margin-top: 3.5rem;
          strong {
            font-weight: 800;
            color: #333;
          }
        }
        &:first-child {
          .circle-wrap {
            position: relative;
            &::after {
              content: '';
              display: block;
              width: 15rem;
              height: 15rem;
              border-radius: 50%;
              background: url('/image/data/becuai-logo-circle.svg') no-repeat center;
              position: absolute;
              right: -10.1rem;
              top: 50%;
              transform: translateY(-50%);
              z-index: 9;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .service-wrap {
      padding-top: 7rem;
    }
    .box-wrap {
      display: block;
      .text-box {
        text-align: center;
      }
      .img-box {
        text-align: center;
        margin-top: 6rem;
      }
      .circle-map {
        margin-top: 6rem;
      }
      &.reverse {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
      }
    }
    .sec04 {
      .sales-wrap {
        .box {
          &:first-child {
            .circle-wrap {
              &::after {
                width: 12rem;
                height: 12rem;
                right: -8.6rem;
              }
            }
          }
          .circle-wrap {
            .count {
              font-size: 8rem;
            }
            span {
              font-size: 2rem;
            }
          }
          p {
            word-break: keep-all;
            font-size: 1.6rem;
            br {
              display: none;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .sec04 {
      .sales-wrap {
        flex-direction: column;
        margin-top: 6rem;
        .box {
          &:first-child {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            .circle-wrap {
              &::after {
                right: unset;
                left: 50%;
                top: unset;
                bottom: -8.6rem;
                transform: translateX(-50%);
              }
              .info {
                flex-direction: column-reverse;
              }
              span {
                margin-top: 0;
                margin-bottom: 2rem;
              }
            }
            p {
              margin-top: 0;
              margin-bottom: 3rem;
            }
          }
          .circle-wrap {
            margin-top: 0;
            .info {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              text-align: center;
            }
            .count {
              position: unset;
              transform: unset;
            }
            span {
              position: unset;
              transform: unset;
              margin-top: 2rem;
            }
          }
          p {
            margin-top: 3rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: 594px) {
    .box-wrap {
      .circle-map {
        width: 100%;
        height: auto;
        padding: 0 3.367vw;
        .big-circle {
          width: 53vw;
        }
        .small-circle {
          width: 28.4512vw;
        }
        .logo-wrap {
          .logo01 {
            width: 16.33vw;
          }
          .logo02 {
            width: 10.4377vw;
          }
          .logo03 {
            width: 10.6061vw;
          }
          .logo04 {
            width: 14.1414vw;
          }
          .logo05 {
            width: 13.4680vw;
          }
          .logo06 {
            width: 13.1313vw;
          }
          .logo07 {
            width: 9.2593vw;
          }
          .logo08 {
            width: 11.4478vw;
          }
        }
      }
    }
  }

  @media screen and (max-width: 573px) {
    section {
      padding: 8rem 0 12rem;
    }
    .box-wrap {
      .img-box {
        padding: 0 3.5026vw;
        .circle {
          width: 57vw;
        }
        span {
          font-size: 6.9333vw;
        }
      }
      .text-box {
        h2 {
          font-size: 2.6rem;
        }
        p {
          font-size: 1.6rem;
          word-break: keep-all;
          br {
            display: none;
          }
        }
      }
    }
    .sec02 {
      padding: 12rem 0;
    }
  }

  @media screen and (max-width: 500px) {
    .sec04 {
      padding-top: 12rem;
      .title-wrap {
        span {
          font-size: 1.8rem;
          margin-bottom: 0.8rem;
          .pc {
            display: block ;
          }
        }
        h2 {
          font-size: 2.6rem;
        }
      }
      .sales-wrap {
        gap: 1.2rem;
        margin-top: 5rem;
        .box {
          &:first-child {
            .circle-wrap {
              &::after {
                width: 12.6rem;
                height: 12.6rem;
                bottom: -6.9rem;
              }
            }
          }
          .circle-wrap {
            max-width: 343px;
            padding-top: 343px;
            margin: 0 auto;
            .count {
              font-size: 7rem;
            }
            span {
              font-size: 1.6rem;
            }
          }
          p {
            font-size: 1.6rem;
          }
        }
      }
    }
  }
`
