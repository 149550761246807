import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from './default/Header'
import Footer from './default/Footer'
import { Wrap } from './styles'

const DefaultLayout = () => {
  return (
    <>
      <Header/>
      <Wrap>
        <Outlet/>
      </Wrap>
      <Footer/>
    </>
  )
}

export default DefaultLayout
