import {defaultService} from "../service";

const DataAction = () => {

  const getValue = async (params: any) => {
    return await defaultService.handleService({
      method: 'get',
      endPoint: `/v1/setting/get?infoType=${params}`
    }).then(
      response => response.data.payload.value,
      error => console.log(error.message)
    )
  }

  return {
    getValue
  }
}

export default DataAction
