import {atom} from 'recoil'
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist({
  key: 'persist_root',
  storage: localStorage,
})

export const langAtom = atom({
  key: 'langAtom',
  default: true,
  effects_UNSTABLE: [persistAtom],
})
