import React from "react";
import { MobileGnbWrap } from "./styles";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { langAtom } from "../../store/recoil/lang";
import useViewportSize from "../../hook/useViewportSize";

interface MobileGnbProps {
  active: boolean;
}

const MobileGnb = ({ active }: MobileGnbProps) => {
  const { width, height } = useViewportSize();

  // 다국어
  const [lang, setLang] = useRecoilState(langAtom);
  const navigate = useNavigate();
  const location = useLocation();

  // gnbList
  const gnbList = [
    {
      title: "Data",
      link: "/en/data",
      dep2List: [
        {
          title: "Service",
          link: "/en/data",
        },
        {
          title: "Sales",
          link: "/en/data",
        },
      ],
    },
    {
      title: "Tech",
      link: "/en/tech",
      dep2List: [
        {
          title: "Pre-Processing",
          link: "/en/tech#processing",
        },
        {
          title: "AI+Data",
          link: "/en/tech#data",
        },
      ],
    },
    {
      title: "Products",
      link: "/en/products",
      dep2List: [
        {
          title: "AI Root",
          link: "/en/products",
        },
        {
          title: "RDP Line",
          link: "/en/products",
        },
        {
          title: "AI Surfer",
          link: "/en/products",
        },
      ],
    },
    {
      title: "IR·PR",
      link: "/en/invest",
      dep2List: [
        {
          title: "재무정보",
          link: "/en/invest",
        },
        {
          title: "보도자료",
          link: "/en/invest",
        },
        {
          title: "공지사항",
          link: "/en/invest",
        },
        {
          title: "공시정보",
          link: "/en/invest",
        },
      ],
    },
    {
      title: "Company",
      link: "/en/company",
    },
  ];

  const kr = location.pathname.split("/en");
  const handleLang = (b: boolean) => {
    setLang(b);
    const pathname = location.pathname
      .replace(/\/en\//g, "/")
      .replace("/en", "");
    navigate(
      b
        ? kr[1] === ""
          ? "/"
          : kr[1]
        : pathname === "/"
        ? "/en"
        : `/en${pathname}`
    );
  };

  return (
    <MobileGnbWrap
      className={active ? "on" : ""}
      style={{ height: `${window.innerHeight}px` }}
    >
      <div className="container">
        <ul className="gnb">
          {gnbList.map((item, index) => {
            return (
              <li key={index}>
                <NavLink to={item.link}>{item.title}</NavLink>
              </li>
            );
          })}
        </ul>
        <div className="btn-wrap">
          <a
            href={process.env.REACT_APP_CAREER_URL}
            target="_blank"
            rel="noreferrer"
          >
            Careers
          </a>
          <ul className="lang">
            <li>
              <button
                type="button"
                className={lang ? "active" : ""}
                onClick={() => handleLang(true)}
              >
                KR
              </button>
            </li>
            <li>
              <button
                type="button"
                className={lang ? "" : "active"}
                onClick={() => handleLang(false)}
              >
                EN
              </button>
            </li>
          </ul>
        </div>
      </div>
    </MobileGnbWrap>
  );
};

export default MobileGnb;
