import styled from "styled-components";

export const FinanceWrap = styled.div`
  width: 100%;
`

export const TableInfo = styled.div`
  margin: 6.3rem 0 .8rem 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media screen and (max-width: 1200px) {
    padding: 0 1.6rem;
  }
`

export const TableTitle = styled.p`
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  color: #333333;

  @media screen and (max-width: 500px) {
    font-size: 14px;
  }
`

export const TableUnit = styled.p`
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  color: #333333;

  @media screen and (max-width: 500px) {
    font-size: 12px;
  }
`
