import {FinanceWrap, TableInfo, TableTitle, TableUnit} from "./styles";
import {BasicTable, BasicTableWrap} from "../../../common/commonStyle";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { langAtom } from "../../../store/recoil/lang";

const Index = () => {

  const lang = useRecoilValue(langAtom)

  const [tableWidth1, setTableWidth1] = useState(0)
  const [tableWidth2, setTableWidth2] = useState(0)
  const [tableWidth3, setTableWidth3] = useState(0)

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 500) {
        setTableWidth1(140)
        setTableWidth2(140)
        setTableWidth3(140)
      } else {
        setTableWidth1(300)
        setTableWidth2(240)
        setTableWidth3(480)
      }
    }

    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return <FinanceWrap>
    <BasicTableWrap>
      <BasicTable className="w56">
        <colgroup>
          <col width={tableWidth1} />
          <col width={tableWidth1} />
          <col width={tableWidth1} />
          <col width={tableWidth1} />
        </colgroup>
        {
          lang ? (
            <tr>
              <th>상장주식수(주)</th>
              <td>31,445,725</td>
              <th>액면가(원)</th>
              <td>500</td>
            </tr>
          ) : (
            <tr>
              <th>No. of Listed Shares</th>
              <td>31,445,725</td>
              <th>Par value(Won)</th>
              <td>500</td>
            </tr>
          )
        }
      </BasicTable>
    </BasicTableWrap>

    {
      lang ? (
        <TableInfo>
          <TableTitle>재무상태표</TableTitle>
          <TableUnit>(단위: 백만원)</TableUnit>
        </TableInfo>    
      ) : (
        <TableInfo>
          <TableTitle>Statement of Financial Position</TableTitle>
          <TableUnit>(in millions of Korean Won)</TableUnit>
        </TableInfo>
      )
    }

    <BasicTableWrap>
    <BasicTable>
      <colgroup>
        <col width={tableWidth2}/>
        <col width={tableWidth2}/>
        <col width={tableWidth2}/>
        <col width={tableWidth2}/>
        <col width={tableWidth2}/>
      </colgroup>
      {
        lang ? (
          <>
            <tr>
              <th></th>
              <th>과목</th>
              <th>2023</th>
              <th>2022</th>
              <th>2021</th>
            </tr>

            <tr>
              <th rowSpan={3}> 자산</th>
              <td>유동자산</td>
              <td className={'right'}>14,781</td>
              <td className={'right'}>13,955</td>
              <td className={'right'}>8,622</td>
            </tr>
            <tr>
              <td>비유동자산</td>
              <td className={'right'}>1,661</td>
              <td className={'right'}>2,443</td>
              <td className={'right'}>895</td>
            </tr>
            <tr className={'sum'}>
              <td className={'bold'}>자산총계</td>
              <td className={'right'}>16,443</td>
              <td className={'right'}>16,398</td>
              <td className={'right'}>9,517</td>
            </tr>

            <tr>
              <th rowSpan={3}> 부채</th>
              <td>유동부채</td>
              <td className={'right'}>3,850</td>
              <td className={'right'}>3,754</td>
              <td className={'right'}>6,582</td>
            </tr>
            <tr>
              <td>비유동부채</td>
              <td className={'right'}>276</td>
              <td className={'right'}>738</td>
              <td className={'right'}>523</td>
            </tr>
            <tr className={'sum'}>
              <td className={'bold'}>부채총계</td>
              <td className={'right'}>4,127</td>
              <td className={'right'}>4,493</td>
              <td className={'right'}>7,105</td>
            </tr>

            <tr>
              <th rowSpan={2}> 자본</th>
              <td>자본금</td>
              <td className={'right'}>15,772</td>
              <td className={'right'}>15,772</td>
              <td className={'right'}>2,624</td>
            </tr>
            <tr>
              <td>자본금 총계</td>


              <td className={'right'}>12,316</td>
              <td className={'right'}>11,905</td>
              <td className={'right'}>2,412</td>
            </tr>

            <tr className={'sum'}>
              <th/>
              <td className={'bold'}>부채와 자본총계</td>


              <td className={'right'}>16,443</td>
              <td className={'right'}>16,398</td>
              <td className={'right'}>9,517</td>
            </tr>
          </>
        ) : (
          <>
            <tr>
              <th></th>
              <th>Accounts</th>
              <th>2023</th>
              <th>2022</th>
              <th>2021</th>
            </tr>

            <tr>
              <th rowSpan={3}> Assets</th>
              <td>Current assets</td>
              <td className={'right'}>14,781</td>
              <td className={'right'}>13,955</td>
              <td className={'right'}>8,622</td>
            </tr>
            <tr>
              <td>Non-current assets</td>
              <td className={'right'}>1,661</td>
              <td className={'right'}>2,443</td>
              <td className={'right'}>895</td>
            </tr>
            <tr className={'sum'}>
              <td className={'bold'}>Total assets</td>
              <td className={'right'}>16,443</td>
              <td className={'right'}>16,398</td>
              <td className={'right'}>9,517</td>
            </tr>

            <tr>
              <th rowSpan={3}> Liabilities</th>
              <td>Current liabilities</td>
              <td className={'right'}>3,850</td>
              <td className={'right'}>3,754</td>
              <td className={'right'}>6,582</td>
            </tr>
            <tr>
              <td>Non-current liabilities</td>
              <td className={'right'}>276</td>
              <td className={'right'}>738</td>
              <td className={'right'}>523</td>
            </tr>
            <tr className={'sum'}>
              <td className={'bold'}>Total liabilities</td>
              <td className={'right'}>4,127</td>
              <td className={'right'}>4,493</td>
              <td className={'right'}>7,105</td>
            </tr>

            <tr>
              <th rowSpan={2}> Owner's equity</th>
              <td>Share capital</td>
              <td className={'right'}>15,772</td>
              <td className={'right'}>15,772</td>
              <td className={'right'}>2,624</td>
            </tr>
            <tr>
              <td>Total equity</td>
              <td className={'right'}>12,316</td>
              <td className={'right'}>11,905</td>
              <td className={'right'}>2,412</td>
            </tr>

            <tr className={'sum'}>
              <th/>
              <td className={'bold'}>Total liabilities <br/>and equity</td>


              <td className={'right'}>16,443</td>
              <td className={'right'}>16,398</td>
              <td className={'right'}>9,517</td>
            </tr>
          </>
        )
      }

    </BasicTable>
    </BasicTableWrap>

    {
      lang ? (
        <TableInfo>
          <TableTitle>손익계산서</TableTitle>
          <TableUnit>(단위: 백만원)</TableUnit>
        </TableInfo>
      ) : (
        <TableInfo>
          <TableTitle>Statement of Comprehensive Income</TableTitle>
          <TableUnit>(in millions of Korean Won)</TableUnit>
        </TableInfo>
      )
    }

    <BasicTableWrap>
    <BasicTable className="w56">

      <colgroup>
        <col width={tableWidth3}/>
        <col width={tableWidth2}/>
        <col width={tableWidth2}/>
        <col width={tableWidth2}/>
      </colgroup>

      {
        lang ? (
          <>
            <tr>
              <th>과목</th>


              <th>2023</th>
              <th>2022</th>
              <th>2021</th>
            </tr>

            <tr>
              <th>영업수익</th>
              <td className="right">16,892</td>
              <td className="right">16,259</td>
              <td className="right">17,018</td>
            </tr>

            <tr>
              <th>영업비용</th>
              <td className="right">16,652</td>
              <td className="right">17,387</td>
              <td className="right">17,961</td>
            </tr>

            <tr>
              <th>영업이익</th>
              <td className="right">240</td>
              <td className="right">-1,129</td>
              <td className="right">-942</td>
            </tr>

            <tr>
              <th>영업외손익</th>
              <td className="right">493</td>
              <td className="right">1,094</td>
              <td className="right">-1,081</td>
            </tr>

            <tr>
              <th>법인세비용<br/>차감전순이익</th>


              <td className="right">733</td>
              <td className="right">-35</td>
              <td className="right">-2,023</td>
            </tr>

            <tr>
              <th>당기순이익</th>


              <td className="right">733</td>
              <td className="right">-46</td>
              <td className="right">-746</td>
            </tr>
          </>
        ) : (
          <>
            <tr>
              <th>Accounts</th>


              <th>2023</th>
              <th>2022</th>
              <th>2021</th>
            </tr>

            <tr>
              <th>Net Sales</th>
              <td className="right">16,892</td>
              <td className="right">16,259</td>
              <td className="right">17,018</td>
            </tr>

            <tr>
              <th>Operating costs</th>
              <td className="right">16,652</td>
              <td className="right">17,387</td>
              <td className="right">17,961</td>
            </tr>

            <tr>
              <th>Operating profit</th>
              <td className="right">240</td>
              <td className="right">-1,129</td>
              <td className="right">-942</td>
            </tr>

            <tr>
              <th>Other costs and profit</th>
              <td className="right">493</td>
              <td className="right">1,094</td>
              <td className="right">-1,081</td>
            </tr>

            <tr>
              <th>Profit before income tax</th>


              <td className="right">733</td>
              <td className="right">-35</td>
              <td className="right">-2,023</td>
            </tr>

            <tr>
              <th>Profit for the period</th>


              <td className="right">733</td>
              <td className="right">-46</td>
              <td className="right">-746</td>
            </tr>
          </>
        )
      }

    </BasicTable>
    </BasicTableWrap>

  </FinanceWrap>
}

export default Index
