import React, { useEffect, useState } from 'react'
import { BusinessSliderWrap } from './styles'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation } from 'swiper/modules'
import { useRecoilValue } from 'recoil';
import { langAtom } from '../../store/recoil/lang';

const BusinessSlider = () => {

  // 다국어
  const lang = useRecoilValue(langAtom)

  // Slider Active Index
  const [sliderActive, setSliderActive] = useState(0)

  const [prevText, setPrevText] = useState('')
  const [nextText, setNextText] = useState('')

  useEffect(() => {
    setPrevText(sliderActive === 2 ? 'AI ROOT' : 'AI SURFER')
    setNextText(sliderActive === 0 ? 'AI ROOT' : 'RDPLINE')
  }, [sliderActive])

  return (
    <BusinessSliderWrap className={sliderActive === 0 ? 'slide01Wrap' : sliderActive === 1 ? 'slide02Wrap' : 'slide03Wrap'}>
      <div className="bg-box">
        <div className="pc">
          <div 
            className={(sliderActive % 3) === 0 ? "bg on" : "bg"}
            style={{
              'background': `url('/image/main-slider-bg01.png') no-repeat center top -6px / cover`
            }}
          ></div>
          <div 
            className={(sliderActive % 3) === 1 ? "bg on" : "bg"}
            style={{
              'background': `url('/image/main-slider-bg02.png') no-repeat center top -6px / cover`
            }}
          ></div>
          <div 
            className={(sliderActive % 3) === 2 ? "bg on" : "bg"}
            style={{
              'background': `url('/image/main-slider-bg03.png') no-repeat center top -6px / cover`
            }}
          ></div>
        </div>
        <div className="tablet-big">
          <div 
            className={(sliderActive % 3) === 0 ? "bg on" : "bg"}
            style={{
              'background': `url('/image/mobile/tablet-big-main-slider-bg01.svg') no-repeat center top -6px / cover`
            }}
          ></div>
          <div 
            className={(sliderActive % 3) === 1 ? "bg on" : "bg"}
            style={{
              'background': `url('/image/mobile/tablet-big-main-slider-bg02.svg') no-repeat center top -6px / cover`
            }}
          ></div>
          <div 
            className={(sliderActive % 3) === 2 ? "bg on" : "bg"}
            style={{
              'background': `url('/image/mobile/tablet-big-main-slider-bg03.svg') no-repeat center top -6px / cover`
            }}
          ></div>
        </div>
        <div className="tablet-small">
          <div 
            className={(sliderActive % 3) === 0 ? "bg on" : "bg"}
            style={{
              'background': `url('/image/mobile/tablet-small-main-slider-bg01.svg') no-repeat center top -6px / cover`
            }}
          ></div>
          <div 
            className={(sliderActive % 3) === 1 ? "bg on" : "bg"}
            style={{
              'background': `url('/image/mobile/tablet-small-main-slider-bg02.svg') no-repeat center top -6px / cover`
            }}
          ></div>
          <div 
            className={(sliderActive % 3) === 2 ? "bg on" : "bg"}
            style={{
              'background': `url('/image/mobile/tablet-small-main-slider-bg03.svg') no-repeat center top -6px / cover`
            }}
          ></div>
        </div>
        <div className="mobile">
          <div 
            className={(sliderActive % 3) === 0 ? "bg on" : "bg"}
            style={{
              'background': `url('/image/mobile/mobile-main-slider-bg01.png') no-repeat center top -6px / cover`
            }}
          ></div>
          <div 
            className={(sliderActive % 3) === 1 ? "bg on" : "bg"}
            style={{
              'background': `url('/image/mobile/mobile-main-slider-bg02.png') no-repeat center top -6px / cover`
            }}
          ></div>
          <div 
            className={(sliderActive % 3) === 2 ? "bg on" : "bg"}
            style={{
              'background': `url('/image/mobile/mobile-main-slider-bg03.png') no-repeat center top -6px / cover`
            }}
          ></div>
        </div>
      </div>
      <div className='container-fluid'>
        <div className='title-wrap'>
          <h2>BUSINESS AREA</h2>
          {
            lang ? (
              <span>더 다양한 분야의 콘텐츠와 글로벌 콘텐츠<br /> 수급을 통해 시장을 확대합니다.</span>
            ) : (
              <span>We will expand our position to the global markets with the data in multi-sector.</span>
            )
          }
        </div>
        <div className='slider-wrap'>
          <div className='arrow-btn-wrap'>
            <button type='button' className='slider-prev'>
              <img src='/image/arrow-left-icon.svg' alt='이전 화살표' />
              <span>
                {prevText}
              </span>
            </button>
            <button type='button' className='slider-next'>
              <img src='/image/arrow-right-icon.svg' alt='다음 화살표' />
              <span>
                {nextText}
              </span>
            </button>
          </div>
          <div className="item">
            <div className="side-box pc">
              {
                sliderActive === 0 ? (
                  <div className={lang ? "logo-box" : 'logo-box mw256'}>
                    <img src='/image/ai-surfer-kim-logo.svg' alt="ai-surfer-kim-logo" />
                    {
                      lang ? (
                        <span>뉴스 검색/생성 AI Chatbot 서비스</span>
                      ) : (
                        <span>AI chatbot for news searching</span>
                      )
                    }
                  </div>
                ) : sliderActive === 1 ? (
                  <ul>
                    <li>
                      <span>High Value Contents</span>
                    </li>
                    <li>
                      <span>Reliable data</span>
                    </li>
                  </ul>
                ) : null
              }
            </div>
            <div className={sliderActive === 2 ? 'center-box w100' : 'center-box'}>
              <div className="circle-wrap">
                <Swiper
                  initialSlide={1}
                  modules={[Navigation]}
                  slidesPerView={1}
                  navigation={{
                    prevEl: ".slider-prev",
                    nextEl: ".slider-next",
                  }}
                  onSlideChange={(swiper: any) => setSliderActive(swiper.realIndex)}
                >
                  <SwiperSlide>
                    <img src='/image/main-slider-logo01.svg' alt='정보구독 서비스' />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src='/image/main-slider-logo02.svg' alt='데이터 전처리' className='pc' />
                    <img src='/image/mobile/main-slider-logo02.svg' alt='데이터 전처리' className="mo" />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src='/image/main-slider-logo03.svg' alt='데이터 공급' className='pc' />
                    <img src='/image/mobile/main-slider-logo03.svg' alt='데이터 공급' className="mo" />
                  </SwiperSlide>
                </Swiper>
                {
                  sliderActive === 0 ? (
                    lang ? (
                      <span>정보구독 서비스</span>
                    ) : (
                      <span>Subscription Service</span>
                    )
                  ) : sliderActive === 1 ? (
                    lang ? (
                      <span>데이터 전처리</span>
                    ) : (
                      <span>Data Pre-Processing</span>
                    )
                  ) : (
                    lang ? (
                      <span>데이터 공급</span>
                    ) : (
                      <span>Data Pipeline</span>
                    )
                  )
                }
              </div>
              <div className={sliderActive === 0 ? "mobile-box space item01" : sliderActive === 1 ? "mobile-box item02" : "mobile-box item03"}>
                <div className="side-box">
                  {
                    sliderActive === 0 ? (
                      <div className="logo-box">
                        <img src='/image/ai-surfer-kim-logo.svg' alt="ai-surfer-kim-logo" />
                        {
                          lang ? (
                            <span>뉴스 검색/생성 AI Chatbot 서비스</span>
                          ) : (
                            <span>AI chatbot for news searching</span>
                          )
                        }
                      </div>
                    ) : sliderActive === 1 ? (
                      <ul>
                        <li>
                          <span>High Value Contents</span>
                        </li>
                        <li>
                          <span>Reliable data</span>
                        </li>
                      </ul>
                    ) : null
                  }
                </div>
                {
                  sliderActive === 0 && (
                    <div className="side-box right">
                      <div className="logo-box">
                        <img src='/image/ai-surfer-vu-logo.svg' alt="ai-surfer-vu-logo" />
                        {
                          lang ? (
                            <span>뉴스 스크랩 공유 및 뷰어 서비스</span>
                          ) : (
                            <span>Board & Viewer service for news clipping</span>
                          )
                        }
                      </div>
                    </div>
                  )
                }
              </div>
              <div className="info">
                {
                  sliderActive === 0 ? (
                    lang ? (
                      <div className="subject">
                        실시간 뉴스 미디어 모니터링 플랫폼
                      </div>
                    ) : (
                      <div className="subject">
                        Real-time news monitoring platform
                      </div>
                    )
                  ) : sliderActive === 1 ? (
                    <div className="subject">
                      Pre data processing SW Platform
                    </div>
                  ) : (
                    lang ? (
                      <div className="subject">
                        AI 학습과 서비스를 위한 Real time<br className='mo'/> data pipeline 플랫폼
                      </div>
                    ) : (
                      <div className="subject">
                        Real-time data supply platform <br className='mo'/>for AI learning
                      </div>
                    )
                  )
                }
                <div className="text">
                  {
                    sliderActive === 0 ? (
                      lang ? (
                        `국내 3,000여개의 뉴스 매체 데이터를 직접 모니터링 또는\n기사 스크랩/편집 기능을 통해 다양한 형태로 활용할 수 있는 플랫폼 입니다.`
                      ) : (
                        `Platform that provides useful function of monitoring, clipping and\nediting tools through 3,000 media can be utilized in various way.`
                      )
                    ) : sliderActive === 1 ? (
                      lang ? (
                        `다양한 형태의 데이터(TEXT, IMAGE, VIDEO)를 수요자의 요청에 맞게\n커스터마이징하여 최적화된 데이터 라벨링&전처리 서비스를 제공합니다.`
                      ) : (
                        `Providing highly optimized data labeling  and preprocessing\nservice to meet diverse customer needs across various types\nof data (Text, Image, Video).`
                      )
                    ) : (
                      lang ? (
                        `생성형 AI 학습에 필요한 데이터를 수요자가 필요한 형태로 가공하여\n대량 공급 또는 실시간 API로 제공합니다.`
                      ) :(
                        `The data for generative AI is provided with\ncustomizing, mass supply or real-time API.`
                      )
                    )
                  }
                </div>
              </div>
            </div>
            <div className="side-box right pc">
              {
                sliderActive === 0 ? (
                  <div className="logo-box">
                    <img src='/image/ai-surfer-vu-logo.svg' alt="ai-surfer-vu-logo" />
                    {
                      lang ? (
                        <span>뉴스 스크랩 공유 및 뷰어 서비스</span>
                      ) : (
                        <span>Board & Viewer service for news clipping</span>
                      )
                    }
                  </div>
                ) : sliderActive === 1 ? (
                  <ul>
                    <li>
                      <span>High Value Contents</span>
                    </li>
                    <li>
                      <span>Reliable data</span>
                    </li>
                  </ul>
                ) : null
              }
            </div>
          </div>
        </div>
      </div>
    </BusinessSliderWrap>
  )
}

export default BusinessSlider