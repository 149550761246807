import styled from "styled-components";

export const DataCountWrap = styled.div`
  width: 100%;
  height: auto;
  transition: all ease 0.3s;
  .container {
    padding: 16rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .count-box-wrap {
    position: relative;
  }
  .data-count-item {
    .title-wrap {
      width: 100%;
      height: auto;
      text-align: center;
      padding-bottom: 10.5rem;
      h3 {
        font-size: 2.8rem;
        font-weight: 500;
        color: #fff;
      }
      span {
        display: block;
        height: 1.6rem; 
        font-size: 1.6rem;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.6);
        margin-top: 1.5rem;
      }
    }
    .count-wrap {
      span {
        display: block;
        font-size: 15rem;
        font-weight: 800;
        color: #fff;
        text-align: center;
      }
    }
  }
  .swiper-pagination {
    position: unset;
    transform: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.1rem;
    margin-top: 14rem;
    span {
      display: block;
      margin: 0 !important;
      width: 3rem;
      height: 0.4rem;
      border-radius: 0;
      background-color: #fff;
      opacity: 0.4;
      transition: all ease 0.3s;
      &.swiper-pagination-bullet-active {
        opacity: 1;
      } 
    }
  }
  .btn-wrap {
    display: flex;
    align-items: center;
    gap: 4rem;
    position: absolute;
    right: 0;
    bottom: -2.6rem;
    button {
      width: 5.2rem;
      height: 5.2rem;
      border: 0.1rem solid #fff;
      transition: all ease 0.3s;
      img {
        transition: all ease 0.3s;
      }
      &.swiper-button-disabled {
        opacity: 0.4;
      }
      &:not(.swiper-button-disabled) {
        &:hover {
          background-color: #fff;
          img {
            filter: invert(18%) sepia(49%) saturate(7392%) hue-rotate(355deg) brightness(80%) contrast(116%);
          }
        }
      }
    }
  }

  @media screen and (max-width: 1232px) {
    .btn-wrap {
      right: 1.6rem;
    }
  }

  @media screen and (max-width: 1024px) {
    .data-count-item {
      .count-wrap {
        span {
          font-size: 12rem;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .data-count-item {
      .count-wrap {
        span {
          font-size: 8rem;
        }
      }
    }
    .btn-wrap {
      position: unset;
      justify-content: center;
      margin-top: 6rem;
      button {
        &:not(.swiper-button-disabled) {
          &:hover {
            background-color: transparent;
            img {
              filter: unset;
            }
          }
          &:active {
            background-color: #fff;
            img {
              filter: invert(18%) sepia(49%) saturate(7392%) hue-rotate(355deg) brightness(80%) contrast(116%);
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 500px) {
    .container {
      padding: 12rem 0;
    }
    .data-count-item {
      .title-wrap {
        padding-bottom: 6rem;
        h3 {
          font-size: 2rem;
        }
        span {
          font-size: 1.4rem;
          margin-top: 1rem;
        }
      }
      .count-wrap {
        span {
          font-size: 5rem;
        }
      }
    }
    .swiper-pagination {
      margin-top: 6rem;
    }
    .btn-wrap {
      position: unset;
      justify-content: center;
      margin-top: 6rem;
      button {
        width: 4.4rem;
        height: 4.4rem;
        img {
          width: 1.5rem;
        }
      }
    }
  }
  
`
