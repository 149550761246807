import React, { useEffect } from 'react'
import {CompanyWrap,} from './styles'
import InfoArea from "./component/infoArea";
import History from "./component/history";
import CeoMessage from "./component/ceoMessage";
import CompanyMap from "./component/companyMap";

const Index = () => {

  // 타이틀 변경
  useEffect(() => {
    const titleElement = document.getElementsByTagName('title')[0]
    if (titleElement) {
      titleElement.innerHTML = 'Company | 비큐AI'
    }

    return () => {
      titleElement.innerHTML = '비큐AI'
    }
  }, [])

  /******
   * 반드시 삭제
   * style={{overflow: 'hidden'}}
   * *******/

  return <CompanyWrap style={{overflow: 'hidden'}}>
    <InfoArea />
    <History />
    <CeoMessage />
    <CompanyMap />
  </CompanyWrap>
}

export default Index
