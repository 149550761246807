import styled from "styled-components";

export const NewsSliderWrap = styled.section`
  padding: 17rem 0;
  padding-left: 18.64vw;
  background-color: #F5F5F5;

  .container-fluid {
    display: flex;
    gap: 5.8rem;
  }

  .title-wrap {
    width: 31rem;
    padding: 7.2rem 0 0;

    span {
      display: block;
      font-size: 3rem;
      font-weight: 800;
      color: #D80101;
      margin-bottom: 1.5rem;
    }

    h2 {
      font-size: 4.8rem;
      line-height: 1.19;
      font-weight: 800;
      color: #333;
    }
  }

  .news-slider {
    width: calc(100% - 31rem);
    padding: 7rem 0 7rem 10rem;
    background-color: #fff;

    .slider-wrap {
      display: flex;
      overflow: hidden;
      .slider {
        display: flex;
        transition: all ease 0.7s;
        .item {
          width: 34rem;
          height: auto;
          a {
            display: block;
            width: 100%;
            height: auto;
            background-color: #fff;

            .thumbnail {
              width: 100%;
              height: 21rem;
              overflow: hidden;
              border-radius: .8rem;
              border: 1px solid #eeeeee;
              margin-bottom: 1rem;
            }

            .title {
              height: 5.3rem;
              font-size: 1.8rem;
              line-height: 1.45;
              font-weight: 600;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }

            .date {
              font-size: 1.4rem;
              color: #aaa;
              margin-top: 0.5rem;
            }
          }
        }
      }
    }

    .btn-wrap {
      display: flex;
      gap: 4rem;
      margin-top: 6rem;

      button {
        width: 5.2rem;
        height: 5.2rem;
        border: 0.1rem solid #333;
        transition: all ease 0.5s;

        &:disabled {
          opacity: 0.2;
        }

        &:not(&:disabled) {
          &:hover {
            background-color: #333333;
            img {filter: invert(100%);}
          }
        }

      }
    }
  }

  @media screen and (max-width: 1048px) {
    padding-left: 2.8rem;
    .container-fluid {
      display: block;
    }
    .title-wrap {
      width: 100%;
      padding: 0 2.8rem 0 0;
      text-align: center;
      margin-bottom: 8rem;
      h2 {
        .mo {
          display: none;
        }
      }
    }
    .news-slider {
      width: 100%;
    }
  }

  @media screen and (max-width: 500px) {
    padding: 6.8rem 0 8rem 2.8rem;
    .title-wrap {
      margin-bottom: 3.6rem;
      span {
        font-size: 1.8rem;
        margin-bottom: 0.8rem;
      }
      h2 {
        font-size: 2.6rem;
        line-height: 3.8rem;
      }
    }
    .news-slider {
      padding: 3rem 0 4rem 3.2rem;
      .btn-wrap {
        display: none;
      }
      .slider-wrap {
        overflow-x: auto;
        padding-right: 3.2rem;
        &::-webkit-scrollbar {
          width: 0;
          height: 0;
        }
        .slider {
          .item {
            width: 20.2rem;
            a {
              .thumbnail {
                height: 11.8rem;
                border-radius: 0.3rem;
                margin-bottom: 1.2rem;
              }
              .title {
                height: 4.1rem;
                font-size: 1.4rem;
              }
              .date {
                font-size: 1.2rem;
                margin-top: 0.8rem;
              }
            }
          }
        }
      }
    }
  }
`
