import {CardBody, CardContent, CardDate, CardDescWrap, CardImage, CardImageWrap, CardTitle} from "./styels";
import NewsAction from "../../store/action/news";
import moment from "moment";

interface CardInterface {
  id: number
  image: string
  title: string
  content: string
  date: string
  link: string
}

const Card = (props: CardInterface) => {

  const {view} = NewsAction()

  const handleOpnLink = () => {
    view(props.id)
    window.open(props.link)
  }

  return<CardBody onClick={handleOpnLink}>
    <CardImageWrap style={{'background': `url('${props.image}') no-repeat center / cover`}}>
    </CardImageWrap>
    <CardDescWrap>
      <CardTitle> {props.title} </CardTitle>
      <CardDate> {moment(props.date).format('YYYY.MM.DD')} </CardDate>
    </CardDescWrap>
  </CardBody>
}

export default Card
