import styled from "styled-components";

export const NoticeWrap = styled.div`
  width: 100%;
`

export const Tr = styled.tr`
  cursor: pointer;
  border-bottom: .1rem solid #EEEEEE;
`

export const TitleTd = styled.td`
  padding: 2.35rem 3.2rem;
  color: #333333;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  text-align: left;
  max-width: 92rem;
  > p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`

export const CreateTd = styled.td`
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  color: #AAAAAA;
`


export const NoticeUl = styled.ul`
  border-top: 1px solid #AAAAAA;
  margin: 0 1.6rem;
`

export const NoticeLi = styled.li`
  padding: 2.4rem 0;
  border-top: 1px solid #EEEEEE;
  cursor: pointer;
  > p.notice-li-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 2.5rem;
    text-align: left;
    color: #333333;
    max-height: 5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* 2줄까지만 표시 */
    -webkit-box-orient: vertical;
  }
  > p.notice-li-date {
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    text-align: left;
    color: #AAAAAA;
  }
`
