import styled from "styled-components";

export const ProductsWrap = styled.div`
  padding: 12rem 0 16rem;
  @media screen and (max-width: 1024px) {
    padding: 0;
    width: 100%;
  }

  @media screen and (max-width: 500px) {
    width: 100%;
  }
`

export const ProductSection02Wrap = styled.div`
  width: 100%;
  height: auto;
`
