import {Body, ExtraFont, Extra50, FlexBox, FlexRowWrap, SemiBold18} from "../../../../../common/commonStyle";
import {
  ButtonWrap, HistoryBody,
  HistoryCircle, HistoryContentText,
  HistoryLi,
  HistoryLine,
  HistoryLineLeftFade,
  HistoryLineRightFade,
  HistoryLineWrap,
  HistoryTopWrap, HistoryUl,
  HistoryWrap, HistoryYearList, HistoryYearListWrap, HistoryYearText, HistoryYearWrap
} from "./styles";
import {useEffect, useRef, useState} from "react";
import PageButton from "./pageButton";
import { useRecoilValue } from "recoil";
import { langAtom } from "../../../../../store/recoil/lang";

const Index = () => {

  // 다국어
  const lang = useRecoilValue(langAtom)

  const [active, setActive] = useState(0)
  const [calc, setCalc] = useState('')
  const historyRef = useRef<any>([])

  const historyList = [
    {
      year: '2023',
      list: [
        '· 연간 흑자 전환',
        '· 자회사 위고데이터 합병(11월)',
        '· RDPLINE 출시(10월)',
        '· AI김기자 정식 출시(5월)',
      ],
      enList: [
        '· Achieved turnaround in annual profits',
        '· Parent-subsidiary merger of WIGO Data(Nov)',
        "· 'RDP Line' Launch(Oct)",
        "· 'AI Kiim' Launch(May)",
      ]
    },
    {
      year: '2022',
      list: [
        '· 본점 소재지 변경(8월)',
        '· 코스닥 이전상장(6월)',
      ],
      enList: [
        '· Headquarter relocation(Aug)',
        '· Listed on KOSDAQ(Jun)'
      ]
    },
    {
      year: '2021',
      list: [
        '· 로제우스(ROZEUS) 출시(9월)',
        '· 하이서울 기업지원 모니터링 및 분석기술분야 선정(6월)',
        '· 미디어 빅데이터 기술협력을 위한 SBS와 업무협약 체결(4월)',
      ],
      enList: [
        "· 'ROZEUS' Launch(Sep)",
        "· Selected as a 'High Seoul' certified company in the field of 'Monitoring and Analysis Technology'(Jun)",
        "· Signed MOU with SBS for cooperation in media big data technology(Apr)"
      ]
    },
    {
      year: '2020 ~ 2011',
      list: [
        '· 한국언론진흥재단 뉴스컨텐츠 디지털화 지원사업(2020)',
        '· 조선일보 100주년 기념 웹 지면서비스 데이터 구축(2020)',
        '· 아이서퍼 V4 런칭(2018)',
        '· 코넥스 상장(2016)',
        '· 아이서퍼 3.0 런칭, 600기관 돌파(2012)',
      ],
      enList: [
        "· Nominated as a 'News Content Digitalization Support Company' by Korea Press Foundation(2020)",
        "· Setup a news database for 'Chosun Ilbo' 100th anniversary' web system(2020)",
        "· Released 'Eyesurfer V4'(2018)",
        "· Listed on KONEX(2016)",
        "· Released 'Eyesurfer 3.0', exceeded 600 institutional users(2012)"
      ]
    },
    {
      year: '2010 ~ 1999',
      list: [
        '· 한국 온라인신문협회 모바일 컨텐츠 MCP 선정(2009)',
        '· 정보통신부 “2006 신 S/W 대상” 수상(2005)',
        '· 아이서퍼 런칭(2005)',
        '· 한국언론재단 뉴스 저작권사업 공식유통사 선정(2005)',
        '· IR52 장영실상 수상(2001)',
      ],
      enList: [
        "· Nominated as a 'Mobile Newspaper Content Provider(MCP)' by KONA(2009)",
        "· Awarded 'Grand Prize' in the '2006 New S/W Award' by Ministry of Science and ICT(2005)",
        "· 'EYESURFER' Launch(2005)",
        "· Selected as 'News Copyright Business' official distributor of Korea Press Foundation(2005)",
        "· Awarded 'IR52 Chang Young-Sil Award' by Ministry of Science and Technology(2005)"
      ]
    },
    {
      year: '1998 창립',
      enYear: 'Established in 1998',
      list: [
        '· 비플라이소프트㈜ 창립'
      ],
      enList: [
        "· Established BFLYSOFT CO., LTD"
      ]
    },
  ]

  const handleChangeActive = (num: number) => {
    const next = active + num
    if (next >= 0 && next < historyList.length) {
      if (next === 0) {
        setCalc('')
      } else {
        let width = 0
        let gap = 0
        historyRef.current.map((ref: any, index: number) => {
          if (index < next) {
            width += ref.clientWidth
            gap++
          }
        })
        setCalc(`- ${width}px - ${17.8 * gap}rem`)
      }
      setActive(prev => prev + num)
    }
  }


  const [paddingTop, setPaddingTop] = useState(3)

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 500) {
        setPaddingTop(0)
      } else {
        setPaddingTop(3)
      }
    }

    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return <HistoryWrap>
    <FlexRowWrap>
      <HistoryBody>
        <FlexBox flex={3} responsiveFlexDisable={true}>
          <Extra50 color={'#FFFFFF'}>History</Extra50>
        </FlexBox>
        <FlexBox flex={4} responsiveFlexDisable={true}>
          {
            lang ? (
              <HistoryContentText>
                BECUAI는 1988년 창립 이후 20년 동안 국내 3000여개의 미디어 데이터를 연결하는 서비스를 <br/>
                제공하고, 독자적인 빅데이터 기술을 확보하는 회사로 성장해 왔습니다. <br/>
                앞으로도, <b>BECUAI는 인공지능(AI)을 위한 데이터 기술과 차별화된 글로벌 MCP</b>로서 <br/>
                더욱 성장해 나가겠습니다.
              </HistoryContentText>
            ) : (
              <HistoryContentText>
                Since 1988, 'BECUAI' has relentlessly worked to create the extraordinary media <br/>
                big-data and services that linked more than 3,000 media channels. <b>'BECUAI' will <br/>
                become the competitive MCP and service platforms with significant and <br/>
                independent technology for AI.</b>
              </HistoryContentText>
            )
          }
        </FlexBox>
      </HistoryBody>
    </FlexRowWrap>


    <HistoryYearListWrap>
      <HistoryLineWrap>
        <HistoryLineLeftFade/>
        <HistoryLine/>
        <HistoryLineRightFade/>
      </HistoryLineWrap>

      <HistoryYearList left={`calc( ((100vw - 120rem) / 2) + (120rem / 7 * 3) ${calc} )`}>
        {
          historyList.map((history, key) => (
            <HistoryYearWrap
              key={key}
              ref={elem => historyRef.current[key] = elem}
            >
              <HistoryCircle/>
              <HistoryYearText>
                {lang ? history.year : history.enYear ? history.enYear : history.year}
              </HistoryYearText>
              <HistoryUl>
                {
                  history.enList.map((item, key) => <HistoryLi key={key}>{item}</HistoryLi>)
                }
              </HistoryUl>
            </HistoryYearWrap>
          ))
        }
      </HistoryYearList>
    </HistoryYearListWrap>

    <FlexRowWrap>
      <Body flexend={true} pdtop={`${paddingTop}rem`}>
        <ButtonWrap>
          <PageButton disabled={active === 0} reverse={true} handleClick={() => handleChangeActive(-1)}/>
          <PageButton disabled={active === historyList.length - 1} handleClick={() => handleChangeActive(1)}/>
        </ButtonWrap>
      </Body>
    </FlexRowWrap>
  </HistoryWrap>
}

export default Index
