export const partnersList01 = [
  {
    logo: '/image/partners-logo01.png'
  },
  {
    logo: '/image/partners-logo02.png'
  },
  {
    logo: '/image/partners-logo03.png'
  },
  {
    logo: '/image/partners-logo04.png'
  },
  {
    logo: '/image/partners-logo05.png'
  },
  {
    logo: '/image/partners-logo06.png'
  },
  {
    logo: '/image/partners-logo07.png'
  },
  {
    logo: '/image/partners-logo08.png'
  },
]

export const partnersList02 = [
  {
    logo: '/image/partners-logo09.png'
  },
  {
    logo: '/image/partners-logo10.png'
  },
  {
    logo: '/image/partners-logo11.png'
  },
  {
    logo: '/image/partners-logo12.png'
  },
  {
    logo: '/image/partners-logo13.png'
  },
  {
    logo: '/image/partners-logo14.png'
  },
  {
    logo: '/image/partners-logo15.png'
  },
  {
    logo: '/image/partners-logo16.png'
  },
]