import React, { useEffect, useRef, useState } from "react";
import { NewsSliderWrap } from "./styles";
import NewsAction from "../../store/action/news";
import moment from "moment";
import { useRecoilValue } from "recoil";
import { langAtom } from "../../store/recoil/lang";
// import {newsList} from './dummy'

const NewsSlider = () => {
  // 다국어
  const lang = useRecoilValue(langAtom);

  const [currentSlide, setCurrentSlide] = useState(0);
  const [translateX, setTranslateX] = useState(0);
  const itemRef = useRef<any>(null);
  const [gapSize, setGapSize] = useState(70);

  const [newsList, setNewsList] = useState({
    cnt: 0,
    row: [
      {
        id: 0,
        image: "",
        title: ``,
        deployedAt: "",
        url: "",
      },
    ],
  });

  const { list, view } = NewsAction();

  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === newsList.row.length - 1 ? 0 : prevSlide + 1
    );
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? newsList.row.length - 1 : prevSlide - 1
    );
  };

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 500) {
        setGapSize(30);
      } else {
        setGapSize(70);
      }
    }

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setTranslateX(currentSlide * (itemRef.current.offsetWidth + gapSize));
  }, [currentSlide]);

  useEffect(() => {
    list(
      {
        currentPage: 0,
        size: 8,
      },
      setNewsList
    );
  }, []);

  return (
    <NewsSliderWrap>
      <div className="container-fluid">
        <div className="title-wrap">
          <span>NEW</span>
          {lang ? (
            <h2>
              새로운 <br className="mo" />
              소식을
              <br />
              전해드립니다
            </h2>
          ) : (
            <h2>
              Our latest <br />
              Press <br className="mo" />
              Release
            </h2>
          )}
        </div>
        <div className="news-slider">
          <div className="slider-wrap">
            <div
              className="slider"
              style={{
                transform: `translateX(-${translateX}px)`,
                gap: gapSize,
              }}
            >
              {newsList.row.map((item, index) => {
                return (
                  <div key={index} className="item" ref={itemRef}>
                    <a
                      href={item.url}
                      target={"_blank"}
                      onClick={() => {
                        //window.open(item.url);
                        view(item.id);
                      }}
                    >
                      <div
                        className={"thumbnail"}
                        style={{
                          background: `url('${
                            process.env.REACT_APP_IMAGE_URL + item.image
                          }') no-repeat center / cover`,
                        }}
                      ></div>
                      <div className="title">{item.title}</div>
                      <div className="date">
                        {moment(item.deployedAt).format("YYYY.MM.DD")}
                      </div>
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="btn-wrap">
            <button
              type="button"
              className="news-slider-prev"
              onClick={prevSlide}
              disabled={currentSlide === 0}
            >
              <img src="/image/prev-icon.svg" alt="이전 화살표" />
            </button>
            <button
              type="button"
              className="news-slider-next"
              onClick={nextSlide}
              disabled={currentSlide === newsList.row.length - 1}
            >
              <img src="/image/next-icon.svg" alt="다음 화살표" />
            </button>
          </div>
        </div>
      </div>
    </NewsSliderWrap>
  );
};

export default NewsSlider;
