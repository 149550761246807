import styled from "styled-components";

export const ContentWrap = styled.div`
  width: 100%;
  padding: 10rem 0;
  @media screen and (max-width: 1024px) {
    padding: 2.4rem 1.6rem;
  }
`

export const ContentTitleWrap = styled.div`
  border-bottom: 1px solid #AAA;
`

export const ContentTitle = styled.p`
  font-size: 30px;
  font-weight: 700;
  line-height: 42px;
  text-align: left;

  @media screen and (max-width: 500px) {
    font-size: 18px;
    line-height: 26px;
  }
`
export const ContentDate = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  padding: 1.2rem 0 3rem 0;
  color: #aaaaaa;

  @media screen and (max-width: 500px) {
    font-size: 14px;
    line-height: 16px;
    padding-bottom: 24px;
  }
`

export const ContentBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4rem 0;
  border-bottom: 1px solid #EEE;

  @media screen and (max-width: 1024px) {
    padding: 3rem 0;
  }
`

export const ContentBodyImage = styled.img`
  width: 72rem;
  margin: 0 auto;
`

export const ContentBodyDescription = styled.pre`
  white-space: pre-wrap;
  //font-family: 'Pretendard';
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
  margin: 4rem 10rem;
  > p {
    font-size: 1.8rem;
    line-height: 2.8rem;
  }
  @media screen and (max-width: 1024px) {
    margin: 0;
    > p {
      font-size: 1.6rem;
      line-height: 2.8rem;
    }
  }
`

export const TempCenterText = styled.pre`
  white-space: pre-wrap;
  //font-family: 'Pretendard';
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  margin: 4rem 10rem;
  font-weight: 700;
  @media screen and (max-width: 1024px) {
    margin: 3rem 0;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    color: #333333;
  }
`

export const Download = styled.div`
  flex-direction: row;
  display: flex;
  @media screen and (min-width: 1025px) {
    margin-bottom: 30px;
  }
  @media screen and (max-width: 1024px) {
    //margin-bottom: 30px;
  }
`

export const ContentFileDownload = styled.div`
  margin-top: 4rem;
  cursor: pointer;
  padding: .8rem 2.2rem;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;

  > img {
    margin: 0 0 0 1.2rem;
  }

  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: row;
    width: 100vw;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 500px) {
    height: 51px;
  }
`

export const ContentButtonWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 3rem;
  align-items: center;

  @media screen and (min-width: 1024px) {
    //display: inline-block;
  }
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    gap: 3rem;
  }
`
export const ContentPrevNextWrap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3.3rem;
  align-items: center;
  
  @media screen and (max-width: 1024px) {
    width: calc(100vw - 3.2rem);
    justify-content: space-between;
  }

  > div {
    &.left {
      img { margin: -.45rem 1rem 0 0; }
      cursor: pointer;
    }

    &.right {
      img { margin: -.45rem 0 0 1rem; }
      cursor: pointer;
    }

    &.splitLine {
      width: .1rem;
      height: 1.4rem;
      background-color: #CCC;

      //@media screen and (min-width: 1024px) {
      //  display: inline-block;
      //}
      @media screen and (max-width: 1024px) {
        display: none;
      }
    }

    > a {
      display: inline-block;
      padding: .8rem 0;
      font-size: 16px;
      font-weight: 700;
      line-height: 32px;
      text-align: left;
      align-items: center;
    }
  }

  .disabled {
    img {
      filter: invert(1);
    }
    > a {
      color: #CCC;
    }
  }
`

export const ContentListButton = styled.button`
  width: 8.9rem;
  height: 4.1rem;
  padding: 0;
  text-align: center;
  color: white;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.71px;
  background-color: #333333;
  @media screen and (max-width: 1024px) {
    width: calc(100vw - 3.2rem);
    text-align: center;
    margin-bottom: 12rem;
  }

  @media screen and (max-width: 500px) {
    height: 50px;
  }
`
