import styled from "styled-components";

export const HeaderWrap = styled.header`
  width: 100%;
  height: auto;
  position: fixed;
  left: 0;
  top: 0;
  transition: all ease 0.3s;
  background-color: #fff;
  z-index: 999;

  .container {
    position: relative;
    
  }

  #logo {
    width: 14.5rem;
    height: 4rem;
    background: url('/image/logo-active.svg') no-repeat center / cover;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
    transition: all ease 0.3s;
    &.dn {
      display: none;
    }
  }

  .back-btn {
    position: absolute;
    left: 1.6rem;
    top: 50%;
    transform: translateY(-50%);
    img {
      width: 2.4rem;
    }
  }

  #gnb {
    width: 100%;
    height: auto;

    .dep1 {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      > li {
        position: relative;
        > a {
          display: block;
          height: 8rem;
          font-size: 1.6rem;
          line-height: 8rem;
          font-weight: 700;
          color: #333;
          text-align: center;
          padding: 0 4rem;

          transition: .5s;
          &:hover {
            color: #D80101;
          }
          &.active {
            color: #D80101;
          }
        }
        .dep2 {
          width: 100%;
          height: 0;
          position: absolute;
          left: 0;
          top: 100%;
          padding: 0;
          z-index: 9;
          overflow: hidden;
          transition: all ease 0.3s;
          li {
            width: 100%;
            height: auto;
            &:not(:last-child) {
              margin-bottom: 2.5rem;
            }
            a {
              display: block;
              width: 100%;
              height: auto;
              font-size: 1.5rem;
              font-weight: 400;
              color: #555555;
              text-align: center;
              transition: .5s;
              &.sub_active {
                color: #333333; 
                font-weight: bold;
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }

  .btn-wrap {
    display: flex;
    align-items: center;
    gap: 5rem;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;

    a {
      display: block;
      width: 6.8rem;
      height: 3.4rem;
      border: 0.1rem solid #333;
      font-size: 1.2rem;
      line-height: 3.4rem;
      font-weight: 600;
      color: #333;
      text-align: center;
      transition: all ease 0.3s;
    }

    .lang {
      display: flex;
      align-items: center;

      li {
        &:first-child {
          padding-right: 1rem;
          margin-right: 1rem;
          position: relative;

          &::after {
            content: '';
            display: block;
            width: 0.1rem;
            height: 1.4rem;
            background-color: #ddd;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            transition: all ease 0.3s;
          }
        }

        button {
          font-size: 1.6rem;
          font-weight: 500;
          color: #aaa;
          transition: all ease 0.3s;

          &.active {
            font-weight: 700;
            color: #333;
          }
        }
      }
    }
  }

  &.white {
    background-color: transparent;
    #logo {
      background: url('/image/logo.svg') no-repeat center / cover;
    }
    #gnb {
      .dep1 {
        > li {
          > a {
            color: #fff;
            &:hover {
              color: #333;
            }
            &.active {
              color: #333;
            }
          }
        }
      }
    }
    .btn-wrap {
      a {
        border-color: #fff;
        color: #fff;
      }
      .lang {
        li {
          &:first-child {
            &::after {
              background-color: rgba(255, 255, 255, 0.3);
            }
          }
          button {
            color: rgba(255, 255, 255, 0.3);
            &.active {
              color: #fff;
            }
          }
        }
      }
    }
  }

  &.active {
    background-color: #fff;
    #logo {
      background: url('/image/logo-active.svg') no-repeat center / cover;
    }
    #gnb {
      .dep1 {
        > li {
          > a {
            color: #333;
            &:hover {
              color: #D80101;
            }
            &.active {
              color: #D80101;
            }
          }
        }
      }
    }
    .btn-wrap {
      a {
        border-color: #333;
        color: #333;
      }
      .lang {
        li {
          &:first-child {
            &::after {
              background-color: #ddd;
            }
          }
          button {
            color: #aaa;
            &.active {
              color: #333;
            }
          }
        }
      }
    }
  }
  .header-banner {
    width: 100%;
    height: 0;
    background-color: #fff;
    padding: 0;
    overflow: hidden;
    transition: all ease 0.3s;
    .banner {
      width: 23rem;
      height: 13rem;
    }
  }
  &.hover {
    background-color: #fff;
    #logo {
      background: url('/image/logo-active.svg') no-repeat center / cover;
    }
    #gnb {
      .dep1 {
        > li {
          > a {
            color: #333;
            &.active {
              color: #D80101;
            }
          }
          .dep2 {
            padding: 1rem 0 4rem;
            height: 18.5rem;
          }
        }
      }
    }
    .btn-wrap {
      a {
        border-color: #333;
        color: #333;
      }
      .lang {
        li {
          &:first-child {
            &::after {
              background-color: #ddd;
            }
          }
          button {
            color: #aaa;
            &.active {
              color: #333;
            }
          }
        }
      }
    }
    .header-banner {
      height: 20rem;
      padding: 1rem 0 6rem;
    }
  }

  .mobile-menu {
    display: none;
  }

  @media screen and (max-width: 1232px) {
    #logo {
      left: 1.6rem;
    }
    #gnb {
      .dep1 {
        > li {
          > a {
            padding: 0 3rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    height: 7rem;
    #logo {
      width: 11.6rem;
      height: 3.2rem;
      &.hidden {
        opacity: 0;
      }
    }
    #gnb {
      display: none;
    }
    .btn-wrap {
      display: none;
    }
    .mobile-menu {
      width: 2.4rem;
      height: 2.4rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 1.6rem;
      top: 50%;
      transform: translateY(-50%);
      z-index: 99;
      span {
        display: block;
        width: 1.8rem;
        height: 0.2rem;
        background-color: #333;
        transition: all ease 0.3s;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        &:first-child {
          margin-bottom: 0.6rem;
        }
        &:last-child {
          margin-top: 0.6rem;
        }
      }
      &.on {
        span {
          &:first-child {
            margin: 0;
            transform: translate(-50%, -50%) rotate(-45deg);
          }
          &:last-child {
            margin: 0;
            transform: translate(-50%, -50%) rotate(45deg);
          }
        }
      }
    }
    &.white {
      .mobile-menu {
        span {
          background-color: #fff;
        }
        &.on {
          span {
            background-color: #333;
          }
        }
      } 
    }
  }
`


export const FooterWrap = styled.footer`
  padding: 5.4rem 0 5rem;
  border-top: 0.1rem solid #eee;
  background-color: #fff;
  .container {
    position: relative;
    display: flex;
    gap: 10.5rem;
  }
  .left-box {
    h3 {
      font-size: 1.6rem;
      line-height: 1.75;
      font-weight: 700;
      color: #333;
      margin-top: 1.5rem;
    }
  }
  .right-box {
    .company-info {
      padding-top: 0.8rem;
      h4 {
        font-size: 1.5rem;
        font-weight: 700;
        color: #333;
        margin-bottom: 2rem;
      }
      .contact-us {
        display: flex;
        gap: 10rem;
        .info {
          p {
            font-size: 1.3rem;
            line-height: 1.77;
            font-weight: 500;
            color: #999;
            &:not(:last-child) {
              margin-bottom: 0.4rem;
            }
          }
        }
        .service {
          p {
            display: flex;
            font-size: 1.3rem;
            line-height: 1.77;
            font-weight: 600;
            color: #666;
            &:not(:last-child) {
              margin-bottom: 0.4rem;
            }
            span {
              display: block;
              width: 5.5rem;
              font-weight: 500;
              color: #999;
              padding-right: 1rem;
              margin-right: 1rem;
              position: relative;
              white-space: nowrap; /* 텍스트가 줄 바꿈되지 않도록 설정 */
              &::after {
                content: '';
                display: block;
                width: 0.1rem;
                height: 1rem;
                background-color: #DDDDDD;
                position: absolute;
                right: 0;
                top: 0.65rem;
              }
              &.en {
                width: 13.5rem;
              }
            }
          }
        }
      }
    }
    .copyright {
      font-size: 1.3rem;
      font-weight: 400;
      color: #aaa;
      margin-top: 10rem;
    }
    
  }
  img {
    &.mo {
      display: none;
    }
    &.kosdac-logo {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  @media screen and (max-width: 1232px) {
    img {
      &.kosdac-logo {
        right: 1.6rem;
      }
    }
  }

  @media screen and (max-width: 870px) {
    .container {
      gap: 5rem;
    }
    .right-box {
      .company-info {
        .contact-us {
          gap: 3rem;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 8rem 0;
    .container {
      display: block;
    }
    .left-box {
      text-align: center;
    }
    .right-box {
      margin-top: 6rem;
      .company-info {
        text-align: center;
        padding: 0;
        .contact-us {
          display: block;
          .service {
            text-align: left;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 2rem;
            p {
              span {
                width: auto;
                margin-right: 1rem;
                padding-right: 0;
                &::after {
                  display: none;
                }
                &.en {
                  width: auto;
                }
              }
              &:last-child {
                display: block;
                text-align: center;
                span {
                  margin-right: 0;
                }
              }
            }
          }
        }
      }
      .copyright {
        margin-top: 6rem;
        text-align: center;
      }
    }
    img {
      &.mo {
        display: block;
      }
      &.pc {
        display: none;
      }
      &.kosdac-logo {
        width: 16.1rem;
        position: unset;
        margin: 3rem auto 0;
      }
    }
  }

  @media screen and (max-width: 500px) {
    .left-box {
      h3 {
        font-size: 1.4rem;
        margin-top: 1.6rem;
      }
    }
    .right-box {
      margin-top: 4.8rem;
      .company-info {
        h4 {
          font-size: 1.4rem;
        }
        .contact-us {
          .info {
            p {
              font-size: 1.1rem;
            }
          }
          .service {
            margin-top: 1rem;
            p {
              font-size: 1.1rem;
            }
          }
        }
      }
      .copyright {
        margin-top: 4.8rem;
        font-size: 1.2rem;
      }
    }
    img {
      &.kosdac-logo {
        width: 11.1rem;
        margin: 2rem auto 0;
      }
    }
  }
`

