import styled from "styled-components";

export const CompanyMapWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 4rem;
  @media screen and (max-width: 1200px) {
    width: 100vw;
  }
`

export const CompanyMapBody = styled.div`
  width: 120rem;
  padding-top: 12rem;
  @media screen and (max-width: 1200px) {
    padding-top: 0;
    width: 100%;
    >p {
      padding: 1.6rem;
      &:first-child {
        font-size: 30px;
        font-weight: 800;
        line-height: 35.8px;
        text-align: left;
      }
      &:last-child{
        font-size: 16px;
        font-weight: 700;
        line-height: 28px;
        text-align: right;
      }
    }
  }

  @media screen and (max-width: 500px) {
    > p {
      padding: 0 1.6rem;
    }
  }
`

export const CompanyMapFlexBody = styled.div`
  position: relative;
  min-width: 375px;
  width: 1200px;
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  padding-top: 2rem;
  @media screen and (max-width: 1200px) {
    width: 100vw;
    flex-direction: column;
  }

  @media screen and (max-width: 500px) {
    min-width: unset;
  }
`

export const GoogleMapIframe = styled.div`
  width: 120rem;
  height: 39rem;
  background-color: #aaa;
  overflow: hidden;
  > iframe {
    width: 100%;
    height: 100%;
    outline: none !important;
    border: none !important;
    border-width: 0;
    -webkit-tap-highlight-color: transparent; /* 모바일에서 블루 테두리 제거 */
    -webkit-touch-callout: none; /* 모바일에서 터치 시 나타나는 메뉴 제거 */
  }
  @media screen and (max-width: 1200px) {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  @media screen and (max-width: 500px) {
    width: 100%;
    height: 375px;
  }
`

export const SubTitle = styled.p`
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  padding: 5.019rem 0 2rem;
  color: #AAAAAA;

  @media screen and (max-width: 500px) {
    font-size: 1.6rem;
    margin-top: 4rem;
    margin-bottom: 1.4rem;
  }
`

export const GoogleMapWrap = styled.div`
  padding-bottom: 8rem;
`
export const MapTitle = styled.p`
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;

  @media screen and (max-width: 1200px) {
    padding: 0 1.6rem 2rem;
  }

  @media screen and (max-width: 500px) {
    font-size: 1.8rem;
    padding: 0 1.6rem;
  }
`

export const MapContact = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  color: #333333;

  span {
    color: #666666;
    padding-right: 1rem;
    font-weight: 400;
  }

  @media screen and (max-width: 1200px) {
    padding: 0 1.6rem;
  }

  @media screen and (max-width: 500px) {
    line-height: 2.4rem;
    span {
      display: block;
      margin-bottom: 0.4rem;
      margin-top: 2rem;
    }
  }
`
