import styled from "styled-components";

export const Section01Wrap = styled.div`
  padding-bottom: 13rem;
  @media screen and (max-width: 1024px) {
    width: 100%;
    padding: 11.8rem 1.6rem 13rem;
  }

  @media screen and (max-width: 675px) {
    position: relative;
  }

  @media screen and (max-width: 500px) {
    padding: 4.8rem 1.6rem 6rem;
  }
`;

export const TitleWrap = styled.div`
  @media screen and (max-width: 1024px) {
    flex: 1;
  }
`;

export const RedText = styled.p`
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  color: #d80101;
  text-align: center;
  margin-bottom: 3.2rem;
  @media screen and (max-width: 1024px) {
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
  }
`;

export const InfoText = styled.p`
  font-size: 48px;
  font-weight: 800;
  line-height: 72px;
  text-align: center;
  @media screen and (max-width: 1024px) {
    font-size: 38px;
    font-weight: 800;
    line-height: 5.6rem;
    text-align: center;
  }
  @media screen and (max-width: 768px) {
    font-size: 26px;
    font-weight: 800;
    line-height: 38px;
    text-align: center;
  }
`;
export const DisplayBackWrap = styled.div`
  //position: relative;
  transition: 0.5s;
  @media screen and (max-width: 1024px) {
    width: 100%;
    height: auto;
  }
`;
export const DisplayBack = styled.div`
  text-align: center;
  margin: 12rem auto 0;
  position: relative;
  .bg {
    width: 88.2rem;
  }

  @media screen and (max-width: 500px) {
    margin-top: 6.6rem;
  }
`;
export const SearchIconWrap = styled.div`
  position: absolute;
  left: -7rem;
  top: 1rem;
  display: flex;
  flex-direction: column;
  z-index: 8;
  width: 17rem;
  height: 10rem;
  overflow: hidden;
  > div {
    position: relative;
    top: 0rem;
    img {
      width: 17rem;
      height: 10rem;
    }
  }

  @media screen and (max-width: 914px) {
    left: -7.6586vw;
    top: 1.0941vw;
    width: 18.5996vw;
    height: 10.9409vw;
    > div {
      top: 0rem;
      img {
        width: 18.5996vw;
        height: 10.9409vw;
      }
    }
  }
`;

export const SearchListWrap = styled.div`
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: 41.2rem;
  height: 100%;
  background-color: white;
  margin: 0 auto;

  > div:nth-child(2) {
    margin-bottom: 10rem;
  }

  > div:nth-child(3) {
    position: absolute;
    z-index: 9;
    left: 50%;
    transform: translateX(-50%);
    top: 9rem;
    border-radius: 0.4rem;
    width: 58rem;
    height: 12rem;
    padding-top: 1rem;
    border: 0.5rem solid #d80101 !important;
    background-color: white;

    > div {
      height: 9rem;
      align-items: center;
    }

    p {
      position: relative;
      font-size: 3rem;
      line-height: 3.6rem;
      font-weight: 800;
      color: #d80101;
    }

    p.value {
      display: flex;
      font-size: 2.6rem;
      font-weight: 600;
      gap: 1rem;
      align-items: center;
      > span {
        position: relative;
        top: -0.2rem;
        font-weight: 900;
        font-size: 5rem;
      }
    }
  }

  @media screen and (max-width: 914px) {
    width: 45.0766vw;
    > div:nth-child(2) {
      margin-bottom: 10.9409vw;
    }

    > div:nth-child(3) {
      top: 9.8468vw;
      border-radius: 0.4376vw;
      width: 63.4573vw;
      height: 13.1291vw;
      padding-top: 1.0941vw;
      border: 0.547vw solid #d80101 !important;

      > div {
        height: 9.8468vw;
      }

      p {
        font-size: 3.2823vw;
        line-height: 4.6875vw;
      }

      p.value {
        font-size: 2.8446vw;
        gap: 1.0941vw;
        > span {
          top: -0.0219vw;
          font-size: 5.4705vw;
        }
      }
    }
  }
`;

export const SearchListItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 10rem;
  overflow: hidden;

  > div {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  &:not(:nth-child(6)) {
    border-bottom: 0.1rem solid #eeeeee;
  }

  p {
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
    text-align: left;
  }

  @media screen and (max-width: 914px) {
    height: 10.9409vw;

    > div {
      gap: 1.0941vw;
    }

    p {
      font-size: 2.6258vw;
      line-height: 3.9063vw;
    }
  }
`;

export const SearchListItemWrap = styled.div`
  position: relative;
  top: 0.2rem;
  padding: 0 3.5rem;
  justify-content: space-between;
  min-height: 9rem;

  > div {
    display: flex;
    align-items: center;
    flex-direction: row;
    line-height: 3.5rem;

    > p.number {
      padding-right: 1.4rem;
    }

    p {
      position: relative;
    }
  }
  > p {
    position: relative;
  }

  @media screen and (max-width: 914px) {
    //top: 0.0219vw;
    top: -0.6vw;

    padding: 0 3.8293vw;
    min-height: 11.7188vw;

    > div {
      line-height: 3.8293vw;

      > p.number {
        padding-right: 1.5317vw;
      }
    }
  }
`;
