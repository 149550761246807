import {CardListWrap} from "./styels";
import Card from "./Card";

interface CardListInterface {
  data: any[]
}

const Index = (props: CardListInterface) => {
  return <CardListWrap>
    {
      props.data.map((item, key) => (
        <Card
          key={key}
          id={item.id}
          image={process.env.REACT_APP_IMAGE_URL + item.image}
          title={item.title}
          content={item.content}
          link={item.url}
          date={item.deployedAt}
        />
      ))
    }
  </CardListWrap>
}

export default Index
